import React from 'react';
import { StudentsList, StudentEdit, StudentCreate } from './students';
import { EmployeesList, EmployeeEdit, EmployeeCreate } from './employees';
import { ReceiptList, ReceiptEdit } from './receipt';
import { PaymentList, PaymentEdit, PaymentCreate } from './payment';
import { BlankList } from './blank';
import { SummariesList } from './summary';
import { StudentAbsencesList, StudentAbsencesCreate, StudentAbsencesEdit } from './studentAbsences';
import { RCList, RCEdit } from './reportcards';
import { MRCList, MRCEdit, MRCCreate } from './masterrc';
import { fetchUtils, Admin, Resource } from 'react-admin';
import authProvider from './authProvider';
import MyLoginPage from './MyLoginPage';
import MyLogoutButton from './MyLogoutButton';
// import jsonServerProvider from 'ra-data-json-server';
import jsonServerProvider from './dataprovider/dp'; //TO BE FULLY TESTED
import { StudentSigninoutEdit, StudentSigninoutList } from './studentsigninout';
import { ToddlerDailyReportsCreate, ToddlerDailyReportsList, ToddlerDailyReportsEdit } from './toddlerreport';
// import { DailyAttendanceEdit, DailyAttendanceList } from './dailystudentattendance';
import { EmployeeSigninoutEdit, EmployeeSigninoutList } from './employeesigninout';
import {ScheduleUpdatesCreate, ScheduleUpdatesList} from './schedule';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
// import moment from 'moment'
import { LamsApplicationList, LamsApplicationEdit, LamsApplicationCreate, LamsApplicationShow } from './LamsApplication';
import { TourCreate,  Tours, TourEdit } from './tourdetails.js';
import { useAuth0 } from '@auth0/auth0-react';
import { Dashboard } from './dashboard.js';
import {EmployeeGenReleaseShow} from './employeegenrelease.js';




// let launchMoment = require('moment')
// require('moment-timezone')
// moment.tz.setDefault('America/Los_Angeles')

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.mode = 'cors';
    return fetchUtils.fetchJson(url, options);
}

const i18nProvider = polyglotI18nProvider(locale => {
    // Always fallback on english
    return englishMessages;
}, 'en');

const providerURL = process.env.REACT_APP_GO_CONNECT_URL
console.log("providerurl " + providerURL)
const dataProvider = jsonServerProvider(providerURL, httpClient);
// <Admin authProvider={authProvider} dataProvider={dataProvider} >
//     <Admin loginPage={MyLoginPage} logoutButton={MyLogoutButton} authProvider={authProvider} dataProvider={dataProvider} ></Admin>
//<Admin loginPage={MyLoginPage} authProvider={authProvider} dataProvider={dataProvider} >
// console.log("In main ");
const App = () => {
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout,
    } = useAuth0();
    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    };
    return (
        <Admin dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider} i18nProvider={i18nProvider} loginPage={MyLoginPage} logoutButton={MyLogoutButton} disableTelemetry >
            {permissions => [
                permissions.role === 'admin' || permissions.role === 'officemgr' || permissions.role === 'teacher' || permissions.role === 'toddlerteacher'
                    ? <Resource name="Applications" show={LamsApplicationShow} list={LamsApplicationList} create={LamsApplicationCreate} edit={LamsApplicationEdit} />
                    : null,
                permissions.role === 'admin' || permissions.role === 'officemgr'
                    ? <Resource options={{ label: 'Tours' }}name="tours/TourDetails"  list={Tours} create={TourCreate} edit={TourEdit} />
                    : null,
                permissions.role === 'admin' || permissions.role === 'officemgr'
                    ? <Resource name="Employees" list={EmployeesList} create={EmployeeCreate} edit={EmployeeEdit} />
                    : null,
                permissions.role === 'admin' || permissions.role === 'officemgr'
                    ? <Resource name="EmployeeGenReleases" show={EmployeeGenReleaseShow} />
                    : null,
                permissions.role === 'admin' || permissions.role === 'teacher' || permissions.role === 'toddlerteacher' || permissions.role === 'officemgr' || permissions.role === 'toddlerassistant'
                    ? <Resource name="Students" list={StudentsList} create={StudentCreate} edit={StudentEdit} />
                    : null,
                // Restrict access to the edit and remove views to admin only
                // <Resource
                //     name="customers"
                //     list={VisitorList}
                //     edit={permissions === 'admin' ? VisitorEdit : null}
                //     icon={VisitorIcon}
                // />,
                // Only include the categories resource for admin users
                permissions.role === 'admin'
                    ? <Resource name="Receipts" list={ReceiptList} edit={ReceiptEdit} />
                    : null,
                permissions.role === 'admin'
                    ? <Resource name="Payments" list={PaymentList} edit={PaymentEdit} create={PaymentCreate}/>
                    : null,
                permissions.role === 'admin' || permissions.role === 'officemgr'
                    ? <Resource name="Summaries" list={SummariesList} />
                    : null,
                permissions.role === 'admin' || permissions.role === 'officemgr' 
                    ? <Resource options={{ label: 'Student Attendance' }} name="StudentSignInOut" list={StudentSigninoutList} edit={StudentSigninoutEdit} />
                    : null,
                permissions.role === 'admin' || permissions.role === 'officemgr' || permissions.role === 'teacher' || permissions.role === 'toddlerteacher'
                    ? <Resource options={{ label: 'Student Absences' }} name="StudentAbsences" list={StudentAbsencesList} create={StudentAbsencesCreate} edit={StudentAbsencesEdit}/>
                    : null,
                permissions.role === 'admin'
                    ? <Resource options={{ label: 'Employee Hours' }} name="EmployeeSignInOut" list={EmployeeSigninoutList} edit={EmployeeSigninoutEdit} />
                    : null,
                permissions.role === 'admin' || permissions.role === 'teacher' || permissions.role === 'toddlerteacher' || permissions.role === 'officemgr'
                    ? < Resource name="ReportCards" list={RCList} edit={RCEdit} />
                    : null,
                permissions.role === 'admin'
                    ? <Resource options={{ label: 'Master Report Card' }} name="MasterReportCards" list={MRCList} edit={MRCEdit} create={MRCCreate} />
                    : null,
                permissions.role === 'none'
                    ? <Resource options={{ label: 'Default' }} name="Blank" list={BlankList} />
                    : null,
                permissions.role === 'admin' || permissions.role === 'toddlerteacher' || permissions.role === 'toddlerassistant'
                    ? <Resource options={{ label: 'Toddler Daily Reports' }} name="ToddlerReports" create={ToddlerDailyReportsCreate} list={ToddlerDailyReportsList} edit={ToddlerDailyReportsEdit} />
                    : null,
                permissions.role === 'admin' || permissions.role === 'officemgr'
                    ? <Resource options={{ label: 'Schedule Updates' }} name="ScheduleUpdates" create={ScheduleUpdatesCreate} list={ScheduleUpdatesList}  />
                    : null,
            ]}

        </Admin>
    );
}
export default App;

