import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import history from "./history";



const redirectURL = process.env.REACT_APP_AUTH0_REDIRECT_URL
const clientID = process.env.REACT_APP_AUTH0_CLIENTID

const onRedirectCallback = (appState: any) => {
    // console.log("appState", appState, " ", window.location.pathname);
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

ReactDOM.render(
    < Auth0Provider
        domain="lamsaz.us.auth0.com"
        clientId={clientID}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        redirectUri={redirectURL}
        access_type="offline"
        // prompt="consent"
        // approval_prompt="force"
        connection_scope="https://mail.google.com/,https://www.googleapis.com/auth/gmail.compose,https://www.googleapis.com/auth/gmail.insert"
        audience="https://www.lamsaz.com:8080"
        connection="google-oauth2"
        scope="openid profile email offline_access"
        // approvalPrompt= "force"
        authorizationParams={{
            redirect_uri: {redirectURL},
            audience: "https://www.lamsaz.com:8080",
            scope: "openid profile email offline_access"
          }}
    >
        <App />
    </Auth0Provider >, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
