import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import PersonIcon from '@material-ui/icons/Person';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { getCurrYr } from './utils';


const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValueYear, setSelectedValueYear, selectedValueSemester, setSelectedValueSemester, open } = props;
  // console.log(selectedValueYear, selectedValueSemester)

  const handleCancel = () => {
    // console.log("Value for RC", selectedValueYear, selectedValueSemester);
    // setOpen(false);
    onClose(true,selectedValueYear, selectedValueSemester);
  };

  const handleClose = () => {
    // console.log("Value for RC", selectedValueYear, selectedValueSemester);
    onClose(false,selectedValueYear, selectedValueSemester);
  };

  const handleListItemClick = (valueYear, valueSemester) => {
    onClose(valueYear, valueSemester);
  };

  const handleChangeYear = (event) => {
    // setSelectedValueYear(event.target.value);
    setSelectedValueYear(event.target.value);
  };

  const handleChangeSemester = (event) => {
    // setSelectedValueSemester(event.target.value);
    setSelectedValueSemester(event.target.value);
  };

  var currYr = getCurrYr();
  var prvYr = currYr - 1;
  var nxtYr = currYr + 1;
  var ppYr = currYr - 2;

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select Year and Semester</DialogTitle>
      {/* <List>
        {emails.map((email) => (
          <ListItem button onClick={() => handleListItemClick(email)} key={email}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add account" />
        </ListItem>
      </List> */}
      <InputLabel id="select-year-label">ReportCard Year</InputLabel>
      <Select
        labelId="select-year"
        id="RCYear"
        value={selectedValueYear}
        onChange={handleChangeYear}
      >
        <MenuItem value={currYr}>{currYr}</MenuItem>
        <MenuItem value={prvYr}>{prvYr}</MenuItem>
        <MenuItem value={nxtYr}>{nxtYr}</MenuItem>        
        <MenuItem value={ppYr}>{ppYr}</MenuItem>

      </Select>
      <InputLabel id="select-semester-label">ReportCard Semester</InputLabel>
      <Select
        labelId="select-semester"
        id="RCSemester"
        value={selectedValueSemester}
        onChange={handleChangeSemester}
      >
        <MenuItem value={"Spring"}>Spring</MenuItem>
        <MenuItem value={"Fall"}>Fall</MenuItem>
      </Select>
     <div width="100%"> <Button variant="contained" onClick={() => handleClose()}>Submit</Button>
      <Button variant="contained" onClick={() => handleCancel()}>Cancel</Button></div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

// const GenerateRCsButton = ({ selectedIds,push,showNotification }) => {
class GenerateRCsButton extends Component {
  constructor(props) {
    super(props);
    // console.log("In Generate RC comp", this.props.push, this.props.selectedIds, this.props.showNotification);
    //     const { push, selectedIds, showNotification } = this.props;

    // console.log("got in RC button",selectedIds,push,showNotification);
    //   const [open, setOpen] = React.useState(false);
    //   const [selectedValueYear, setSelectedValueYear] = React.useState('');
    //   const [selectedValueSemester, setSelectedValueSemester] = React.useState('');
    this.state = {
      open: false,
      selectedValueYear: getCurrYr(),
      selectedValueSemester: "Spring"
    };
  }

  updateYear = (year) => {
    this.setState((state, props) => {
      return { selectedValueYear: year };
    });
  }

  updateSemester = (sems) => {
    this.setState((state, props) => {
      return { selectedValueSemester: sems };
    });
  }
  handleClickOpen = () => {
    // console.log("open ", this.state.open);
    // this.state.open=true;
    this.setState((state, props) => {
      return { open: true };
    });
    // console.log("In click open", this.state.open)
  };

  handleCancel =  () => {
    this.setState((state, props) => {
      return { open: false };
    });
  }
  handleClose = (cancel,valueYear, valueSemester, studentIds, push, showNotification) => {
    // console.log("SIDS", studentIds, push, showNotification);
    this.setState((state, props) => {
      return { open: false };
    });
    if (cancel) {
      return;
    }
    // console.log("Value for RC call RC go API ", valueYear, valueSemester);
    this.state.selectedValueYear = valueYear;
    this.state.setSelectedValueSemester = valueSemester;
    // console.log("in gen RC" + process.env.REACT_APP_GENRCS_URL);
    const fetchURL = process.env.REACT_APP_GENRCS_URL
    // console.log(fetchURL)
    //  const { push, selectedIds, showNotification } = this.props;
    //  console.log(this.props);
    // console.log("sending " + this.props.selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      // "Content-Length": JSON.stringify(body).length
    })
    // const bdy="{\"studs\":[{\"id\":\"8bb9e4a0-5387-11ea-8d8c-f8cab832656a\",\"sname\":\"Test04\",\"Sdob\":\"01/01/2018\",\"sdoj\":\"02/10/2019\",\"semail\":\"\",\"eStatus\":\"Active\",\"graduationDate\":\"\",\"schoolID\":3,\"program\":\"fullday\",\"tuition\":\"$875\",\"sex\":\"F\",\"pIN\":\"8976\",\"immunizationStatus\":\"complete\",\"classroom\":\"montessori01\",\"parents\":[{\"pName\":\"parent01\",\"address\":\"abc, AZ 85001\",\"tel\":\"6230019999\",\"email\":\"abc@xyz.com\"},{\"pName\":\"parent02\",\"address\":\"abc, AZ 85002\",\"tel\":\"6230019990\",\"email\":\"abc2@xyz.com\"}]},{\"id\":\"98e64254-537e-11ea-aab6-f8cab832656a\",\"Sname\":\"newtest01\",\"Sdob\":\"01/01/1999\",\"Sdoj\":\"\",\"Semail\":\"\",\"EStatus\":\"Graduated\",\"GraduationDate\":\"\",\"SchoolID\":3,\"Program\":\"Toddler - FullDay\",\"Tuition\":\"\",\"Sex\":\"F\",\"PIN\":\"9876\",\"ImmunizationStatus\":\"Not Provided\",\"Classroom\":\"Classroom 03\"},{\"id\":\"2dba6ce6-537f-11ea-aab6-f8cab832656a\",\"Sname\":\"newtest01\",\"Sdob\":\"1/1/1999\",\"Sdoj\":\"\",\"Semail\":\"\",\"EStatus\":\"\",\"GraduationDate\":\"\",\"SchoolID\":3,\"Program\":\"Toddler - FullDay\",\"Tuition\":\"\",\"Sex\":\"F\",\"PIN\":\"\",\"ImmunizationStatus\":\"Not Provided\",\"Classroom\":\"Classroom 03\"}]}";
    const params = new Object();
    params.rcyear = this.state.selectedValueYear;
    params.rcsemester = this.state.selectedValueSemester;
    params.sids = this.props.selectedIds;
    const bdy = JSON.stringify(params);
    // console.log(bdy)
    // const updatedRecord = { ...record, is_approved: true };
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        // console.log("response", response)
        if (response.ok) {
          response.json().then(json => {
            // console.log(response, json);
            if (json != "") {
              this.props.showNotification("ReportCards generated");
              this.props.push('/ReportCards');

            } else {
              this.props.showNotification("ReportCards generated");
              this.props.push('/ReportCards');
            }
          });
        } else if (response.status === '401' ) {
          showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            // console.log(response, json);
            if (json != "") {
              this.props.showNotification("Unable to generate for " + json.message);

            } else {
              this.props.showNotification("Error generating ReportCard(s)");
            }
          });
        }
      })
      .catch((e) => {
        console.error(e);
        this.props.showNotification('Error: generating reportcards', 'warning')
      });
  };

  render() {
    //   const [open, setOpen] = React.useState(false);
    // const [selectedValueYear, setSelectedValueYear] = React.useState('');
    // const [selectedValueSemester, setSelectedValueSemester] = React.useState('');
    return <div><Tooltip title="Generate ReportCards"><IconButton color="primary" component="span" onClick={this.handleClickOpen}>
      <MenuBookIcon style={{ color: "purple" }} />
    </IconButton></Tooltip>
      {/* <SimpleDialog push={this.props.push} showNotification={this.props.showNotification} selectedIds={this.props.selectedIds} selectedValueYear={selectedValueYear}  setSelectedValueYear={setSelectedValueYear} selectedValueSemester={selectedValueSemester} setSelectedValueSemester={setSelectedValueSemester} open={this.state.open} onClose={this.handleClose} /> */}
      <SimpleDialog selectedValueYear={this.state.selectedValueYear} setSelectedValueYear={this.updateYear} setSelectedValueSemester={this.updateSemester} selectedValueSemester={this.state.selectedValueSemester} open={this.state.open} onClose={this.handleClose} />

    </div>
  }

}

GenerateRCsButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(GenerateRCsButton);
// export default GenerateRCsButton;

// export default function RCDetailsDialog() {
//   const [open, setOpen] = React.useState(false);
//   const [selectedValue, setSelectedValue] = "test@gmail.com";

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = (value) => {
//     setOpen(false);
//     setSelectedValue(value);
//   };

//   return (
//     <div>
//       <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
//       <br />
//       <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//         Open simple dialog
//       </Button>
//     </div>
//   );
// }

