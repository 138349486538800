// in src/reportcards.js
import React from 'react';
import TuitionReceiptButton from './TuitionReceiptButton';
import RegReceiptButton from './RegReceiptButton';
import MaterialReceiptButton from './MaterialReceiptButton';
import CreateDraftEmailButton from './CreateDraftEmailButton';
import SendWelcomeEmailButton from './SendWelcomeEmailButton';
import { SimpleList, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import { Box, Typography, CardActions } from '@material-ui/core';
import FlatButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { RadioButtonGroupInput, Filter, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';
import ExtraHrsButton from './ExtraHrsButton';
import CashSummaryButton from './CashSummaryButton';
import GenerateRCsButton from './GenerateRCsButton';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import {
    TabbedForm,
    FormTab,
    DateField,
    ReferenceManyField,
    NumberInput,
    DateInput,
    BooleanInput
} from 'react-admin';




const validateName = [required(), minLength(2), maxLength(45)];
const validateEmail = [required(), email()];
const validatePIN = [required(), regex(/[0-9][0-9][0-9][0-9]/, "Must be 4 digit number")];
const validatePrg = [required()];
// const validateDate = [required(), regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDate = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDateOpt = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateClass = [required()];
const validateSex = [required()];
const validateEStatus = [required()];

console.log("Env:" + process.env.NODE_ENV)

// const RCFilter = (props) => (
//     <Filter {...props}>
//             <SelectInput alwaysOn label="Enrollment Status" validate={validateEStatus} source="EStatus" allowEmpty={false} choices={[
//                 { id: 'All', name: 'All' },
//                 { id: 'Active', name: 'Active' },
//                 { id: 'Graduated', name: 'Graduated' },
//                 { id: 'On Hold', name: 'On Hold' },
//             ]} />
//             <SelectInput alwaysOn label="Classroom" source="Classroom" allowEmpty={false} choices={[
//                 { id: 'All', name: 'All' },
//                 { id: 'Classroom 01', name: 'Classroom 01' },
//                 { id: 'Classroom 02', name: 'Classroom 02' },
//                 { id: 'Classroom 03', name: 'Classroom 03' },
//                 { id: 'Classroom 04', name: 'Classroom 04' },
//             ]} />
//     </Filter>
// );

const DetailsPanel = ({ id, record, resource }) => {
    const txt = "<div>Sex: " + record.Sex + "</div>" +
        "<div>Date of Birth: " + record.Sdob + "</div>" +
        "<div>Date of Joining: " + record.Sdoj + "</div>" +
        "<div>Discount: " + record.Discount + "</div>" +
        "<div>Material Fee Paid: " + record.MaterialFeePaid + "</div>"
    return (
        <div dangerouslySetInnerHTML={{ __html: txt }} />
    );
}

export const MRCList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List  {...props} >
            {isSmall ? (
                <Datagrid>
                    <TextField label="Master Report Card Name" source="MRCName" />
                    <TextField label="Notes" source="Notes" sortable={false} />
                    <EditButton />
                </Datagrid>
            ) : (
                <Datagrid>
                    <TextField label="Master Report Card Name" source="MRCName" />
                    <TextField label="Notes" source="Notes" sortable={false} />
                    <EditButton />
                </Datagrid>)}
        </List>
    );
};

const MRCTitle = ({ record }) => {
    return <span>RC {record ? `"${record.id}"` : ''}</span>;
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        backgroundColor: "#b5b5b5",
        margin: "10px"
    },
    control: {
        padding: theme.spacing(2),
    },
}));

export const MRCEdit = (props) => {
    const classes = useStyles();
    return (
        <Edit title={<MRCTitle />} {...props}>
            <SimpleForm>
                <TextInput disabled label="Id" source="id" />
                <TextInput label="Notes" source="Notes" />
                <TextInput label="Master Report Card Name" source="MRCName" />
                <ArrayInput label="Subjects" source="Categories" >
                    <SimpleFormIterator>
                        <TextInput label="Subject Name" source="CategoryName" />
                        <TextInput label="Subject Description" source="CategoryDescription" />
                        <ArrayInput label="Sub Categories" source="SubCats" >
                            <SimpleFormIterator>
                                <TextInput label="SubCategory Name" source="SubCatName" />
                                <ArrayInput label="Work Items" source="WIs" >
                                    <SimpleFormIterator>
                                        <TextInput label="Work Item Name" source="WorkItemName" />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
}

export const MRCCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Typography variant="h6" gutterBottom>Master Report Card Template</Typography>
            <TextInput label="Master Report Card Name" source="MRCName" />
            <TextInput label="Notes" source="Notes" />
            <ArrayInput label="Subjects" source="Categories" validate={required()}>
                <SimpleFormIterator>
                    <Typography variant="h6" gutterBottom>Subject</Typography>
                    <TextInput label="Subject Name" source="CategoryName" validate={validateName} />
                    <ArrayInput label="SubCategories" source="SubCats" validate={required()}>
                        <SimpleFormIterator>
                            <Typography variant="h6" gutterBottom>SubCategory</Typography>
                            <TextInput label="Sub Category Name" source="SubCategoryName" validate={validateName} defaultValue="default" />
                            <ArrayInput label="WorkItems" source="WIs" validate={required()}>
                                <SimpleFormIterator>
                                    <Typography variant="h6" gutterBottom>Work Items</Typography>
                                    <TextInput label="Work Item Name" source="WorkItemName" validate={validateName} />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleFormIterator>
            </ArrayInput>


        </SimpleForm>
    </Create>
);



// const cardActionStyle = {
//     zIndex: 2,
//     display: 'inline-block',
//     float: 'right',
// };

// const RCCreateActions = ({ basePath, data, refresh }) => (
//     <CardActions style={cardActionStyle}>
//         <ShowButton basePath={basePath} record={data} />
//         <ListButton basePath={basePath} />
//         <DeleteButton basePath={basePath} record={data} />
//         <FlatButton primary label="Refresh" onClick={refresh} icon={<NavigationRefresh />} />

//     </CardActions>
// );

// const RCListActions = props => (
//     <TopToolbar>
//         <BulkDeleteButton {...props} undoable="true" /> 
//         {/* <CreateButton basePath={basePath} record={data} /> */}
//         {/* Add your custom actions */}
//         <SendWelcomeEmailButton {...props} />
//         <CreateDraftEmailButton {...props} />
//         <TuitionReceiptButton {...props} />
//         <RegReceiptButton {...props} />
//         <MaterialReceiptButton {...props} />        
//         <CashSummaryButton {...props} />
//         <GenerateRCsButton {...props} />
//     </TopToolbar>
// );
