import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';


function SimpleDialog(props) {
  const { onClose, setName, setItem, setAmount, open } = props;

  //console.log(selectedValueMonth, setSelectedValueMonth)

  const handleClose = () => {
    //console.log("Value for RC", selectedValueMonth);
    onClose();
  };

  const handleAmtChange = (event) => {
    setAmount(event.target.value);
  };

  const handleItemChange = (event) => {
    setItem(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="form-dialog-title">Invoice Details</DialogTitle>
        <DialogContent>
        <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            onChange={handleNameChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="item"
            label="Item"
            type="text"
            fullWidth
            onChange={handleItemChange}
          />
          <TextField
            margin="dense"
            id="amount"
            label="Amount"
            type="text"
            fullWidth
            onChange={handleAmtChange}
          />
        </DialogContent>
      <Button variant="contained" onClick={() => handleClose()}>Submit</Button>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

class GenericInvoiceButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      item: "",
      amount: "0",
      name: ""
    };
  }
  updateName = (name) => {
    this.setState((state, props) => {
      return state.name=name;
    });
  }

  updateItem = (itemtxt) => {
    this.setState((state, props) => {
      return state.item=itemtxt;
    });
  }

  updateAmt = (amt) => {
    this.setState((state, props) => {
      return state.amount=amt;
    });
  }

  handleClickOpen = () => {
    //console.log("open ", this.state.open);
    this.setState((state, props) => {
      return { open: true };
    });
    //console.log("In click open", this.state.open)
  };

  handleClose = () => {
    //console.log("in gen receipt" + process.env.REACT_APP_GENRCPT_URL);
    this.setState((state, props) => {
      return { open: false };
    });
    const fetchURL = process.env.REACT_APP_GENERICINVOICE_URL
    const { push, selectedIds, showNotification } = this.props;
    //console.log(fetchURL)
    //  //console.log(this.props);
    //console.log("sending " + this.props.selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    })
    const params = new Object();
    params.item = this.state.item;
    params.amount = this.state.amount;
    params.name = this.state.name;
    params.sids = this.props.selectedIds;
    const bdy = JSON.stringify(params);
    //console.log(bdy)
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              this.props.showNotification("Invoices generated");
              push('/Receipts');

            } else {
              this.props.showNotification("Invoices generated");
              push('/Receipts');
            }
          });
        } else if (response.status === '401' ) {
          showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            console.log(response, json);
            if (json != "") {
              this.props.showNotification("Unable to generate invoice error code: " + response.status);

            } else {
              this.props.showNotification("Error generating invoices(s)");
            }
          });
        }
      })
      .catch((e) => {
        //console.error(e);
        this.props.showNotification('Error: generating Invoices', 'warning')
      });
  }

  render() {
    return <div><Tooltip title="Generate Generic Invoice"><IconButton color="primary" component="span" onClick={this.handleClickOpen}>
      <ReceiptIcon style={{ color: "purple" }} />
    </IconButton></Tooltip>
      <SimpleDialog setName={this.updateName} setAmount={this.updateAmt} setItem={this.updateItem} open={this.state.open} onClose={this.handleClose} />
    </div>
  }
}

GenericInvoiceButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(GenericInvoiceButton);

