// in src/reportcards.js
import React from 'react';
import SendRCEmailButton from './SendRCEmailButton';
import { TopToolbar, ArrayInput, SimpleFormIterator, Filter, FormDataConsumer, List, Edit, Datagrid, TextField, EditButton, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, regex, email } from 'react-admin';
import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BulkDeleteButton } from 'react-admin';
import MyTab from './mytab';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { getYears } from './utils';



const validateName = [required(), minLength(2), maxLength(45)];
const validateEmail = [required(), email()];
const validatePIN = [required(), regex(/[0-9][0-9][0-9][0-9]/, "Must be 4 digit number")];
const validatePrg = [required()];
// const validateDate = [required(), regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDate = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDateOpt = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateClass = [required()];
const validateSex = [required()];
const validateEStatus = [required()];

//console.log("Env:" + process.env.NODE_ENV)


const AdminRCFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Classroom" source="Classroom" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Classroom 01', name: 'Classroom 01' },
            { id: 'Classroom 02', name: 'Classroom 02' },
            { id: 'Classroom 03', name: 'Classroom 03' },
            { id: 'Classroom 04', name: 'Classroom 04' },
        ]} />
        <SelectInput alwaysOn label="Semester" validate={required()} source="Semester" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Spring', name: 'Spring' },
            { id: 'Fall', name: 'Fall' },
        ]} />
        <SelectInput alwaysOn label="Year" validate={required()} source="Year" allowEmpty={false} choices={getYears()} />
    </Filter>
);

const TeacherFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Semester" validate={required()} source="Semester" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Spring', name: 'Spring' },
            { id: 'Fall', name: 'Fall' },
        ]} />
        <SelectInput alwaysOn label="Year" validate={required()} source="Year" allowEmpty={false} choices={getYears()} />
    </Filter>
);

const DetailsPanel = ({ id, record, resource }) => {
    const txt = "<div>Creation Date: " + record.CreationDate + "</div>" +
        "<div>Email Addresses: " + record.EmailAddresses + "</div>"
    return (
        <div dangerouslySetInnerHTML={{ __html: txt }} />
    );
}

var d = new Date();
var yr = d.getFullYear();

export const RCList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions !== undefined && permissions.role === 'admin') {
        return (
            <List  {...props} filters={<AdminRCFilter />} filterDefaultValues={{ Semester: "All", Year: yr, Classroom: "Classroom 04" }} bulkActionButtons={<RCListActions {...props} />} >
                {isSmall ? (
                    <Datagrid>
                        <TextField label="Student Name" source="StudentName" />
                        <TextField label="Year" source="Year" />
                        <TextField label="Semester" source="Semester" sortable={false} />
                        <TextField label="Email Sent" source="EmailSent" sortable={false} />
                        <EditButton />
                    </Datagrid>
                ) : (
                    <Datagrid className="txtCopy" expand={<DetailsPanel />}>
                        <TextField label="Student Name" source="StudentName" />
                        <TextField label="Classroom" source="Classroom" />
                        <TextField label="Year" source="Year" />
                        <TextField label="Semester" source="Semester" sortable={false} />
                        <TextField label="Email Sent" source="EmailSent" sortable={false} />
                        <TextField label="Notes" source="Notes" sortable={false} />
                        <EditButton />
                    </Datagrid>)}
            </List>
        );
    } else if (permissions !== undefined && permissions.role === 'officemgr') {
        return (
            <List  {...props} filter={{ Classroom: "Classroom 01" }} filters={<TeacherFilter />} filterDefaultValues={{ Semester: "All", Year: "2023" }} >
                {isSmall ? (
                    <Datagrid>
                        <TextField label="Student Name" source="StudentName" />
                        <TextField label="Year" source="Year" />
                        <TextField label="Semester" source="Semester" sortable={false} />
                        <TextField label="Email Sent" source="EmailSent" sortable={false} />
                        <EditButton />
                    </Datagrid>
                ) : (
                    <Datagrid expand={<DetailsPanel />}>
                        <TextField label="Student Name" source="StudentName" />
                        <TextField label="Classroom" source="Classroom" />
                        <TextField label="Year" source="Year" />
                        <TextField label="Semester" source="Semester" sortable={false} />
                        <TextField label="Email Sent" source="EmailSent" sortable={false} />
                        <TextField label="Notes" source="Notes" sortable={false} />
                        <EditButton />
                    </Datagrid>)}
            </List>
        );
    } else {
        return (
            <List  {...props} filter={{ Classroom: permissions !== undefined && permissions.classroom }} filters={<TeacherFilter />} filterDefaultValues={{ Semester: "All", Year: "2023" }} bulkActionButtons={<RCTeacherListActions{...props} />} >
                {isSmall ? (
                    <Datagrid>
                        <TextField label="Student Name" source="StudentName" />
                        <TextField label="Year" source="Year" />
                        <TextField label="Semester" source="Semester" sortable={false} />
                        <TextField label="Email Sent" source="EmailSent" sortable={false} />
                        <EditButton />
                    </Datagrid>
                ) : (
                    <Datagrid className="txtCopy" expand={<DetailsPanel />}>
                        <TextField label="Student Name" source="StudentName" />
                        <TextField label="Classroom" source="Classroom" />
                        <TextField label="Year" source="Year" />
                        <TextField label="Semester" source="Semester" sortable={false} />
                        <TextField label="Email Sent" source="EmailSent" sortable={false} />
                        <TextField label="Notes" source="Notes" sortable={false} />
                        <EditButton />
                    </Datagrid>)}
            </List>
        );
    }
};

const RCTitle = ({ record }) => {
    return <span>RC {record ? `"${record.id}"` : ''}</span>;
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        backgroundColor: "#b5b5b5",
        margin: "10px"
    },
    control: {
        padding: theme.spacing(2),
    }
}));
const useStyle = makeStyles({
    bold: {
      fontWeight: 'bold',
      fontSize: '1em',
    }
  });
export const RCEdit = (props) => {
    const classes = useStyles();
    const inpclass= useStyle();
    const useIteratorStyle = makeStyles(() => ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          height: 400,
          flexWrap: 'wrap'
        },
        // form: {
        //     border: 1,
        //     width: 300        },
        line: {
          width: 300
        },
      }));

      const iteratorClasses = useIteratorStyle();
    return (
        <Edit title={<RCTitle />} {...props}>
            <SimpleForm>
                <Box style={{ width: "100%" }} >
                    <Box display="flex">
                        <Box mr="4em">
                            <TextInput disabled label="Id" source="id" />
                            <TextInput disabled label="Student Name" source="StudentName" />
                            <TextInput disabled label="Classroom" source="Classroom" />
                        </Box>
                        <Box mr="4em">
                            <TextInput label="Teacher" source="TeacherName" />
                            <TextInput disabled label="Semester" source="Semester" />
                            <TextInput disabled label="Year" source="Year" />
                        </Box>

                    </Box>
                    <Box mr="4em">
                        <TextInput style={{ width: "100%" }} multiline label="Notes" source="Notes" options={{ multiline: true }} />
                    </Box>
                    <ArrayInput label="Subjects" source="Categories" >
                        <SimpleFormIterator disableRemove={true} disableAdd={true} disableReordering={true}>
                            <FormDataConsumer>
                                {({ getSource, scopedFormData }) => {
                                    return (
                                        <Accordion>
                                            <AccordionSummary>{scopedFormData.CategoryName}</AccordionSummary>
                                            <AccordionDetails>
                                                <Box>
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 150 }} disabled label="Subject Name" source={getSource('CategoryName')} />
                                                        </Box>
                                                        <Box paddingLeft="4em" mr="4em">
                                                            <TextInput style={{ width: 500 }} multiline disabled label="Subject Description" source={getSource('CategoryDescription')} />
                                                        </Box>
                                                    </Box>
                                                    <ArrayInput label="Sub Categories" source={getSource('SubCats')} >
                                                        <SimpleFormIterator disableRemove={true} disableAdd={true} disableReordering={true}>
                                                            <FormDataConsumer>
                                                                {({ getSource, scopedFormData }) => {
                                                                    return (
                                                                        <div><br></br>
                                                                        <Accordion>
                                                                            <AccordionSummary>{scopedFormData.SubCatName}</AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <Box>
                                                                                    <Box>
                                                                                        <TextInput disabled label="SubCategory Name" source={getSource('SubCatName')} />
                                                                                    </Box>
                                                                                    <Box display="flex">
                                                                                        <ArrayInput label="Work Items" source={getSource('WIs')} >
                                                                                            <SimpleFormIterator disableRemove={true} disableAdd={true} disableReordering={true} classes={iteratorClasses}>
                                                                                                <FormDataConsumer>
                                                                                                    {({ getSource, scopedFormData }) => {
                                                                                                        return (
                                                                                                            <Box display="flex">
                                                                                                                <Box>
                                                                                                                    <TextField  source={getSource('WorkItemName')} className={inpclass.bold}/>
                                                                                                                </Box>
                                                                                                                <Box>
                                                                                                                    <SelectInput label="Evaluation" source={getSource('Evaluation')} choices={[
                                                                                                                        { id: '', name: '' },
                                                                                                                        { id: 'Proficient', name: 'Proficient' },
                                                                                                                        { id: 'Mastery', name: 'Mastery' }
                                                                                                                    ]}
                                                                                                                        defaultValue={''}
                                                                                                                    />
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                        );
                                                                                                    }}
                                                                                                </FormDataConsumer>
                                                                                            </SimpleFormIterator>
                                                                                        </ArrayInput>
                                                                                    </Box>
                                                                                </Box>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </FormDataConsumer>
                                                        </SimpleFormIterator>
                                                    </ArrayInput>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </Box>
            </SimpleForm>
        </Edit>
    );
}

const RCListActions = props => (
    <TopToolbar>
        <BulkDeleteButton {...props} undoable="true" /><MyTab /><MyTab /><MyTab /><MyTab />
        <SendRCEmailButton {...props} />
    </TopToolbar>
);

const RCTeacherListActions = props => (
    <TopToolbar>
        <SendRCEmailButton {...props} />
    </TopToolbar>
);
