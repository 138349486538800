
import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Tooltip from '@material-ui/core/Tooltip';

class SetPaidButtonCash extends Component {
  handleClick = () => {
    //console.log("in send email");
    const fetchURL = process.env.REACT_APP_SETPAID_URL
    const { push, selectedIds, showNotification } = this.props;
    //console.log(this.props);
    //console.log("sending " + selectedIds);
    // const bdy = JSON.stringify(selectedIds);
    const bdy = JSON.stringify({ rids: selectedIds, PmtMethod: "cash" });
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "Content-Length": JSON.stringify(bdy).length
    })
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              showNotification("Invoices Paid");
              push('/Receipts');

            } else {
              showNotification("Invoices Paid");
              push('/Receipts');
            }
          });
        } else if (response.status === '401' ) {
          showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            if (json != "") {
              showNotification("Unable to set invoices paid for " + json.message);

            } else {
              showNotification("Error setting invoices paid");
            }
          });
        }
      })
      .catch((e) => {
        //console.error(e);
        showNotification('Error: setting invoices paid ' + e, 'warning')
      });
  }

  render() {
    return <div><Tooltip title="Set Invoices Paid by Cash"><IconButton color="primary" component="span" onClick={this.handleClick}>
      <MonetizationOnIcon style={{ color: "red" }} />
    </IconButton></Tooltip></div>
    // <Button onClick={this.handleClick}>Send Email Button</Button>;
  }
}

SetPaidButtonCash.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(SetPaidButtonCash);

//export default RegReceiptButton;