import React from 'react';

const StudentURL = ({ record }) => {
    // console.log("studurl ", record)
    if (record.SID != "") {
        var url = "/#/Students/"+record.SID
        return (
            <a href={encodeURI(url)}>Student Link</a>
        );
    } else {
                return (<div></div>);
            }
}

export default StudentURL;