import React, { useState } from 'react';
import { SimpleList, RefreshButton, ExportButton, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import { Box, Typography, CardActions } from '@material-ui/core';
import FlatButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { RadioButtonGroupInput, Filter, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

// import CreateScheduleUpdatesDraftEmailButton from './CreateScheduleUpdatesDraftEmailButton';
import './index.css';
import StudentURL from './studentUrl';


function futureDate(value) {
    const today = new Date();
    const dt = new Date(value);
    // Remove the time portion to compare only the dates
    today.setHours(0, 0, 0, 0);
    dt.setHours(0, 0, 0, 0);
    // console.log("DT CHK",today,dt);
    if (dt < today) {
        return "Date has to be today or in future";
    }
}

const validateDate = [required(),regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy"),futureDate];
const validateDt = [required(),regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];


const ScheduleUpdatesFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Update Type" source="UpdateType" allowEmpty={true} choices={[
            { id: 'Disenroll', name: 'Disenroll' },
            { id: 'Transition', name: 'Transition' },
            { id: 'Graduate', name: 'Graduate' },
            { id: 'Program', name: 'Change Program' },
            { id: 'JoiningDate', name: 'Change Joining Date' },
            { id: 'UseOfMedia', name: 'Change Media Preference' },
        ]} />
        <SelectInput alwaysOn label="Status" source="Status" allowEmpty={false} choices={[
            { id: 'Scheduled', name: 'Scheduled' },
            { id: 'Failed', name: 'Failed' },
            { id: 'Completed', name: 'Completed' },
        ]} />
        <ReferenceInput alwaysOn label="Student ID" source="SID" reference="Students" filter={{ FEStatus: "Active:Pending" }} sort={{ field: 'Sname', order: 'ASC' }}>
            <SelectInput optionText={(record) => { let val = record.id + "-" + record.Sname; return val; }} />
        </ReferenceInput>
    </Filter>
);



export const ScheduleUpdatesList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List  {...props} filters={<ScheduleUpdatesFilter />} filterDefaultValues={{ Status: "Scheduled"}}  bulkActionButtons={<ScheduleUpdatesListActions {...props} />} sort={{ field: 'Name', order: 'ASC' }} >
                <Datagrid className="txtCopy" >
                    <TextField label="Student Name" source="SName" />
                    <TextField label="UpdateType" source="ScheduleUpdateType" />
                    <TextField label="Effective Date" source="EffectiveDt" />
                    <TextField label="Program" source="Program" />
                    <TextField label="Use of Media" source="UseofMediaApproval" />
                    <TextField label="Status" source="Status" />
                    <TextField label="Date/Time Submitted" source="DoS" />
                    <StudentURL />
                    {permissions !== undefined && permissions.role === 'admin' &&
                        <TextField label="Error" source="Error" /> }
                    
                </Datagrid>
        </List>
    );
};

export const ScheduleUpdatesCreate = (props) => {
    const [visibleDate, setvisibleDate] = useState(false);
    const [visibleCPrg, setvisibleCPrg] = useState(false);
    const [visibleUseOfMedia, setvisibleUseOfMedia] = useState(false);
    const [visibleDisenrollHelpTxt, setvisibleDisenrollHelpTxt] = useState(false);

const handleType = (event) => {
        // console.log("TYPE Change ", event.target.value)
        const type = event.target.value
        if (type == "Transition" || type == "JoiningDate" || type== "Graduate") {
            setvisibleDate(() => true);
            setvisibleCPrg(() => false);
            setvisibleUseOfMedia(() => false);
            setvisibleDisenrollHelpTxt(() => false);
        } else if (type == "Disenroll") {
            setvisibleDate(() => true);
            setvisibleCPrg(() => false);
            setvisibleUseOfMedia(() => false);
            setvisibleDisenrollHelpTxt(() => true);
        } else if (type == "Program") {
            setvisibleCPrg(() => true);
            setvisibleDate(() => true);
            setvisibleUseOfMedia(() => false);
            setvisibleDisenrollHelpTxt(() => false);
        } else if (type == "UseOfMedia") {
            setvisibleUseOfMedia(() => true);
            setvisibleCPrg(() => false);
            setvisibleDate(() => false);
            setvisibleDisenrollHelpTxt(() => false);
        }
    };

    return (
        <Create {...props}>
            <SimpleForm>
                <Box p="1em">
                    <Box display="flex">
                        <Box >
                            <Typography variant="h6" gutterBottom>Provide the change which needs to be Scheduled</Typography>
                            <br />
                            <Box display="flex">
                                <Box mr="2em">
                                    <ReferenceInput alwaysOn label="Student ID" source="SID" reference="Students" filter={{ FEStatus: "Active:Pending" }} sort={{ field: 'Sname', order: 'ASC' }} >
                                        <SelectInput validate={[required()]} optionText={(record) => { let val = record.Sname; return val; }}  />
                                    </ReferenceInput>
                                </Box>
                                <Box mr="2em">
                                    <SelectInput label="UpdateType" source="ScheduleUpdateType" validate={[required()]} choices={[
                                        { id: 'Disenroll', name: 'Disenroll' },
                                        { id: 'Transition', name: 'Transition' },
                                        { id: 'Graduate', name: 'Graduate' },
                                        { id: 'Program', name: 'Change Program' },
                                        { id: 'JoiningDate', name: 'Change Joining Date' },
                                        { id: 'UseOfMedia', name: 'Change Media Preference' },
                                    ]} onChange={event => handleType(event)} />
                                </Box>
                                {visibleDate && visibleCPrg && (<Box mr="2em" style={{ width: 300 }}>
                                    <TextInput label="Effective Date" source="EffectiveDt" validate={validateDt} />
                                </Box>)}
                                {visibleDate && !visibleCPrg && (<Box mr="2em" style={{ width: 300 }}>
                                    <TextInput label="Effective Date" source="EffectiveDt" validate={validateDate} />
                                </Box>)}
                                {visibleDisenrollHelpTxt && (<span><br/>
                                    <b>
                                    Effective Date is the Last Day the Child is attending School.
                                    </b>
                                    </span>)
                                }
                                {visibleCPrg && (<Box mr="2em">
                                    <SelectInput label="Program" source="Program" choices={[
                                        { id: 'HalfDay', name: 'HalfDay' },
                                        { id: 'FullDay', name: 'FullDay' },
                                        { id: 'ExtendedDay', name: 'ExtendedDay' },
                                    ]} validate={[required()]}/>
                                </Box>)}
                                {visibleUseOfMedia && (<Box mr="2em">
                                    <SelectInput label="Use of Media" source="UseofMediaApproval" choices={[
                                        { id: 'Y', name: 'Y' },
                                        { id: 'N', name: 'N' },
                                    ]} validate={[required()]}/>
                                </Box>)}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Create >
    );
};



const ScheduleUpdatesListActions = props => (
    <TopToolbar>
        <BulkDeleteButton {...props} undoable="true" />
    </TopToolbar>
);



