
import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react'; 
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';


const CashSummaryButton = ({ selectedIds }) => {
  // const refresh = useRefresh();
  // const notify = useNotify();
  // const unselectAll = useUnselectAll();
  const butclick = () => {
    console.log(selectedIds)

  }
  // const [updateMany, { loading }] = useUpdateMany(
  //     'posts',
  //     selectedIds,
  //     { views: 0 },
  //     {
  //         onSuccess: () => {
  //             refresh();
  //             notify('Posts updated');
  //             unselectAll(resource);
  //         },
  //         onFailure: error => notify('Error: posts not updated', 'warning'),
  //     }
  // );

  return (
    <div><Tooltip title="Summary for Selected Students"><IconButton color="primary" 
    component={Link}
      to={{
          pathname: '/Summaries',
          search: "?sort="+selectedIds,
      }}
           onClick={butclick}
           >
        <MonetizationOnIcon style={{ color: "green" }} />
      </IconButton></Tooltip></div>
      // <Button
      //     label="summ"
      //     // disabled={loading}
      //           component={Link}
      // to={{
      //     pathname: '/Summaries',
      //     search: "?sort="+selectedIds,
      // }}
      //     onClick={butclick}
      // >Summ
      //     {/* <VisibilityOff /> */}
      // </Button>
  );
};

export default CashSummaryButton;


// const CashSummaryButton = ({ record }) => {
//   console.log(record)
//   return (
//   <Button
//       component={Link}
//       to={{
//           pathname: '/Summaries',
//           search: "?sort=name",
//       }}
//   >
//       Write a comment for that post
//   </Button>
// );
//     }
// export default CashSummaryButton
// class CashSummaryButton extends Component {
//     handleClick = () => {
//         console.log("in cash summary");
//         const fetchURL= process.env.REACT_APP_CASH_SUMMARY_URL
//          const { push, selectedIds, showNotification } = this.props;
//          console.log(this.props);
//          console.log("sending "+selectedIds);
//          const bdy=JSON.stringify(selectedIds);
//          const hdrs = new Headers({
//             "Content-Type": "application/json",
//             "Content-Length": JSON.stringify(bdy).length
//         })
//         this.component={Link} 
//         this.to={
//           pathname: '/Summaries',
//           search: "?SIDs="+bdy,
//       }
//         // fetch(fetchURL, { method: 'GET',  headers: hdrs })
//         //     .then(response => {
//         //         if (response.ok) {
//         //           response.json().then(json => {
//         //               if(json!=""){
//         //             showNotification("Unable to generate for "+json);
//         //             console.log(json);
//         //               }
//         //             push('/Summaries?'+bdy);
//         //           });
//         //         }
//         //       })
//         //     .catch((e) => {
//         //         console.error(e);
//         //         showNotification('Error: getting Cash Summary', 'warning')
//         //     });
//     }

//     render() {
//         return <div><Tooltip title="Summary"><IconButton color="primary" 
//            onClick={this.handleClick}
//            >
//         <MonetizationOnIcon style={{ color: "green" }} />
//       </IconButton></Tooltip></div>
//         // <Button onClick={this.handleClick}>Gen Registration Receipt</Button>;
//     }
// }

// CashSummaryButton.propTypes = {
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };
// export default connect(null, {
//     showNotification: showNotificationAction,
//     push: pushAction,
// })(CashSummaryButton);

