import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ReportIcon from '@material-ui/icons/Assessment';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const years = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var d = new Date();
var currYear = d.getFullYear();

function SimpleDialog(props) {
  const { onClose, selectedValueYear, setSelectedValueYear, open } = props;

  // console.log(selectedValueYear,setSelectedValueYear)

  const handleClose = () => {
    // console.log("Value for Report Year", selectedValueYear);
    onClose(selectedValueYear);
  };

  const handleListItemClick = (valueYear) => {
    onClose(valueYear);
  };

  const handleChangeYear = (event) => {
    setSelectedValueYear(event.target.value);
  };

  // console.log(selectedValueYear);
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select Year</DialogTitle>
      <InputLabel id="select-year-label">Report Year</InputLabel>
      <Select
        labelId="select-year"
        id="ReportYear"
        value={selectedValueYear}
        onChange={handleChangeYear}
      >
        <MenuItem value={currYear - 1}>{currYear - 1}</MenuItem>
        <MenuItem value={currYear}>{currYear}</MenuItem>
        <MenuItem value={currYear - 2}>{currYear - 2}</MenuItem>
      </Select>
      <Button variant="contained" onClick={() => handleClose()}>Submit</Button>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

class AnnualReportButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedValueYear: currYear - 1
    };
  }

  updateYear = (year) => {
    this.setState((state, props) => {
      return { selectedValueYear: year };
    });
  }

  handleClickOpen = () => {
    // console.log("open ", this.state.open);
    this.setState((state, props) => {
      return { open: true };
    });
    // console.log("In click open", this.state.open)
  };

  handleClose = (valueYear, push, showNotification) => {
    // console.log("in gen report" + process.env.REACT_APP_EMAILANNUALREPORT_URL);
    this.setState((state, props) => {
      return { open: false };
    });
    this.state.selectedValueYear = valueYear;
    const fetchURL = process.env.REACT_APP_EMAILANNUALREPORT_URL
    // console.log(fetchURL)
    //  console.log(this.props);
    // console.log("sending " + this.props.selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    })
    const params = new Object();
    params.reportyear = this.state.selectedValueYear;
    params.sids = this.props.selectedIds;
    const bdy = JSON.stringify(params);
    // console.log(bdy)
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            if (json != "") {
              this.props.showNotification("Unable to generate for " + json);
              // console.log(json);
            } else {
              this.props.showNotification("Report generated");
              this.props.push('/Students');
            }
          });
        }
      })
      .catch((e) => {
        console.error(e);
        this.props.showNotification('Error: generating reports', 'warning')
      });
  }

  render() {
    return <div><Tooltip title="Generate Annual Payment Report"><IconButton color="primary" component="span" onClick={this.handleClickOpen}>
      <ReportIcon style={{ color: "green" }} />
    </IconButton></Tooltip>
      <SimpleDialog selectedValueYear={this.state.selectedValueYear} setSelectedValueYear={this.updateYear} open={this.state.open} onClose={this.handleClose} />
    </div>
  }
}

AnnualReportButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(AnnualReportButton);

