import React from 'react';
import { SelectField } from 'react-admin';
import { useRecordContext } from 'react-admin';
import MyTab from './mytab';

const Behavior = (props) => {
    const record = useRecordContext(props);
    if (record != undefined) {
        return (
            <div>
                <SelectField source="Child.Behavior[0]" choices={[
                    { id: 'shy', name: 'Shy' },
                    { id: 'calm', name: 'Calm' },
                    { id: 'cheerful', name: 'Cheerful' },
                    { id: 'aggressive', name: 'Aggressive' },
                    { id: 'whining', name: 'Whining' },
                    { id: 'friendly', name: 'Friendly' },
                    { id: 'excitable', name: 'Excitable' },
                    { id: 'fearful', name: 'Fearful' },
                    { id: 'cooperative', name: 'Cooperative' }
                ]} /><MyTab />
                <SelectField source="Child.Behavior[1]" choices={[
                    { id: 'shy', name: 'Shy' },
                    { id: 'calm', name: 'Calm' },
                    { id: 'cheerful', name: 'Cheerful' },
                    { id: 'aggressive', name: 'Aggressive' },
                    { id: 'whining', name: 'Whining' },
                    { id: 'friendly', name: 'Friendly' },
                    { id: 'excitable', name: 'Excitable' },
                    { id: 'fearful', name: 'Fearful' },
                    { id: 'cooperative', name: 'Cooperative' }
                ]} /><MyTab />
                <SelectField source="Child.Behavior[2]" choices={[
                    { id: 'shy', name: 'Shy' },
                    { id: 'calm', name: 'Calm' },
                    { id: 'cheerful', name: 'Cheerful' },
                    { id: 'aggressive', name: 'Aggressive' },
                    { id: 'whining', name: 'Whining' },
                    { id: 'friendly', name: 'Friendly' },
                    { id: 'excitable', name: 'Excitable' },
                    { id: 'fearful', name: 'Fearful' },
                    { id: 'cooperative', name: 'Cooperative' }
                ]} /><MyTab />
                <SelectField source="Child.Behavior[3]" choices={[
                    { id: 'shy', name: 'Shy' },
                    { id: 'calm', name: 'Calm' },
                    { id: 'cheerful', name: 'Cheerful' },
                    { id: 'aggressive', name: 'Aggressive' },
                    { id: 'whining', name: 'Whining' },
                    { id: 'friendly', name: 'Friendly' },
                    { id: 'excitable', name: 'Excitable' },
                    { id: 'fearful', name: 'Fearful' },
                    { id: 'cooperative', name: 'Cooperative' }
                ]} /><MyTab />
                <SelectField source="Child.Behavior[4]" choices={[
                    { id: 'shy', name: 'Shy' },
                    { id: 'calm', name: 'Calm' },
                    { id: 'cheerful', name: 'Cheerful' },
                    { id: 'aggressive', name: 'Aggressive' },
                    { id: 'whining', name: 'Whining' },
                    { id: 'friendly', name: 'Friendly' },
                    { id: 'excitable', name: 'Excitable' },
                    { id: 'fearful', name: 'Fearful' },
                    { id: 'cooperative', name: 'Cooperative' }
                ]} /><MyTab />
                <SelectField source="Child.Behavior[5]" choices={[
                    { id: 'shy', name: 'Shy' },
                    { id: 'calm', name: 'Calm' },
                    { id: 'cheerful', name: 'Cheerful' },
                    { id: 'aggressive', name: 'Aggressive' },
                    { id: 'whining', name: 'Whining' },
                    { id: 'friendly', name: 'Friendly' },
                    { id: 'excitable', name: 'Excitable' },
                    { id: 'fearful', name: 'Fearful' },
                    { id: 'cooperative', name: 'Cooperative' }
                ]} /><MyTab />
                <SelectField source="Child.Behavior[6]" choices={[
                    { id: 'shy', name: 'Shy' },
                    { id: 'calm', name: 'Calm' },
                    { id: 'cheerful', name: 'Cheerful' },
                    { id: 'aggressive', name: 'Aggressive' },
                    { id: 'whining', name: 'Whining' },
                    { id: 'friendly', name: 'Friendly' },
                    { id: 'excitable', name: 'Excitable' },
                    { id: 'fearful', name: 'Fearful' },
                    { id: 'cooperative', name: 'Cooperative' }
                ]} /><MyTab />
                <SelectField source="Child.Behavior[7]" choices={[
                    { id: 'shy', name: 'Shy' },
                    { id: 'calm', name: 'Calm' },
                    { id: 'cheerful', name: 'Cheerful' },
                    { id: 'aggressive', name: 'Aggressive' },
                    { id: 'whining', name: 'Whining' },
                    { id: 'friendly', name: 'Friendly' },
                    { id: 'excitable', name: 'Excitable' },
                    { id: 'fearful', name: 'Fearful' },
                    { id: 'cooperative', name: 'Cooperative' }
                ]} /><MyTab />
                <SelectField source="Child.Behavior[8]" choices={[
                    { id: 'shy', name: 'Shy' },
                    { id: 'calm', name: 'Calm' },
                    { id: 'cheerful', name: 'Cheerful' },
                    { id: 'aggressive', name: 'Aggressive' },
                    { id: 'whining', name: 'Whining' },
                    { id: 'friendly', name: 'Friendly' },
                    { id: 'excitable', name: 'Excitable' },
                    { id: 'fearful', name: 'Fearful' },
                    { id: 'cooperative', name: 'Cooperative' }
                ]} /><MyTab />
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export default Behavior;