import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { userLogin as userLoginAction } from 'react-admin';
import { useAuth0 } from '@auth0/auth0-react';

import { withStyles, createStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = ({ spacing }) =>
    createStyles({
        button: {
            position: 'relative',
            top: '20px',
            'font-size': '14px',
            'font-weight': 'bold'
        },
        icon: {
            marginRight: spacing.unit,
        },
        note: {
            'font-size': '9px',
        }
    });

const LoginForm = ({ classes, userLogin }) => {
    const [loading, setLoading] = useState(false);
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
    } = useAuth0();

    const handleLogin = () => {
        setLoading(true);
        loginWithRedirect();
    };
    useEffect(() => {
        const { searchParams } = new URL(window.location.href);
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        // console.log("Code and State", code, state, isAuthenticated);
        (async () => {
            if (isAuthenticated) {
                try {
                    // console.log("getting token");
                    const token = await getAccessTokenSilently();
                    // console.log("token", token);
                    userLogin({ user, token });
                } catch (e) {
                    console.error(e.error);
                    if (e.error === 'invalid_grant') {
                        loginWithRedirect();
                      }
                }
            }
        })();
    }, [getAccessTokenSilently]);

    return (
        <div>
            <CardActions >
                <Button
                    className={classes.button}
                    variant="raised"
                    type="submit"
                    color="primary"
                    onClick={handleLogin}
                    disabled={loading}
                >
                    {(isAuthenticated || loading) && (
                        <CircularProgress
                            className={classes.icon}
                            size={18}
                            thickness={2}
                        />
                    )}
                    {!isAuthenticated && (<span>Click to Login/Signup for LAMS Account <br /> <span className={classes.note}><i> (This is required to submit &amp; track application online)</i></span><br /><br /></span>)}
                </Button>
            </CardActions>
        </div >
    );


}

const mapDispatchToProps = {
    userLogin: userLoginAction,
}

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(LoginForm));
