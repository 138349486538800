import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var d = new Date();
var n = d.getMonth();

function SimpleDialog(props) {
  const { onClose, selectedValueMonth, setSelectedValueMonth, open } = props;

  //console.log(selectedValueMonth, setSelectedValueMonth)

  const handleClose = () => {
    //console.log("Value for RC", selectedValueMonth);
    onClose(selectedValueMonth);
  };

  const handleListItemClick = (valueMonth) => {
    onClose(valueMonth);
  };

  const handleChangeMonth = (event) => {
    setSelectedValueMonth(event.target.value);
  };

  //console.log("Month", n);
  var currMnth = n;
  var prvMnth = n - 1;
  if (currMnth == 0) { prvMnth = 11; }
  var nxtMnth = n + 1;
  if (currMnth == 11) { nxtMnth = 0; }
  var nxtplus1Mnth = n + 2;
  if (currMnth == 10) { nxtplus1Mnth = 0; }
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select Month</DialogTitle>
      <InputLabel id="select-year-label">Invoice Month</InputLabel>
      <Select
        labelId="select-month"
        id="RcptMonth"
        value={selectedValueMonth}
        onChange={handleChangeMonth}
      >
        <MenuItem value={currMnth}>{monthNames[currMnth]}</MenuItem>
        <MenuItem value={prvMnth}>{monthNames[prvMnth]}</MenuItem>
        <MenuItem value={nxtMnth}>{monthNames[nxtMnth]}</MenuItem>
        <MenuItem value={nxtplus1Mnth}>{monthNames[nxtplus1Mnth]}</MenuItem>
      </Select>
      <Button variant="contained" onClick={() => handleClose()}>Submit</Button>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

class TuitionReceiptButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedValueMonth: n
    };
  }

  updateMonth = (month) => {
    this.setState((state, props) => {
      return { selectedValueMonth: month };
    });
  }

  handleClickOpen = () => {
    //console.log("open ", this.state.open);
    this.setState((state, props) => {
      return { open: true };
    });
    //console.log("In click open", this.state.open)
  };

  handleClose = (valueMonth, push, showNotification) => {
    //console.log("in gen receipt" + process.env.REACT_APP_GENRCPT_URL);
    this.setState((state, props) => {
      return { open: false };
    });
    this.state.selectedValueMonth = valueMonth;
    const fetchURL = process.env.REACT_APP_GENRCPT_URL
    //console.log(fetchURL)
    //  //console.log(this.props);
    //console.log("sending " + this.props.selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    })
    const params = new Object();
    params.rcptmonth = this.state.selectedValueMonth;
    params.sids = this.props.selectedIds;
    const bdy = JSON.stringify(params);
    //console.log(bdy)
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              this.props.showNotification("Receipts generated");
              push('/Receipts');

            } else {
              this.props.showNotification("Receipts generated");
              push('/Receipts');
            }
          });
        } else if (response.status === '401' ) {
          showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              this.props.showNotification("Unable to generate for " + json.message);

            } else {
              this.props.showNotification("Error generating receipt(s)");
            }
          });
        }
      })
      .catch((e) => {
        //console.error(e);
        this.props.showNotification('Error: generating receipts', 'warning')
      });
  }

  render() {
    return <div><Tooltip title="Generate Tuition Invoice"><IconButton color="primary" component="span" onClick={this.handleClickOpen}>
      <ReceiptIcon style={{ color: "green" }} />
    </IconButton></Tooltip>
      <SimpleDialog selectedValueMonth={this.state.selectedValueMonth} setSelectedValueMonth={this.updateMonth} open={this.state.open} onClose={this.handleClose} />
    </div>
  }
}

TuitionReceiptButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(TuitionReceiptButton);

