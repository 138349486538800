import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Tooltip from '@material-ui/core/Tooltip';

class SignInButton extends Component {
  handleClick = () => {
    const fetchURL = process.env.REACT_APP_GO_CONNECT_URL + "/StudentSignInOut"
    const { push, selectedIds, showNotification } = this.props;
    //console.log(this.props);
    //console.log("sending " + selectedIds);
    const bdy = JSON.stringify({ Sids: selectedIds, Status: "IN" });
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "Content-Length": JSON.stringify(bdy).length
    })
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            //console.log(response, json);
            showNotification("Sign IN generated");
            push('/StudentSignInOut');
          });
        } else if (response.status === '401' ) {
          showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              showNotification("Unable to generate for " + json);

            } else {
              showNotification("Error generating signin(s)");
            }
          });
        }
      })
      .catch((e) => {
        //console.error(e);
        showNotification('Error: generating signins', 'warning')
      });
  }

  render() {
    return <div><Tooltip title="Generate Sign In"><IconButton color="primary" component="span" onClick={this.handleClick}>
      <PersonAddIcon style={{ color: "green" }} />
    </IconButton></Tooltip></div>
  }
}

SignInButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(SignInButton);
