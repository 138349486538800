// in src/students.js
import React, { useEffect,useState, Component } from 'react';
import { DateInput, FormDataConsumer, TopToolbar, useListContext, FunctionField, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import {BooleanInput, Filter, useInput, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import MyTab from './mytab.js';
import { useDataProvider } from 'react-admin';
import CreateToursEmailButton from './CreateToursEmailButton.js';
import {isAdmin} from './utils.js';
import {validateEnrollmentDate} from './utils.js';
import BlockTourButton from './BlockTourButton.js';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const validateDate = [required(),regex(/[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/, "Must be valid date yyyy-mm-dd")];
const validateTime = [required(),regex(/^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)/,"Must be valid time hh:mm AM/PM")];

var d = new Date();
var dt = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-'
    + ('0' + d.getDate()).slice(-2);

const TourFilter = (props) => (
    <Filter {...props}>
        <TextInput alwaysOn label="Date" source="TourDate" ></TextInput>
        <SelectInput alwaysOn label="Status" source="Status" allowEmpty={false} choices={[
            // { id: 'All', name: 'All' },
            { id: 'Submitted', name: 'Submitted' },
            { id: 'Approved', name: 'Approved' },
            { id: 'Cancelled', name: 'Cancelled' },
            { id: 'NoShow', name: 'No Show' },
            { id: 'Completed', name: 'Completed' },
            { id: 'Available', name: 'Available' },
            { id: 'Blocked', name: 'Blocked' }
        ]} />
        {/* <ReferenceInput allowEmpty={false} alwaysOn label="Student Name" source="id" reference="Students" filter={{ FEStatus: "Active" }} sort={{ field: 'Sname', order: 'ASC' }}>
            <SelectInput optionText="Sname" />
        </ReferenceInput> */}
    </Filter>
);

function getChoices(record){
    // console.log("STATUS",record.Status)
    switch (record.Status) {
        case'Cancelled': {
            return [
                { id: 'Cancelled', name: 'Cancelled' },
            ];
        }
        case'Approved': {
            return [
                { id: 'Cancelled', name: 'Cancelled' },
                { id: 'Submitted', name: 'Submitted' },
                { id: 'NoShow', name: 'No Show' },
                { id: 'Completed', name: 'Completed' },
            ];
        }
        case'Submitted': {
            return [
                { id: 'Approved', name: 'Approved' },
                { id: 'Cancelled', name: 'Cancelled' },
            ];
        }
        case'NoShow': {
            return [
                { id: 'NoShow', name: 'NoShow' },
            ];
        }
        case'Completed': {
            return [
                { id: 'Completed', name: 'Completed' },
            ];
        }
    }

}
function getTourDates() {
    var d = new Date(),
        year = d.getYear(),
        weds = [];

    // d.setDate(1);

    // Get the first Wednesday in the month
    while (d.getDay() !== 3) {
        d.setDate(d.getDate() + 1);
    }

    // Get all the other Wednesday in the month
    while (d.getYear() === year) {
        var pushDate = new Date(d.getTime());
        // weds.push(pushDate.getDate() + '-' + (pushDate.getMonth() + 1) + '-' + pushDate.getFullYear());
        var dt = pushDate.getFullYear() + '-' + (pushDate.getMonth() + 1) + '-' + pushDate.getDate();
        weds.push({ id: dt, name: dt });
        d.setDate(d.getDate() + 7);
    }
    console.log("Weds ", weds);
    return weds;
}
const DynamicSelectInput = ({ ...props }) => {
    const choices = getTourDates();

    return <SelectInput {...props} choices={choices} />;
};


// const TourPNm = ({...props}) => {
//     const { filterValues } = useListContext();
//     if (filterValues.Status != 'Blocked' && filterValues.Status != 'Available') {
//         return (
//             <TextField label="Parent Name" source="ParentName" />
//         )
//     } else {
//         return (
//             null
//         )
//     }
// };
        
export const Tours = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));   
    // console.log("perms ", permissions);
    if (permissions !== undefined && (permissions.role === 'admin' || permissions.role === 'officemgr')) {
        return (
            <List  {...props} filters={<TourFilter />} filterDefaultValues={{ TourDate: "", Status: "Submitted" }} bulkActionButtons={isAdmin(permissions)?<OfficeMgrListActions {...props} />:<UserListActions {...props} />} >
                {(
                    <Datagrid >
                         <TextField label="Date" source="Date" />
                         <TextField label="Time" source="Time" />
                         <TextField label="Status" source="Status" />
                         <TextField label="Parent Name" source="ParentName" />
                         <TextField label="Student Name" source="StudentName" />
                         <TextField label="Program Applying For" source="Program" />
                         <TextField label="Phone" source="Phone" />
                         <TextField label="Email" source="Email" />
                         <TextField label="Enrollment Date" source="EnrollmentDate" />
                         <TextField label="Reason" source="ReasonForBlocking" /> 
                         <FunctionField
                            render={record => (
                                (record.Status != 'Blocked' && record.Status != '') ? <EditButton record={record} /> : null
                            )}
                        />
                    </Datagrid>
                )}
            </List>
        );
    } else {
        return (<span>User has No Access...</span>)
    }
};


export const TourCreate = (props) => {
    const [customValue, setCustomValue] = useState('');

    const handleCustomChange = (event) => {
        setCustomValue(event.target.value);
    };


    const dataProvider = useDataProvider();
    const [TourDateTimes, setTourDateTimes] = useState([]);

    useEffect(() => {
        const fetchTourDateTimes = async () => {
            try {
                const fetchURL = process.env.REACT_APP_TOURDATES_URL
                const token = localStorage.getItem('token');
                const hdrs = new Headers({
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                })
                const params = new Object();
                const bdy = JSON.stringify(params);
                fetch(fetchURL, { method: 'GET', headers: hdrs })
                    .then(response => {
                        if (response.ok) {
                            response.json().then(json => {
                                if (json != "") {
                                    var dates = json.map((date, index) => {
                                        return {
                                            id: date, // Assuming you want 1-based indexing
                                            name: date
                                        };
                                    });
                                    dates.push({ id: 'custom', name: 'Enter custom value' }) // Option for custom value                                    )
                                    console.log(dates);
                                    setTourDateTimes(dates)
                                } else {
                                    
                                }
                            });
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        this.props.showNotification('Error: fetching TourDateTimes', 'warning')
                    });
  
            } catch (error) {
                console.error('Error fetching TourDateTimes:', error);
            }
        };

        fetchTourDateTimes();
    }, [dataProvider]);

    return (
        <Create {...props}>
            <SimpleForm>
                <Box p="1em">
                    <Box display="flex">
                        <Box >
                            <Typography variant="h6" gutterBottom>Tour Details</Typography>
                            <Box>
                                <Box display="flex">
                                    <TextInput label="Parent Name" source="ParentName" validate={required()}/>
                                </Box>
                                <Box>
                                    <TextInput label="Student Name" source="StudentName" validate={required()}/>
                                </Box>
                                <Box>
                                    <SelectInput style={{ width: "250px" }} alwaysOn label="Program Applying For" source="Program" allowEmpty={false} choices={[
                                        { id: 'Toddler', name: 'Toddler (18 - 36 months)' },
                                        { id: 'Primary', name: 'Primary (3 - 6 years)' },
                                    ]} validate={required()} />
                                </Box>
                                <Box>
                                    <DateInput label="Enrollment Date" source="EnrollmentDate" validate={validateEnrollmentDate} />
                                </Box>
                                <Box>
                                    <TextInput label="Email" source="Email" validate={required()}/>
                                </Box>
                                <Box>
                                    <TextInput label="Phone" source="Phone" validate={required()}/>
                                </Box>
                                <Box>
                                    {/* <DynamicSelectInput source="TourDateTimes" label="Date & Time" validate={required()} /> */}
                                    <SelectInput label="Available Dates & Time" source="DateTime" choices={TourDateTimes} optionText="name" optionValue="id" validate={required()} onChange={(event) => {
                    if (event.target.value !== 'custom') {
                        setCustomValue(''); // Clear custom input
                    } else {
                        setCustomValue('custom')
                    }
                }}/>
                {customValue === 'custom' && (
                <TextInput
                    source="Date"
                    label="Enter Date yyyy-mm-dd"
                    fullWidth
                    validate={validateDate}
                />
            )}
                {customValue === 'custom' && (
                    <TextInput
                        source="Time"
                        label="Enter Time hh:mm AM/PM"
                        fullWidth
                        validate={validateTime}
                    />
            )}
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Create >
    );
};

const TourTitle = ({ record }) => {
    return <span>Payment {record ? `"${record.id}"` : ''}</span>;
};

export const TourEdit = (props) => (
    <Edit title={<TourTitle />} {...props}>
        <SimpleForm>
            <Box p="1em">
                <Box display="flex">
                    <Box >
                        <Typography variant="h6" gutterBottom>Tour Details</Typography>
                        <Box>
                            <Box display="flex">
                                <Box>
                                    <FormDataConsumer>
                                    {({formData, ...rest})  => {
                                return ( <SelectInput label="Status" source="Status" choices={getChoices(formData)} optionText="name" optionValue="id" validate={required()}/>
                                );
                            }}
                                    </FormDataConsumer>
                                    </Box>
                                    <Box ml="2em">
                                        <BooleanInput source="sendEmail" label="Send Email"  />
                                    </Box>
                            </Box>
                            <Box display="flex">
                                <TextInput disabled label="Parent Name" source="ParentName" validate={required()} />
                            </Box>
                            <Box>
                                <TextInput disabled label="Student Name" source="StudentName" validate={required()} />
                            </Box>
                            <Box>
                                    <SelectInput style={{ width: "250px" }} alwaysOn label="Program Applying For" source="Program" allowEmpty={false} choices={[
                                        { id: 'Toddler', name: 'Toddler (18 - 36 months)' },
                                        { id: 'Primary', name: 'Primary (3 - 6 years)' },
                                    ]} validate={required()} />
                                </Box>
                                <Box>
                                    <DateInput label="Enrollment Date" source="EnrollmentDate" validate={required()} />
                                </Box>
                            <Box>
                                <TextInput label="Email" source="Email" validate={required()} />
                            </Box>
                            <Box>
                                <TextInput label="Phone" source="Phone" validate={required()} />
                            </Box>
                            <Box>
                                <TextInput disabled label="Available Dates & Time" source="DateTime" validate={required()}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </SimpleForm>
    </Edit >
);

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const CustomBulkDeleteButton = (props) => (
    <BulkDeleteButton {...props} label={false} icon={<CheckCircleOutlineIcon style={{ color: "green" }} />} />
);

const UserListActions = (props) => {
    // console.log("In student buttons ", props);
    let st = props.filterValues.Status;
    // let cl = props.cl;
    // console.log("Classroom filter ", cl, st);
    return (
        <TopToolbar>
            {(st != undefined && st != "Available" && st != "Blocked") &&
            <><BulkDeleteButton {...props} undoable="true" /><MyTab /><MyTab /><MyTab /><MyTab /></>}
            {(st != undefined && st != "Available" && st != "Blocked") &&
            <CreateToursEmailButton {...props} />}
            {(st != undefined && st == "Available") &&
            <BlockTourButton {...props} />}
            {(st != undefined && st == "Blocked") &&
            <CustomBulkDeleteButton {...props} />}
        </TopToolbar>
    );
}

const OfficeMgrListActions = (props) => {
    // console.log("In student buttons ", props);
    let st = props.filterValues.Status;
    // let cl = props.cl;
    // console.log("Classroom filter ", cl, st);
    return (
        <TopToolbar>
            {(st != undefined && st != "Available" && st != "Blocked") &&
            <CreateToursEmailButton {...props} />}
            {(st != undefined && st == "Available") &&
            <BlockTourButton {...props} />}
            {(st != undefined && st == "Blocked") &&
            <CustomBulkDeleteButton {...props} />}
        </TopToolbar>
    );
}
