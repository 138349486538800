import React from 'react';
import { SimpleList, Filter, BulkDeleteButton, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import MyImage from './myimage.js';
import Typography from '@material-ui/core/Typography';
import { getYears } from './utils';

const EmployeeSigninoutFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Month" validate={required()} source="Month" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 0, name: 'Jan' },
            { id: 1, name: 'Feb' },
            { id: 2, name: 'Mar' },
            { id: 3, name: 'Apr' },
            { id: 4, name: 'May' },
            { id: 5, name: 'Jun' },
            { id: 6, name: 'Jul' },
            { id: 7, name: 'Aug' },
            { id: 8, name: 'Sep' },
            { id: 9, name: 'Oct' },
            { id: 10, name: 'Nov' },
            { id: 11, name: 'Dec' },
        ]} />
        <SelectInput alwaysOn label="Year" validate={required()} source="Year" allowEmpty={false} choices={getYears()} />
        <SelectInput alwaysOn label="GetSignatures" validate={required()} source="Signatures" allowEmpty={false} choices={[
            { id: 'N', name: 'N' },
            { id: 'Y', name: 'Y' },
        ]} />
        <ReferenceInput alwaysOn label="PIN" source="id" reference="Employees" sort={{ field: 'Name', order: 'AI' }}>
            <SelectInput optionText={(record) => { let val = record.PIN + "-" + record.Name; return val; }} />
        </ReferenceInput>
        <TextInput alwaysOn label="Date" source="siodate" ></TextInput>
    </Filter>
);

var d = new Date();
var dt = ('0' + (d.getMonth() + 1)).slice(-2) + '/'
    + ('0' + d.getDate()).slice(-2) + '/'
    + d.getFullYear();
// console.log("dateeeee", dt.substr(0, dt.indexOf('/')).length, dt)
var mnth = d.getMonth();
var yr = d.getFullYear();
// console.log(mnth)

function isWeekday(year, month, day) {
    var day = new Date(year, month, day).getDay();
    return day != 0 && day != 6;
}

function daysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
}

function getWeekdaysInMonth(month, year) {
    var days = daysInMonth(month, year);
    var weekdays = 0;
    var today = new Date();
    for (var i = 0; i < days; i++) {
        var day = new Date(year, month, i+1);
        if (day.getTime() <= today.getTime() && isWeekday(year, month, i + 1))
            weekdays++;
    }
    return weekdays;
}

const holidays = new Map([[2023,[2,1,5,1,1,1,21,0,1,5,4,10]],[2024,[2,1,6,1,1,1,23,2,1,5,4,7]],[2025,[4,1,5,2,1,2,23]]]);  //num of holiday for each month starting Jan-Dec

function getHolidays(y,m) {
    return holidays.get(y)[m]
}
function calAbs(data, ids, m, y) {
    // console.log("params", m, y);
    if (ids.length > 0) {
        var att = ids.map(id => data[id]).reduce((acc, rec) => {
            const matchingNode = acc.find(node => node.SIODate === rec.SIODate);
            if(!matchingNode) {
                acc.push(rec);
              }
              return acc;
        }, []);
        // console.log("attt",att);
        return (getWeekdaysInMonth(m, y)-holidays.get(y)[m])-att.length;
    }
    return getWeekdaysInMonth(m, y);
}

function calAbsPercent(data, ids, m, y) {
    // console.log("params", m, y);
    if (ids.length > 0) {
        var att = ids.map(id => data[id]).reduce((acc, rec) => {
            const matchingNode = acc.find(node => node.SIODate === rec.SIODate);
            if(!matchingNode) {
                acc.push(rec);
              }
              return acc;
        }, []);
        // console.log("attt",att);
        return ((att.length/(getWeekdaysInMonth(m, y)-holidays.get(y)[m]))*100).toFixed(2);
    }
    return 0;
}

function calAttendance(data, ids) {
    // console.log(data,ids)
    if (ids.length > 0) {
        var att = ids.map(id => data[id]).reduce((acc, rec) => {
            const matchingNode = acc.find(node => node.Name === rec.Name);
            if(!matchingNode) {
                acc.push(rec);
              }
              return acc;
        }, []);
        // console.log("attt",att);
        return att.length;
    }
    return 0;
}
const Aside = ({ data, ids, ...props }) => {
    // console.log("DATA", props);
    if ((props.filterValues.siodate != undefined || props.filterValues.siodate != "") && (props.filterValues.id == undefined || props.filterValues.id == "")) {
        return (
            <div style={{ width: 200, margin: '1em', 'user-select': 'text' }}>
                <Typography ># Employees Attended</Typography>
                {calAttendance(data, ids)}
                <Typography ># Employees SignedIn</Typography>
                {ids.map(id => data[id]).reduce((sin, rec, idx) => {
                    // console.log(idx,ids.length)
                    if (rec.Status === "IN") {
                        sin = sin + 1;
                    }
                        return sin;
                }, 0)}
                <Typography ># Employees SignedOut</Typography>
                {ids.map(id => data[id]).reduce((sin, rec, idx) => {
                    // console.log(idx,ids.length)
                    if (rec.Status === "OUT") {
                        sin = sin + 1;
                    }
                        return sin;
                }, 0)}
            </div>
    );
    } else {
    return (
        <div style={{ width: 200, margin: '1em', 'user-select': 'text' }}>
            <Typography >Extra Hours Total</Typography>
            <Typography variant="body1">
                {ids.map(id => data[id]).reduce((sum, rec, idx) => {
                    // console.log(idx,ids.length)
                    if (rec.HrsWorked) {
                        sum = sum + parseFloat(rec.HrsWorked);
                    }
                    if (idx + 1 === ids.length)
                        return sum.toFixed(2);
                    else
                        return sum;
                }, 0.0)}
                <div>Hours including 15th</div>
                {ids.map(id => data[id]).reduce((sum2, rec, idx) => {
                    var dte = new Date(rec.SIODate);
                    if (rec.HrsWorked && dte.getDate() <= 15) {
                        sum2 = sum2 + parseFloat(rec.HrsWorked);
                    }
                    if (idx + 1 === ids.length)
                        return sum2.toFixed(2);
                    else
                        return sum2;
                }, 0.0)}
                <div>Hours after 15th</div>
                {ids.map(id => data[id]).reduce((sum3, rec, idx) => {
                    var dte = new Date(rec.SIODate);
                    if (rec.HrsWorked && dte.getDate() > 15) {
                        sum3 = sum3 + parseFloat(rec.HrsWorked);
                    }
                    if (idx + 1 === ids.length)
                        return sum3.toFixed(2);
                    else
                        return sum3;
                }, 0.0)}
                <div>Absences</div>
                {
                    calAbs(data, ids, props.filterValues.Month, props.filterValues.Year)
                }
                <div>Total Weekdays</div>
                {
                    getWeekdaysInMonth(props.filterValues.Month, props.filterValues.Year)
                }
                <div>Holidays</div>
                {
                    getHolidays(props.filterValues.Year,props.filterValues.Month)
                }
                <div>Attendance %</div>
                {
                    calAbsPercent(data, ids, props.filterValues.Month, props.filterValues.Year)
                }
            </Typography>
        </div>
    );
            }
}

export const EmployeeSigninoutList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List aside={<Aside />} filters={<EmployeeSigninoutFilter />} filterDefaultValues={{ Month: mnth, Year: yr, siodate: dt, Signatures: "N" }} bulkActionButtons={<EmployeeSigninoutListActions {...props} />} {...props}>
            {isSmall ? (
                <Datagrid>
                    <TextField label="Date" source="SIODate" />
                    <TextField label="Employee Name" source="Name" />
                    <TextField label="SignIn Status" source="Status" />
                    <TextField label="Time IN" source="INTime" />
                    <MyImage label="Signature IN" type="IN" />
                    <TextField label="Time OUT" source="OUTTime" />
                    <MyImage label="Signature OUT" type="OUT" />
                    <TextField label="Hours Worked" source="HrsWorked" />
                    <TextField label="Notes" source="Notes" />
                    <EditButton />
                </Datagrid>
            ) : (
                <Datagrid {...props}>
                    <TextField label="Date" source="SIODate" />
                    <TextField label="Employee Name" source="Name" />
                    <TextField label="SignIn Status" source="Status" />
                    <TextField label="Time IN" source="INTime" />
                    <MyImage label="Signature IN" type="IN" />
                    <TextField label="Time OUT" source="OUTTime" />
                    <MyImage label="Signature OUT" type="OUT" />
                    <TextField label="Hours Worked" source="HrsWorked" />
                    <TextField label="Notes" source="Notes" />
                    <EditButton />
                </Datagrid>)}
        </List>
    );
};

const EmployeeSigninoutTitle = ({ record }) => {
    return <span>EmployeeSigninout {record ? `"${record.id}"` : ''}</span>;
};

export const EmployeeSigninoutEdit = (props) => (
    <Edit title={<EmployeeSigninoutTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput label="Date" source="SIODate" />
            <TextInput disabled label="Employee Name" source="Name" />
            <SelectInput label="SignIn Status" source="Status" choices={[
                { id: 'IN', name: 'IN' },
                { id: 'OUT', name: 'OUT' },
            ]} />
            <TextInput label="Time IN" source="INTime" />
            <TextInput label="Time OUT" source="OUTTime" />
            <TextInput label="Notes" source="Notes" />
        </SimpleForm>
    </Edit>
);

const EmployeeSigninoutListActions = props => (
    <TopToolbar>
        <BulkDeleteButton {...props} undoable="true" />

    </TopToolbar>
);