// in src/students.js
import React from 'react';
import TuitionReceiptButton from './TuitionReceiptButton';
import AnnualReportButton from './AnnualReportButton';
import RegReceiptButton from './RegReceiptButton';
import MaterialReceiptButton from './MaterialReceiptButton';
import CreateDraftEmailButton from './CreateDraftEmailButton';
import SendWelcomeEmailButton from './SendWelcomeEmailButton';
import { TextField, NumberInput, DateInput, DateTimeInput, CreateButton, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import { Box, Typography, CardActions } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField';
import { CheckboxGroupInput, FormTab, TabbedForm, RadioButtonGroupInput, Filter, BulkDeleteButton } from 'react-admin';
import CashSummaryButton from './CashSummaryButton';
import ToddlerReportButton from './GenerateAndSendToddlerReports';
import { useMediaQuery } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MyTab from './mytab.js';
import { TimeInput } from './picker';

const validateName = [required(), minLength(2), maxLength(45)];
const validateEmail = [required(), email()];
const validatePIN = [required(), regex(/[0-9][0-9][0-9][0-9]/, "Must be 4 digit number")];
const validatePrg = [required()];
// const validateDate = [required(), regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDate = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDateOpt = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateClass = [required()];
const validateSex = [required()];
const validateEStatus = [required()];

//console.log("Env:" + process.env.NODE_ENV)

const ActionsRefresh = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter = null, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate = true, // you can hide CreateButton if hasCreate = false
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => (
    <CardActions>
        <CreateButton
            // disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
    </CardActions>
);

const ToddlerDailyReportFilter = (props) => (
    <Filter {...props}>
        <TextInput alwaysOn label="Date" source="trdate" ></TextInput>
    </Filter>
);
const TeacherFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Enrollment Status" validate={validateEStatus} source="EStatus" allowEmpty={false} choices={[
            // { id: 'All', name: 'All' },
            { id: 'Active', name: 'Active' },
            // { id: 'Graduated', name: 'Graduated' },
            // { id: 'On Hold', name: 'On Hold' },
            // { id: 'Enrolled', name: 'Enrolled' },
            // { id: 'Disenrolled', name: 'Disenrolled' },
        ]} />
    </Filter>
);


export const ToddlerDailyReportsList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions !== undefined && permissions.role === 'admin') {
        return (
            <List  {...props} filters={<ToddlerDailyReportFilter />} filterDefaultValues={{ EStatus: "Active", Classroom: "Classroom 04" }} bulkActionButtons={<ToddlerDailyReportListActions {...props} />} sort={{ field: 'Sname', order: 'ASC' }} >
                <Datagrid >
                    <TextField label="Student Name" source="Sname" />
                    <TextField label="Report Date" source="today" />
                    <EditButton />
                </Datagrid>
            </List>
        );
    } else {
        return (
            <List  {...props} filters={<ToddlerDailyReportFilter />}  filter={{ Classroom: permissions !== undefined && permissions.classroom }} /*filters={<TeacherFilter />} filterDefaultValues={{ EStatus: "Active" }}*/ bulkActionButtons={< TeacherListActions {...props} />} actions={<ActionsRefresh {...props} />} >
                <Datagrid >
                    <TextField label="Student Name" source="Sname" />
                    <TextField label="Report Date" source="today" />
                    <EditButton />
                </Datagrid>
            </List >

        );
    }
};

const ToddlerDailyReportTitle = ({ record }) => {
    return <span>ToddlerDailyReport {record ? `"${record.id}"` : ''}</span>;
};

export const ToddlerDailyReportsEdit = (props) => (
    <Edit title={<ToddlerDailyReportTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Activities" >
                <Box style={{ width: "100%" }} >
                    <Box display="flex">
                        <Box>
                            <TextField label="Name" source="Sname" />
                        </Box>
                        <Box ml="2em">
                            <DateInput source="today" disabled />
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <span><b><u>Today Was</u></b></span>
                        </Box>
                        <Box >
                            <CheckboxGroupInput label="" source="mood" choices={[
                                { id: 'Happy', name: 'Happy' },
                                { id: 'Sad', name: 'Sad' },
                                { id: 'Tired', name: 'Tired' },
                                { id: 'Grumpy', name: 'Grumpy' },
                                { id: 'Busy', name: 'Busy' }
                            ]} />
                        </Box>
                    </Box>
                    <Box>
                        <Box >
                            <span><b><u>Went Oustide :</u></b></span>
                        </Box>
                        <Box display="flex" >
                            <TimeInput source="wof1" label="From" options={{ clearable: true }} />
                            <MyTab />
                            <TimeInput source="wot1" label="To" options={{ clearable: true }} />
                        </Box>
                        <Box display="flex">
                            <TimeInput source="wof2" label="From" options={{ clearable: true }} />
                            <MyTab />
                            <TimeInput source="wot2" label="To" options={{ clearable: true }} />
                        </Box>
                    </Box>
                    <Box >
                        <Box >
                            <span><b><u>Meals:</u></b></span>
                        </Box>
                        <Box display="flex">
                            <Box width="15%">
                                <b>AM Snack : </b>
                            </Box>
                            <Box width="40%">
                                <TextInput multiline label="Food/Drink" source="amfd" />
                            </Box>
                            <CheckboxGroupInput label="" source="amqty" choices={[
                                { id: 'Some', name: 'Some' },
                                { id: 'Most', name: 'Most' },
                                { id: 'All', name: 'All' }
                            ]} />
                        </Box>
                        <Box display="flex">
                            <Box width="15%">
                                <b>Lunch : </b>
                            </Box>
                            <Box width="40%">
                                <TextInput multiline label="Food/Drink" source="lfd" />
                            </Box>
                            <CheckboxGroupInput label="" source="lqty" choices={[
                                { id: 'Some', name: 'Some' },
                                { id: 'Most', name: 'Most' },
                                { id: 'All', name: 'All' }
                            ]} />
                        </Box>
                        <Box display="flex">
                            <Box width="15%">
                                <b>PM Snack : </b>
                            </Box>
                            <Box width="40%">
                                <TextInput multiline label="Food/Drink" source="pmfd" />
                            </Box>
                            <CheckboxGroupInput label="" source="pmqty" choices={[
                                { id: 'Some', name: 'Some' },
                                { id: 'Most', name: 'Most' },
                                { id: 'All', name: 'All' }
                            ]} />
                        </Box>
                        <Box display="flex">
                            <TextInput multiline label="Meal Notes" source="MealNotes" fullWidth />
                        </Box>
                    </Box>
                    <Box >
                        <Box >
                            <span><b><u>Diapers:</u></b></span><br /><br />
                        </Box>
                        <Box width="100%">
                            <Box display="flex" width="80%" >
                                <Box display="flex" >
                                    <TimeInput source="dt1" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="dt1type" choices={[
                                        { id: 'Dry', name: 'Dry' },
                                        { id: 'Wet', name: 'Wet' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="dt2" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="dt2type" choices={[
                                        { id: 'Dry', name: 'Dry' },
                                        { id: 'Wet', name: 'Wet' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="dt3" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="dt3type" choices={[
                                        { id: 'Dry', name: 'Dry' },
                                        { id: 'Wet', name: 'Wet' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="dt4" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="dt4type" choices={[
                                        { id: 'Dry', name: 'Dry' },
                                        { id: 'Wet', name: 'Wet' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                            </Box>
                            <Box display="flex" width="80%">
                                <Box display="flex">
                                    <TimeInput source="dt5" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="dt5type" choices={[
                                        { id: 'Dry', name: 'Dry' },
                                        { id: 'Wet', name: 'Wet' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="dt6" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="dt6type" choices={[
                                        { id: 'Dry', name: 'Dry' },
                                        { id: 'Wet', name: 'Wet' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />

                                </Box>
                                <Box display="flex">
                                    <TimeInput source="dt7" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="dt7type" choices={[
                                        { id: 'Dry', name: 'Dry' },
                                        { id: 'Wet', name: 'Wet' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />

                                </Box>
                                <Box display="flex">
                                    <TimeInput source="dt8" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="dt8type" choices={[
                                        { id: 'Dry', name: 'Dry' },
                                        { id: 'Wet', name: 'Wet' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box >
                        <Box >
                            <span><b><u>Potty Training:</u></b></span><br /><br />
                        </Box>
                        <Box width="100%">
                            <Box display="flex" width="80%" >
                                <Box display="flex" >
                                    <TimeInput source="pt1" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="pt1type" choices={[
                                        { id: 'Tried', name: 'Tried' },
                                        { id: 'Pee', name: 'Pee' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="pt2" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="pt2type" choices={[
                                        { id: 'Tried', name: 'Tried' },
                                        { id: 'Pee', name: 'Pee' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="pt3" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="pt3type" choices={[
                                        { id: 'Tried', name: 'Tried' },
                                        { id: 'Pee', name: 'Pee' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="pt4" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="pt4type" choices={[
                                        { id: 'Tried', name: 'Tried' },
                                        { id: 'Pee', name: 'Pee' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box >
                        <Box >
                            <b><u>Nap:</u></b>
                        </Box>
                        <Box display="flex">
                            <TimeInput source="nf1" label="From" options={{ clearable: true }} />
                            <MyTab />
                            <TimeInput source="nt1" label="To" options={{ clearable: true }} />
                        </Box>
                        {/* <Box display="flex">

                            <TimeInput source="nf2" label="From" options={{ clearable: true }} />
                            <MyTab />
                            <TimeInput source="nt2" label="To" options={{ clearable: true }} />
                        </Box> */}
                    </Box>
                    <Box >
                        <span><b><u>Notes:</u></b></span>
                        <TextInput multiline label="Notes" source="Notes" fullWidth />
                    </Box>
                </Box>
            </FormTab>
            <FormTab label="Training">
                <Box style={{ width: "100%" }} >
                    <Box display="flex">
                        <Box width="25%">
                            <CheckboxGroupInput label="" source="trg" choices={[
                                // { id: "Today's Weather Date", name: "Today's Weather/Date" },
                                { id: "Calendar", name: "Calendar" },
                                // { id: 'Potty Training', name: 'Potty Training' },
                                { id: 'Put on/Take off Jacket, Sweater', name: 'Put on/Take off Jacket, Sweater' },
                                { id: 'Hang Jacket/Sweater on hanger', name: 'Hang Jacket/Sweater on hanger' },
                                { id: 'Put on/Take off Shorts/Pants', name: 'Put on/Take off Shorts/Pants' },
                                { id: 'Put on/Take off Shoes, Socks', name: 'Put on/Take off Shoes, Socks' }
                            ]} />
                        </Box>
                        <Box >
                            <span><b>Circle Time Song:</b></span>
                            <TextInput label="" source="Circle Time Song" />
                        </Box>
                        <Box >
                            <span><b>Book:</b></span>
                            <TextInput label="" source="Book" />
                        </Box>
                    </Box>
                    <Box >
                            <span><b>Language:</b></span><Box><br></br></Box>
                            <Box >
                                <b>Name of Clothing </b>
                                <CheckboxGroupInput label="" source="TrgLanguageNoC" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Parts of Calendar </b>
                                <CheckboxGroupInput label="" source="TrgLanguagePoC" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Parts of Classroom</b>
                                <CheckboxGroupInput label="" source="TrgLanguagePoCl" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Training Notes" source="TrgNotes" fullWidth />
                        </Box> */}
                </Box>
            </FormTab>
            <FormTab label="Practical Life">
                <Box style={{ width: "100%" }} >
                    <Box display="flex" width="50%">
                        <Box >
                            <CheckboxGroupInput label="" source="pl1" choices={[
                                { id: 'Care of Self', name: "Care of Self" },
                                { id: 'Control of Movement', name: 'Control of Movement' },
                                { id: 'Exercise of Grace & Courtesy', name: 'Exercise of Grace & Courtesy' },
                                { id: 'Care of Environment', name: 'Care of Environment' },
                                { id: 'Small Muscle Development', name: 'Small Muscle Development' },
                            ]} />
                        </Box>
                    </Box>
                    <Box >
                            <span><b>Language:</b></span><Box><br></br></Box>
                            <Box >
                                <b>Name of Excercise </b>
                                <CheckboxGroupInput label="" source="PLLanguageNoE" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Name of Materials </b>
                                <CheckboxGroupInput label="" source="PLLanguageNoM" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Practical Life Notes" source="PLNotes" fullWidth />
                        </Box> */}
                </Box>
            </FormTab>
            <FormTab label="Sensorial">
                <Box style={{ width: "100%" }}>
                <Box display="flex">
                            <Box width="25%">
                                <span><b>Excercise for hand eye coordination:</b></span>
                                <CheckboxGroupInput label="" source="sn" choices={[
                                    { id: 'Color Tablets First Set', name: 'Color Tablets First Set' },
                                    // { id: 'Sorting', name: 'Sorting' },
                                    // { id: 'Color sorting and Matching', name: 'Color sorting and Matching' },
                                    // { id: 'Matching work', name: 'Matching work' },
                                    { id: 'Puzzles', name: 'Puzzles' },
                                    // { id: 'Threading', name: 'Threading' },
                                    { id: 'Geometric Shapes Rings', name: 'Geometric Shapes Rings' },
                                    // { id: 'Pink Tower', name: 'Pink Tower' },
                                // { id: 'Brown Stairs', name: 'Brown Stairs' },
                                // { id: 'Red Rods', name: 'Red Rods' },
                                { id: 'Knob Cylinders', name: 'Knob Cylinders' },
                                ]} />
                                 <Box >
                                    <span>Threading:</span>
                                    <CheckboxGroupInput label="" source="th" choices={[
                                        { id: 'Wooden Shapes', name: 'Wooden Shapes' },
                                        { id: 'Pasta', name: 'Pasta' },
                                    ]} />
                                </Box>
                                <Box >
                                    <span>Sorting:</span>
                                    <CheckboxGroupInput label="" source="sort" choices={[
                                        { id: 'Shape', name: 'Shape' },
                                        { id: 'Color', name: 'Color' },
                                        { id: 'Size', name: 'Size' },
                                    ]} />
                                </Box>
                                <Box >
                                    <span>Matching:</span>
                                    <CheckboxGroupInput label="" source="match" choices={[
                                        { id: 'Shape', name: 'Shape' },
                                        { id: 'Color', name: 'Color' },
                                        { id: 'Size', name: 'Size' },
                                    ]} />
                                </Box>
                                <Box >
                                    <span><b>Language:</b></span><Box><br></br></Box>
                                    <Box >
                                        <b>Name of Materials </b>
                                        <CheckboxGroupInput label="" source="SLanguageNoM" choices={[
                                            { id: 'English', name: 'English' },
                                            { id: 'Spanish', name: 'Spanish' },
                                        ]} />
                                    </Box>
                                    <Box >
                                        <b>Name of Colors </b>
                                        <CheckboxGroupInput label="" source="SLanguageNoC" choices={[
                                            { id: 'English', name: 'English' },
                                            { id: 'Spanish', name: 'Spanish' },
                                        ]} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box >
                                <span><b>Touch Sense:</b></span>
                                <CheckboxGroupInput label="" source="ts" choices={[
                                    { id: 'Rough and smooth boards', name: 'Rough and smooth boards' },
                                    // { id: 'Geometric Shapes Rings', name: 'Geometric Shapes Rings' },
                                    { id: 'Mystery Bags', name: 'Mystery Bags' },
                                    { id: 'Prisms and cubes bags', name: 'Prisms and cubes bags' },
                                    { id: 'Folding Clothes', name: 'Folding Clothes'},
                                    { id: 'Sand/Water Tray', name: 'Sand/Water Tray'},
                                ]} />
                                <Box >
                                    <span><b>Language:</b></span><Box><br></br></Box>
                                    <Box >
                                        <b>Name of Materials </b>
                                        <CheckboxGroupInput label="" source="SLanguageTSNoM" choices={[
                                            { id: 'English', name: 'English' },
                                            { id: 'Spanish', name: 'Spanish' },
                                        ]} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Sensorial Notes" source="SNotes" fullWidth />
                        </Box> */}
                </Box>
            </FormTab>
            <FormTab label="Math">
                <Box style={{ width: "100%" }}>
                <Box display="flex">
                            <Box width="25%">
                                <CheckboxGroupInput label="" source="ma" choices={[
                                    // { id: 'Number Rods(1-5)', name: 'Number Rods(1-5)' },
                                    { id: 'Sand Paper Numbers(1-9)', name: 'Sand Paper Numbers(1-9)' },
                                    { id: 'Geometric Shapes', name: 'Geometric Shapes' },
                                    // { id: 'Numerals and Counters 1-9', name: 'Numerals and Counters 1-9' },
                                    // { id: 'Colored Bead Stairs', name: 'Colored Bead Stairs' },
                                    // { id: 'Matching Geometric shapes to the picture', name: 'Matching Geometric shapes to the picture' }
                                ]} />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>Language:</b></span><Box><br></br></Box>
                            <Box >
                                <b>Name of Numbers </b>
                                <CheckboxGroupInput label="" source="MLanguageNoN" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Name of Shapes </b>
                                <CheckboxGroupInput label="" source="MLanguageNoS" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Math Notes" source="MNotes" fullWidth />
                        </Box> */}
                </Box>
            </FormTab>
            <FormTab label="Language">
                <Box style={{ width: "100%" }}>
                <Box display="flex">
                            <Box width="20%">
                                <b>Vocabulary Flash cards</b>
                                <CheckboxGroupInput label="" source="vfc" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <span><b>Pre-Writing:</b></span>
                                <CheckboxGroupInput label="" source="pw" choices={[
                                    { id: 'Sand Tray', name: 'Sand Tray' },
                                    // { id: 'Metal inserts First Presentation', name: 'Metal inserts First Presentation' }
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Language Notes" source="LNotes" fullWidth />
                        </Box> */}
                </Box>
            </FormTab>
            <FormTab label="Arts/Crafts">
                <Box style={{ width: "100%" }}>
                <Box display="flex">
                            <Box width="20%">
                                <CheckboxGroupInput label="" source="ac" choices={[
                                    { id: 'Build Blocks', name: 'Build Blocks' },
                                    { id: 'Chalk Board', name: 'Chalk Board' },
                                    { id: 'Color Maze', name: 'Color Maze' },
                                    { id: 'Paint', name: 'Paint' },
                                    { id: 'Seasonal art project', name: 'Seasonal art project' },
                                    { id: 'Water color paint', name: 'Water color paint' },
                                    { id: 'PlayDoh', name: 'PlayDoh' }
                                ]} />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>Language:</b></span><Box><br></br></Box>
                            <Box >
                                <b>Name of Excercise </b>
                                <CheckboxGroupInput label="" source="ACLanguageNoE" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Name of Materials </b>
                                <CheckboxGroupInput label="" source="ACLanguageNoM" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Name of Projects </b>
                                <CheckboxGroupInput label="" source="ACLanguageNoP" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Arts Crafts Notes" source="ACNotes" fullWidth />
                        </Box> */}
                </Box>
            </FormTab>
        </TabbedForm >
    </Edit>
);


export const ToddlerDailyReportsCreate = (props) => {
    var today = new Date();
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label="Activities" >
                    <Box style={{ width: "100%" }} >
                        <Box display="flex">
                            <Box>
                                <ReferenceInput label="Name" source="id" reference="Students" sort="Sname" order="ASC" filter={{ Classroom: "Classroom 03" }} >
                                    <SelectInput optionText={(record) => { let val = record.Sname; return val; }} />
                                </ReferenceInput>
                            </Box>
                            <Box ml="2em">
                                <DateInput source="today" initialValue={today} />
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <span><b><u>Today Was</u></b></span>
                            </Box>
                            <Box >
                                <CheckboxGroupInput label="" source="mood" choices={[
                                    { id: 'Happy', name: 'Happy' },
                                    { id: 'Sad', name: 'Sad' },
                                    { id: 'Tired', name: 'Tired' },
                                    { id: 'Grumpy', name: 'Grumpy' },
                                    { id: 'Busy', name: 'Busy' }
                                ]} />
                            </Box>
                        </Box>
                        <Box>
                            <Box >
                                <span><b><u>Went Oustide :</u></b></span>
                            </Box>
                            <Box display="flex" >
                                <TimeInput source="wof1" label="From" options={{ clearable: true }} />
                                <MyTab />
                                <TimeInput source="wot1" label="To" options={{ clearable: true }} />
                            </Box>
                            <Box display="flex">
                                <TimeInput source="wof2" label="From" options={{ clearable: true }} />
                                <MyTab />
                                <TimeInput source="wot2" label="To" options={{ clearable: true }} />
                            </Box>
                        </Box>
                        <Box >
                            <Box >
                                <span><b><u>Meals:</u></b></span>
                            </Box>
                            <Box display="flex">
                                <Box width="15%">
                                    <b>AM Snack : </b>
                                </Box>
                                <Box width="40%">
                                    <TextInput multiline label="Food/Drink" source="amfd" />
                                </Box>
                                <CheckboxGroupInput label="" source="amqty" choices={[
                                    { id: 'Some', name: 'Some' },
                                    { id: 'Most', name: 'Most' },
                                    { id: 'All', name: 'All' }
                                ]} />
                            </Box>
                            <Box display="flex">
                                <Box width="15%">
                                    <b>Lunch : </b>
                                </Box>
                                <Box width="40%">
                                    <TextInput multiline label="Food/Drink" source="lfd" />
                                </Box>
                                <CheckboxGroupInput label="" source="lqty" choices={[
                                    { id: 'Some', name: 'Some' },
                                    { id: 'Most', name: 'Most' },
                                    { id: 'All', name: 'All' }
                                ]} />
                            </Box>
                            <Box display="flex">
                                <Box width="15%">
                                    <b>PM Snack : </b>
                                </Box>
                                <Box width="40%">
                                    <TextInput multiline label="Food/Drink" source="pmfd" />
                                </Box>
                                <CheckboxGroupInput label="" source="pmqty" choices={[
                                    { id: 'Some', name: 'Some' },
                                    { id: 'Most', name: 'Most' },
                                    { id: 'All', name: 'All' }
                                ]} />
                            </Box>
                            <Box display="flex">
                            <TextInput multiline label="Meal Notes" source="MealNotes" fullWidth />
                        </Box>
                        </Box>
                        <Box >
                            <Box >
                                <span><b><u>Diapers:</u></b></span><br /><br />
                            </Box>
                            <Box width="100%">
                                <Box display="flex" width="80%" >
                                    <Box display="flex" >
                                        <TimeInput source="dt1" label="Time" options={{ clearable: true }} />
                                        <MyTab />
                                        <CheckboxGroupInput label="" source="dt1type" choices={[
                                            { id: 'Dry', name: 'Dry' },
                                            { id: 'Wet', name: 'Wet' },
                                            { id: 'BM', name: 'BM' }
                                        ]} />
                                    </Box>
                                    <Box display="flex">
                                        <TimeInput source="dt2" label="Time" options={{ clearable: true }} />
                                        <MyTab />
                                        <CheckboxGroupInput label="" source="dt2type" choices={[
                                            { id: 'Dry', name: 'Dry' },
                                            { id: 'Wet', name: 'Wet' },
                                            { id: 'BM', name: 'BM' }
                                        ]} />
                                    </Box>
                                    <Box display="flex">
                                        <TimeInput source="dt3" label="Time" options={{ clearable: true }} />
                                        <MyTab />
                                        <CheckboxGroupInput label="" source="dt3type" choices={[
                                            { id: 'Dry', name: 'Dry' },
                                            { id: 'Wet', name: 'Wet' },
                                            { id: 'BM', name: 'BM' }
                                        ]} />
                                    </Box>
                                    <Box display="flex">
                                        <TimeInput source="dt4" label="Time" options={{ clearable: true }} />
                                        <MyTab />
                                        <CheckboxGroupInput label="" source="dt4type" choices={[
                                            { id: 'Dry', name: 'Dry' },
                                            { id: 'Wet', name: 'Wet' },
                                            { id: 'BM', name: 'BM' }
                                        ]} />
                                    </Box>
                                </Box>
                                <Box display="flex" width="80%">
                                    <Box display="flex">
                                        <TimeInput source="dt5" label="Time" options={{ clearable: true }} />
                                        <MyTab />
                                        <CheckboxGroupInput label="" source="dt5type" choices={[
                                            { id: 'Dry', name: 'Dry' },
                                            { id: 'Wet', name: 'Wet' },
                                            { id: 'BM', name: 'BM' }
                                        ]} />
                                    </Box>
                                    <Box display="flex">
                                        <TimeInput source="dt6" label="Time" options={{ clearable: true }} />
                                        <MyTab />
                                        <CheckboxGroupInput label="" source="dt6type" choices={[
                                            { id: 'Dry', name: 'Dry' },
                                            { id: 'Wet', name: 'Wet' },
                                            { id: 'BM', name: 'BM' }
                                        ]} />

                                    </Box>
                                    <Box display="flex">
                                        <TimeInput source="dt7" label="Time" options={{ clearable: true }} />
                                        <MyTab />
                                        <CheckboxGroupInput label="" source="dt7type" choices={[
                                            { id: 'Dry', name: 'Dry' },
                                            { id: 'Wet', name: 'Wet' },
                                            { id: 'BM', name: 'BM' }
                                        ]} />

                                    </Box>
                                    <Box display="flex">
                                        <TimeInput source="dt8" label="Time" options={{ clearable: true }} />
                                        <MyTab />
                                        <CheckboxGroupInput label="" source="dt8type" choices={[
                                            { id: 'Dry', name: 'Dry' },
                                            { id: 'Wet', name: 'Wet' },
                                            { id: 'BM', name: 'BM' }
                                        ]} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box >
                        <Box >
                            <span><b><u>Potty Training:</u></b></span><br /><br />
                        </Box>
                        <Box width="100%">
                            <Box display="flex" width="80%" >
                                <Box display="flex" >
                                    <TimeInput source="pt1" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="pt1type" choices={[
                                        { id: 'Tried', name: 'Tried' },
                                        { id: 'Pee', name: 'Pee' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="pt2" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="pt2type" choices={[
                                        { id: 'Tried', name: 'Tried' },
                                        { id: 'Pee', name: 'Pee' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="pt3" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="pt3type" choices={[
                                        { id: 'Tried', name: 'Tried' },
                                        { id: 'Pee', name: 'Pee' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                                <Box display="flex">
                                    <TimeInput source="pt4" label="Time" options={{ clearable: true }} />
                                    <MyTab />
                                    <CheckboxGroupInput label="" source="pt4type" choices={[
                                        { id: 'Tried', name: 'Tried' },
                                        { id: 'Pee', name: 'Pee' },
                                        { id: 'BM', name: 'BM' }
                                    ]} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                        <Box >
                            <Box >
                                <b><u>Nap:</u></b>
                            </Box>
                            <Box display="flex">
                                <TimeInput source="nf1" label="From" options={{ clearable: true }} />
                                <MyTab />
                                <TimeInput source="nt1" label="To" options={{ clearable: true }} />
                            </Box>
                            {/* <Box display="flex">

                                <TimeInput source="nf2" label="From" options={{ clearable: true }} />
                                <MyTab />
                                <TimeInput source="nt2" label="To" options={{ clearable: true }} />
                            </Box> */}
                        </Box>
                        <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Notes" source="Notes" fullWidth />
                        </Box>
                    </Box>
                </FormTab>
                <FormTab label="Training">
                    <Box style={{ width: "100%" }} >
                        <Box display="flex">
                            <Box width="25%">
                                <CheckboxGroupInput label="" source="trg" choices={[
                                    // { id: "Today's Weather Date", name: "Today's Weather/Date" },
                                    { id: "Calendar", name: "Calendar" },
                                    // { id: 'Potty Training', name: 'Potty Training' },
                                    { id: 'Put on/Take off Jacket, Sweater', name: 'Put on/Take off Jacket, Sweater' },
                                    { id: 'Hang Jacket/Sweater on hanger', name: 'Hang Jacket/Sweater on hanger' },
                                    { id: 'Put on/Take off Shorts/Pants', name: 'Put on/Take off Shorts/Pants' },
                                    { id: 'Put on/Take off Shoes, Socks', name: 'Put on/Take off Shoes, Socks' }
                                ]} />
                            </Box>
                            <Box >
                                <span><b>Circle Time Song:</b></span>
                                <TextInput label="" source="Circle Time Song" />
                            </Box>
                            <Box >
                                <span><b>Book:</b></span>
                                <TextInput label="" source="Book" />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>Language:</b></span><Box><br></br></Box>
                            <Box >
                                <b>Name of Clothing </b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Parts of Calendar </b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Parts of Classroom</b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Training Notes" source="TrgNotes" fullWidth />
                        </Box> */}
                    </Box>
                </FormTab>
                <FormTab label="Practical Life">
                    <Box style={{ width: "100%" }} >
                        <Box display="flex" width="50%">
                            <Box >
                                <CheckboxGroupInput label="" source="pl1" choices={[
                                    { id: 'Care of Self', name: "Care of Self" },
                                    { id: 'Control of Movement', name: 'Control of Movement' },
                                    { id: 'Exercise of Grace & Courtesy', name: 'Exercise of Grace & Courtesy' },
                                    { id: 'Care of Environment', name: 'Care of Environment' },
                                    { id: 'Small Muscle Development', name: 'Small Muscle Development' },
                                ]} />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>Language:</b></span><Box><br></br></Box>
                            <Box >
                                <b>Name of Excercise </b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Name of Materials </b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Practical Life Notes" source="PLNotes" fullWidth />
                        </Box> */}
                    </Box>
                </FormTab>
                <FormTab label="Sensorial">
                    <Box style={{ width: "100%" }}>
                        <Box display="flex">
                            <Box width="25%">
                                <span><b>Excercise for hand eye coordination:</b></span>
                                <CheckboxGroupInput label="" source="sn" choices={[
                                    { id: 'Color Tablets First Set', name: 'Color Tablets First Set' },
                                    // { id: 'Sorting', name: 'Sorting' },
                                    // { id: 'Color sorting and Matching', name: 'Color sorting and Matching' },
                                    // { id: 'Matching work', name: 'Matching work' },
                                    { id: 'Puzzles', name: 'Puzzles' },
                                    // { id: 'Threading', name: 'Threading' },
                                    { id: 'Geometric Shapes Rings', name: 'Geometric Shapes Rings' },
                                    // { id: 'Pink Tower', name: 'Pink Tower' },
                                // { id: 'Brown Stairs', name: 'Brown Stairs' },
                                // { id: 'Red Rods', name: 'Red Rods' },
                                { id: 'Knob Cylinders', name: 'Knob Cylinders' },
                                ]} />
                                 <Box >
                                    <span>Threading:</span>
                                    <CheckboxGroupInput label="" source="ts" choices={[
                                        { id: 'Wooden Shapes', name: 'Wooden Shapes' },
                                        { id: 'Pasta', name: 'Pasta' },
                                    ]} />
                                </Box>
                                <Box >
                                    <span>Sorting:</span>
                                    <CheckboxGroupInput label="" source="ts" choices={[
                                        { id: 'Shape', name: 'Shape' },
                                        { id: 'Color', name: 'Color' },
                                        { id: 'Size', name: 'Size' },
                                    ]} />
                                </Box>
                                <Box >
                                    <span>Matching:</span>
                                    <CheckboxGroupInput label="" source="ts" choices={[
                                        { id: 'Shape', name: 'Shape' },
                                        { id: 'Color', name: 'Color' },
                                        { id: 'Size', name: 'Size' },
                                    ]} />
                                </Box>
                                <Box >
                                    <span><b>Language:</b></span><Box><br></br></Box>
                                    <Box >
                                        <b>Name of Materials </b>
                                        <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                            { id: 'English', name: 'English' },
                                            { id: 'Spanish', name: 'Spanish' },
                                        ]} />
                                    </Box>
                                    <Box >
                                        <b>Name of Colors </b>
                                        <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                            { id: 'English', name: 'English' },
                                            { id: 'Spanish', name: 'Spanish' },
                                        ]} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box >
                                <span><b>Touch Sense:</b></span>
                                <CheckboxGroupInput label="" source="ts" choices={[
                                    { id: 'Rough and smooth boards', name: 'Rough and smooth boards' },
                                    // { id: 'Geometric Shapes Rings', name: 'Geometric Shapes Rings' },
                                    { id: 'Mystery Bags', name: 'Mystery Bags' },
                                    { id: 'Prisms and cubes bags', name: 'Prisms and cubes bags' },
                                    { id: 'Folding Clothes', name: 'Folding Clothes'},
                                    { id: 'Sand/Water Tray', name: 'Sand/Water Tray'},
                                ]} />
                                <Box >
                                    <span><b>Language:</b></span><Box><br></br></Box>
                                    <Box >
                                        <b>Name of Materials </b>
                                        <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                            { id: 'English', name: 'English' },
                                            { id: 'Spanish', name: 'Spanish' },
                                        ]} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Sensorial Notes" source="SNotes" fullWidth />
                        </Box> */}
                    </Box>
                </FormTab>
                <FormTab label="Math">
                    <Box style={{ width: "100%" }}>
                        <Box display="flex">
                            <Box width="25%">
                                <CheckboxGroupInput label="" source="ma" choices={[
                                    // { id: 'Number Rods(1-5)', name: 'Number Rods(1-5)' },
                                    { id: 'Sand Paper Numbers(1-9)', name: 'Sand Paper Numbers(1-9)' },
                                    { id: 'Geometric Shapes', name: 'Geometric Shapes' },
                                    // { id: 'Numerals and Counters 1-9', name: 'Numerals and Counters 1-9' },
                                    // { id: 'Colored Bead Stairs', name: 'Colored Bead Stairs' },
                                    // { id: 'Matching Geometric shapes to the picture', name: 'Matching Geometric shapes to the picture' }
                                ]} />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>Language:</b></span><Box><br></br></Box>
                            <Box >
                                <b>Name of Numbers </b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Name of Shapes </b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Math Notes" source="MNotes" fullWidth />
                        </Box> */}
                    </Box>
                </FormTab>
                <FormTab label="Language">
                    <Box style={{ width: "100%" }}>
                        <Box display="flex">
                            <Box width="20%">
                                <b>Vocabulary Flash cards</b>
                                <CheckboxGroupInput label="" source="vfc" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <span><b>Pre-Writing:</b></span>
                                <CheckboxGroupInput label="" source="pw" choices={[
                                    { id: 'Sand Tray', name: 'Sand Tray' },
                                    // { id: 'Metal inserts First Presentation', name: 'Metal inserts First Presentation' }
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Language Notes" source="LNotes" fullWidth />
                        </Box> */}
                    </Box>
                </FormTab>
                <FormTab label="Arts/Crafts">
                    <Box style={{ width: "100%" }}>
                        <Box display="flex">
                            <Box width="20%">
                                <CheckboxGroupInput label="" source="ac" choices={[
                                    { id: 'Build Blocks', name: 'Build Blocks' },
                                    { id: 'Chalk Board', name: 'Chalk Board' },
                                    { id: 'Color Maze', name: 'Color Maze' },
                                    { id: 'Paint', name: 'Paint' },
                                    { id: 'Seasonal art project', name: 'Seasonal art project' },
                                    { id: 'Water color paint', name: 'Water color paint' },
                                    { id: 'PlayDoh', name: 'PlayDoh' }
                                ]} />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>Language:</b></span><Box><br></br></Box>
                            <Box >
                                <b>Name of Excercise </b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Name of Materials </b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                            <Box >
                                <b>Name of Projects </b>
                                <CheckboxGroupInput label="" source="TrgLanguage" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                ]} />
                            </Box>
                        </Box>
                        {/* <Box >
                            <span><b><u>Notes:</u></b></span>
                            <TextInput multiline label="Arts Crafts Notes" source="ACNotes" fullWidth />
                        </Box> */}
                    </Box>
                </FormTab>

            </TabbedForm >
        </Create >
    );
}



const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const ToddlerDailyReportListActions = props => (
    <TopToolbar>
        <BulkDeleteButton {...props} undoable="true" /><MyTab /><MyTab /><MyTab /><MyTab />
        <ToddlerReportButton {...props} />
    </TopToolbar>
);

const TeacherListActions = props => (
    <TopToolbar>
        {/* <BulkDeleteButton {...props} undoable="true" /><MyTab /><MyTab /><MyTab /><MyTab /> */}
        <ToddlerReportButton {...props} />
    </TopToolbar>
);
