import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';


function SimpleDialog(props) {
  const { onClose, setreason, setItem, setAmount, open } = props;

  //console.log(selectedValueMonth, setSelectedValueMonth)

  const handleClose = () => {
    //console.log("Value for RC", selectedValueMonth);
    onClose();
  };


  const handleReasonChange = (event) => {
    setreason(event.target.value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="form-dialog-title">Invoice Details</DialogTitle>
        <DialogContent>
        <TextField
            autoFocus
            margin="dense"
            id="ReasonForBlocking"
            label="Reason for Blocking"
            type="text"
            fullWidth
            onChange={handleReasonChange}
          />
        </DialogContent>
      <Button variant="contained" onClick={() => handleClose()}>Submit</Button>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

class BlockTourButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      reason: ""
    };
  }
  updateReason = (reason) => {
    this.setState((state, props) => {
      return state.reason=reason;
    });
  }

  handleClickOpen = () => {
    //console.log("open ", this.state.open);
    this.setState((state, props) => {
      return { open: true };
    });
    //console.log("In click open", this.state.open)
  };

  handleClose = () => {
    //console.log("in gen receipt" + process.env.REACT_APP_GENRCPT_URL);
    this.setState((state, props) => {
      return { open: false };
    });
    const fetchURL = process.env.REACT_APP_BLOCKTOURDATES_URL
    const { push, selectedIds, showNotification } = this.props;
    //console.log(fetchURL)
    //  //console.log(this.props);
    //console.log("sending " + this.props.selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    })
    const params = new Object();
    params.reason = this.state.reason;
    params.tids = this.props.selectedIds;
    const bdy = JSON.stringify(params);
    //console.log(bdy)
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              this.props.showNotification("Tour Dates Blocked");
              push('/tours/TourDetails');

            } else {
              this.props.showNotification("Tour Dates Blocked");
              push('/tours/TourDetails');
            }
          });
        } else if (response.status === '401' ) {
          showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            console.log(response, json);
            if (json != "") {
              this.props.showNotification("Unable to block tour dates error code: " + response.status);

            } else {
              this.props.showNotification("Error blocking tour dates");
            }
          });
        }
      })
      .catch((e) => {
        //console.error(e);
        this.props.showNotification('Error blocking tour dates', 'warning')
      });
  }

  render() {
    return <div><Tooltip title="Block Tour Dates"><IconButton color="primary" component="span" onClick={this.handleClickOpen}>
      <BlockIcon style={{ color: "red" }} />
    </IconButton></Tooltip>
      <SimpleDialog setreason={this.updateReason} open={this.state.open} onClose={this.handleClose} />
    </div>
  }
}

BlockTourButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(BlockTourButton);

