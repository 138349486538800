
// in src/MyLogoutButton.js
import React from 'react';
import { connect } from 'react-redux';
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';

import { Responsive, userLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import { useAuth0 } from '@auth0/auth0-react';


const MyLogoutButton = forwardRef((props, ref) => {
    // console.log("In logout");
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout,
    } = useAuth0();
    const logoutFn = useLogout();
    const handleClick = () => {
        logout({
            returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URL
        });
        logoutFn();
    }
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon /> Logout.....
        </MenuItem>
    );
});

export default MyLogoutButton;