import React from 'react';
import { regex, Create, Filter, BulkDeleteButton, TopToolbar, List, Edit, Datagrid, TextField, EditButton, ReferenceInput, SelectInput, SimpleForm, TextInput, required, } from 'react-admin';
import { Box, useMediaQuery, Typography, CardActions } from '@material-ui/core';
import MyTab from './mytab';
import { getYears } from './utils';




const Aside = ({ data, ids }) => {
    // console.log(data, ids);
    var numrcpt = ids.map(id => data[id]).reduce((numrcpt, rec) => {
        return numrcpt + 1
    }, 0);
    var tottut = ids.map(id => data[id]).reduce((tottut, rec) => {
        var tut = parseFloat(rec.Amount)
        if (!isNaN(tut)) {
            return tottut + tut
        } 
    }, 0.0);
    return (
        <div style={{ width: 200, margin: '1em' }} >
            <Typography >Details:</Typography>
            <Typography variant="body1">
                <br />
                <Typography ># of Payments</Typography>
                {numrcpt}
                <br />
                <Typography >Total Amount</Typography>
                ${tottut}
            </Typography>
        </div >
    );
}


const PaymentFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Month" validate={required()} source="Month" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 0, name: 'Jan' },
            { id: 1, name: 'Feb' },
            { id: 2, name: 'Mar' },
            { id: 3, name: 'Apr' },
            { id: 4, name: 'May' },
            { id: 5, name: 'Jun' },
            { id: 6, name: 'Jul' },
            { id: 7, name: 'Aug' },
            { id: 8, name: 'Sep' },
            { id: 9, name: 'Oct' },
            { id: 10, name: 'Nov' },
            { id: 11, name: 'Dec' },
        ]} />
        {/* <SelectInput alwaysOn label="Year" validate={required()} source="Year" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 2023, name: '2023' },
            { id: 2024, name: '2024' },
        ]} /> */}
        <SelectInput alwaysOn label="Year" validate={required()} source="Year" allowEmpty={false} choices={getYears()} />
        <SelectInput alwaysOn label="Processed" validate={required()} source="Processed" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Y', name: 'Y' },
            { id: 'N', name: 'N' },
        ]} />
    </Filter >
);

var d = new Date();
var mnth = d.getMonth();
var yr = d.getFullYear();
// console.log(mnth)

const validateAmt = [required(), regex(/^\d+(\.\d{1,2})?$/, "Must be a number")];


export const PaymentCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box p="1em">
                <Box display="flex">
                    <Box >
                        <Typography variant="h6" gutterBottom>New Payment</Typography>
                        <Box>
                            <Box mr="2em">
                                <ReferenceInput alwaysOn label="Student ID" source="id" reference="Students" filter={{ FEStatus: "Active:Pending" }} sort={{ field: 'Sname', order: 'ASC' }}>
                                    <SelectInput optionText={(record) => { let val = record.Parents.length>1 ?  record.Sname + ":" + record.Parents[0].PName+"/"+record.Parents[1].PName : record.Sname + ":" + record.Parents[0].PName ; return val; }} />
                                </ReferenceInput>
                            </Box>
                            <Box mr="2em">
                                <TextInput label="Amount" source="Amount" validate={validateAmt} />
                            </Box>
                            <Box mr="2em">
                                <SelectInput label="Payment Method" source="PmtMethod" choices={[
                                    { id: 'venmo', name: 'Venmo' },
                                    { id: 'cash', name: 'Cash' },
                                    { id: 'check', name: 'Check' }
                                ]} validate={required()} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </SimpleForm>
    </Create>
);

export const PaymentList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        // <List filters={<PaymentFilter />} aside={<Aside />} filterDefaultValues={{ Month: mnth, Year: yr, RcptType: "tuition", Paid: "N" }} bulkActionButtons={<PaymentListActions {...props} />} {...props}>
        <List filters={<PaymentFilter />} aside={<Aside />} filterDefaultValues={{ Month: mnth, Year: yr, Processed: "N" }} {...props}>
            {isSmall ? (
                <Datagrid>
                    <TextField label="Payee Name" source="Name" />
                    <TextField label="Amount" source="Amount" />
                    <TextField label="Payment Date" source="PaymentDate" />
                    <TextField label="Payment Method" source="PmtMethod" />
                    <TextField label="Processed" source="Processed" />
                    <EditButton />
                </Datagrid>
            ) : (
                <Datagrid>
                    <TextField label="Payee Name" source="Name" />
                    <TextField label="Amount" source="Amount" />
                    <TextField label="Payment Date" source="PaymentDate" />
                    <TextField label="Payment ID" source="VenmoPaymentID" />
                    <TextField label="Payment Method" source="PmtMethod" />
                    <TextField label="Processed" source="Processed" />
                    <EditButton />
                    {/* <PaymentEditButton /> */}
                </Datagrid>)}
        </List>
    );
};

const PaymentTitle = ({ record }) => {
    return <span>Payment {record ? `"${record.id}"` : ''}</span>;
};

export const PaymentEdit = (props) => (
    <Edit title={<PaymentTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput label="Payee Name" source="Name" />
            <TextInput label="Amount" source="Amount" />
            <SelectInput label="Processed" source="Processed" choices={[
                { id: 'Y', name: 'Y' },
                { id: 'N', name: 'N' },
            ]} />
            <TextInput label="Payment Date" source="PaymentDate" />
            <TextInput label="Payment ID" source="VenmoPaymentID" />
            <SelectInput label="Payment Method" source="PmtMethod" choices={[
                { id: 'venmo', name: 'Venmo' },
                { id: 'cash', name: 'Cash' },
                { id: 'check', name: 'Check' }
            ]} />
        </SimpleForm>
    </Edit>
);

const PaymentListActions = props => (
    <TopToolbar>
        <BulkDeleteButton {...props} undoable="true" /><MyTab /><MyTab /><MyTab /><MyTab />
    </TopToolbar>
);
