import React from 'react';

const MyImage = ({ record, type }) => {
    // console.log("myimage ", record)
    if (type === undefined && record.Signature != "") {
        return (
            < img src={`data:image/jpeg;base64,${record.Signature}`} width="30" height="30" />
        );
    } else
        if (type === "IN" && record.INSignature != "") {
            return (
                < img src={`data:image/jpeg;base64,${record.INSignature}`} width="30" height="30" />
            );
        } else
            if (type === "OUT" && record.OUTSignature != "") {
                return (
                    < img src={`data:image/jpeg;base64,${record.OUTSignature}`} width="30" height="30" />
                );
            } else {
                return (<div></div>);
            }
}

export default MyImage;