import {
  SaveButton,
  useCreate,
  FormWithRedirect, TextInput, SimpleForm, showNotification as showNotificationAction
} from 'react-admin';
import { Box } from '@material-ui/core';
import React, { useState, Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import IconCancel from '@material-ui/icons/Cancel';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MyTime from './MyTime.js';


const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const convertToDate = (tm) => {
  if (tm == undefined || tm == "") {
    return "";
  }
  let d = new Date();

  let [hours, minutes] = tm.split(':');

  d.setHours(+hours); // Set the hours, using implicit type coercion
  d.setMinutes(minutes); // You can pass Number or String. It doesn't really matter
  return d;
}
function SimpleDialog(props) {
  const classes = useStyles();
  const [{ loading }] = useCreate('posts');
  const [showDialog, setShowDialog] = useState(false);
  const { onClose, open, onCancel } = props;

  //console.log("state of open", open)

  const handleClose = (values) => {
    //console.log("Value for TR", values);
    //convert time into date
    values.wof1 = convertToDate(values.wof1);
    values.wot1 = convertToDate(values.wot1);
    values.wof2 = convertToDate(values.wof2);
    values.wot2 = convertToDate(values.wot2);
    onClose(values);
  };

  // const handleListItemClick = (valueYear, valueSemester) => {
  //   onClose(valueYear, valueSemester);
  // };

  // const handleChangeYear = (event) => {
  //   // setSelectedValueYear(event.target.value);
  //   setSelectedValueYear(event.target.value);
  // };

  // const handleChangeSemester = (event) => {
  //   // setSelectedValueSemester(event.target.value);
  //   setSelectedValueSemester(event.target.value);
  // };

  // const handleCloseClick = () => {
  //   open = false;
  // };


  return (
    <Dialog onClose={onCancel} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Enter Common Toddler Report Data</DialogTitle>
      <FormWithRedirect
        resource="ToddlerReports"
        save={handleClose}
        render={({
          handleSubmitWithRedirect,
          pristine,
          saving
        }) => (
          <>
            <DialogContent>
              <Box>
                <span><b><u>Went Oustide :</u></b></span>
                <Box display="flex">
                  <MyTime source="wof1" id="wof1" label="From" />
                  <MyTime source="wot1" id="wot1" label="To" />
                </Box>
                <Box display="flex">
                  <MyTime source="wof2" id="wof2" label="From" />
                  <MyTime source="wot2" id="wot2" label="To" />
                </Box>
                <Box >
                  <span><b>Circle Time Song:</b></span>
                  <TextInput label="" source="Circle Time Song" />
                </Box>
                <Box >
                  <span><b>Book:</b></span>
                  <TextInput label="" source="Book" />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} disabled={loading}>
                Cancel<IconCancel />
              </Button>
              <SaveButton
                handleSubmitWithRedirect={
                  handleSubmitWithRedirect
                }
                pristine={pristine}
                saving={saving}
                disabled={loading}
              />
            </DialogActions>
          </>
        )}
      />

      {/* <Button variant="contained" onClick={() => handleClose()}>Submit</Button> */}
    </Dialog >
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

// const UpdateTRCommonData = ({ selectedIds,push,showNotification }) => {
class UpdateTRCommonData extends Component {
  constructor(props) {
    super(props);
    // //console.log("In Generate RC comp", this.props.push, this.props.selectedIds, this.props.showNotification);
    //     const { push, selectedIds, showNotification } = this.props;

    // //console.log("got in RC button",selectedIds,push,showNotification);
    //   const [open, setOpen] = React.useState(false);
    //   const [selectedValueYear, setSelectedValueYear] = React.useState('');
    //   const [selectedValueSemester, setSelectedValueSemester] = React.useState('');
    this.state = {
      open: false
    };
  }

  // updateYear = (year) => {
  //   this.setState((state, props) => {
  //     return { selectedValueYear: year };
  //   });
  // }

  // updateSemester = (sems) => {
  //   this.setState((state, props) => {
  //     return { selectedValueSemester: sems };
  //   });
  // }
  handleClickOpen = () => {
    //console.log("open ", this.state.open);
    // this.state.open=true;
    this.setState((state, props) => {
      return { open: true };
    });
    //console.log("In click open", this.state.open)
  };

  handleClickCancel = () => {
    //console.log("cancel ", this.state.open);
    // this.state.open=true;
    this.setState((state, props) => {
      return { open: false };
    });
    //console.log("In click cancel", this.state.open)
  };

  handleClose = (values) => {
    this.setState((state, props) => {
      return { open: false };
    });
    //console.log("Value for TR go API ", values);
    // this.state.selectedValueYear = valueYear;
    // this.state.setSelectedValueSemester = valueSemester;
    const fetchURL = process.env.REACT_APP_GO_CONNECT_URL + "/ToddlerReports"
    //console.log(fetchURL)
    //  const { push, selectedIds, showNotification } = this.props;
    //  //console.log(this.props);
    //console.log("sending " + this.props.selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      // "Content-Length": JSON.stringify(body).length
    })
    // const bdy="{\"studs\":[{\"id\":\"8bb9e4a0-5387-11ea-8d8c-f8cab832656a\",\"sname\":\"Test04\",\"Sdob\":\"01/01/2018\",\"sdoj\":\"02/10/2019\",\"semail\":\"\",\"eStatus\":\"Active\",\"graduationDate\":\"\",\"schoolID\":3,\"program\":\"fullday\",\"tuition\":\"$875\",\"sex\":\"F\",\"pIN\":\"8976\",\"immunizationStatus\":\"complete\",\"classroom\":\"montessori01\",\"parents\":[{\"pName\":\"parent01\",\"address\":\"abc, AZ 85001\",\"tel\":\"6230019999\",\"email\":\"abc@xyz.com\"},{\"pName\":\"parent02\",\"address\":\"abc, AZ 85002\",\"tel\":\"6230019990\",\"email\":\"abc2@xyz.com\"}]},{\"id\":\"98e64254-537e-11ea-aab6-f8cab832656a\",\"Sname\":\"newtest01\",\"Sdob\":\"01/01/1999\",\"Sdoj\":\"\",\"Semail\":\"\",\"EStatus\":\"Graduated\",\"GraduationDate\":\"\",\"SchoolID\":3,\"Program\":\"Toddler - FullDay\",\"Tuition\":\"\",\"Sex\":\"F\",\"PIN\":\"9876\",\"ImmunizationStatus\":\"Not Provided\",\"Classroom\":\"Classroom 03\"},{\"id\":\"2dba6ce6-537f-11ea-aab6-f8cab832656a\",\"Sname\":\"newtest01\",\"Sdob\":\"1/1/1999\",\"Sdoj\":\"\",\"Semail\":\"\",\"EStatus\":\"\",\"GraduationDate\":\"\",\"SchoolID\":3,\"Program\":\"Toddler - FullDay\",\"Tuition\":\"\",\"Sex\":\"F\",\"PIN\":\"\",\"ImmunizationStatus\":\"Not Provided\",\"Classroom\":\"Classroom 03\"}]}";
    const params = new Object();
    // params.rcyear = this.state.selectedValueYear;
    // params.rcsemester = this.state.selectedValueSemester;
    values.sids = this.props.selectedIds;
    var d = new Date();
    var dt = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-'
      + ('0' + d.getDate()).slice(-2);
    values.today = dt;
    const bdy = JSON.stringify(values);
    //console.log(bdy)
    // const updatedRecord = { ...record, is_approved: true };
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        //console.log("response", response)
        if (response.ok) {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              this.props.showNotification("Toddler Reports generated");
              this.props.push('/ToddlerReports');

            } else {
              this.props.showNotification("Toddler Reports generated");
              this.props.push('/ToddlerReports');
            }
          });
        } else if (response.status === '401' ) {
          this.props.showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              this.props.showNotification("Unable to generate for " + json.message);

            } else {
              this.props.showNotification("Error generating Toddler Report(s)");
            }
          });
        }
      })
      .catch((e) => {
        //console.error(e);
        this.props.showNotification('Error: generating toddler reports', 'warning')
      });
  };

  render() {
    return <div><Tooltip title="Create Toddler Reports"><IconButton color="primary" component="span" onClick={this.handleClickOpen}>
      <AssignmentIcon style={{ color: "green" }} />
    </IconButton></Tooltip>
      <SimpleDialog /*selectedValueYear={this.state.selectedValueYear} setSelectedValueYear={this.updateYear} setSelectedValueSemester={this.updateSemester} selectedValueSemester={this.state.selectedValueSemester}*/ onCancel={this.handleClickCancel} open={this.state.open} onClose={this.handleClose} />

    </div>
  }

}

UpdateTRCommonData.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(UpdateTRCommonData);


