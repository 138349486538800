import React from 'react';

const ApplnURL = ({ record }) => {
    // console.log("applnurl ", record)
    if (record.AID != "manual") {
        var url = "#/Applications/1-"+record.UserID+":"+record.AID+"/show"
        return (
            <a href={encodeURI(url)}>Application Link</a>
        );
    } else {
                return (<div></div>);
            }
}

export default ApplnURL;