// in src/students.js
import React, { useState, Component } from 'react';
import { DateInput, TopToolbar, BooleanInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput, BooleanField } from 'react-admin';
import { RadioButtonGroupInput, Filter, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import MyTab from './mytab.js';
import TimeParse from './timeparse.js';
import { TimeInput } from './picker';
import ExcusedAbsence from './excusedAbsence';
import ExcusedAbsenceEdit from './excusedAbsenceEdit';



const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
const day = String(today.getDate()).padStart(2, '0');

// Format the date as "yyyy-mm-dd"
const todayDt = `${year}-${month}-${day}`;
const validateDate = [required(), minValue(todayDt)];


var d = new Date();
var dt = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-'
    + ('0' + d.getDate()).slice(-2);

const StudentAbsenceFilter = (props) => (
    <Filter {...props}>
        <TextInput alwaysOn label="Date" source="absdate" ></TextInput>
        <SelectInput alwaysOn label="Absence Type" source="Type" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'absence', name: 'Absence' },
            { id: 'early-pickup', name: 'Early Pickups' },
            { id: 'late-dropoff', name: 'Late DropOffs' },
        ]} />
        {/* <ReferenceInput allowEmpty={false} alwaysOn label="Student Name" source="id" reference="Students" filter={{ FEStatus: "Active" }} sort={{ field: 'Sname', order: 'ASC' }}>
            <SelectInput optionText="Sname" />
        </ReferenceInput> */}
    </Filter>
);


export const StudentAbsencesList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    // console.log("perms ", permissions);
    if (permissions !== undefined && (permissions.role === 'admin' || permissions.role === 'officemgr')) {
        return (
            <List  {...props} filters={<StudentAbsenceFilter />} filterDefaultValues={{ absdate: dt, Type: "All" }} bulkActionButtons={<UserListActions {...props} />} sort={{ field: 'Sname', order: 'ASC' }} >
                {(
                    <Datagrid >
                        <TextField label="Name" source="Name" />
                        <TextField label="Classroom" source="Classroom" />
                        <TextField label="From" source="FromDt" />
                        <TextField label="To" source="ToDt" />
                        <TextField label="Reason" source="Reason" />
                        <TextField label="Type" source="Type" />
                        <TimeParse label="Time" source="Time" />
                        <ExcusedAbsence label="Excused Absence"/>
                        <ExcusedAbsenceEdit />
                    </Datagrid>)}
            </List>
        );
    } else if (permissions !== undefined && (permissions.role === 'teacher' || permissions.role === 'toddlerteacher')) {
        return (
            <List  {...props} filters={<StudentAbsenceFilter />} filterDefaultValues={{ absdate: dt, Type: "All" }} sort={{ field: 'Sname', order: 'ASC' }} >
                {(
                    <Datagrid >
                        <TextField label="Name" source="Name" />
                        <TextField label="Classroom" source="Classroom" />
                        <TextField label="From" source="FromDt" />
                        <TextField label="To" source="ToDt" />
                        <TextField label="Reason" source="Reason" />
                        <TextField label="Type" source="Type" />
                        <TimeParse label="Time" source="Time" />
                        <ExcusedAbsence label="Excused Absence"/>
                    </Datagrid>)}
            </List>
        );
    } else {
        return (<span>User has No Access...</span>)
    }
};

export const StudentAbsencesEdit = (props) => {

    return (
        <Edit {...props}>
            <SimpleForm>
                <Box p="1em">
                    <Box display="flex">
                        <Box >
                            <Typography variant="h6" gutterBottom>Student Absence</Typography>
                            <Box>
                            <BooleanInput label="Excused Absence" source="Excused" />
                                </Box>
                            <Box>
                                <TextInput disabled label="Student Name" source="Name"  />
                                <Box display="flex">
                                    <TextInput disabled label="Absence Type" source="Type"  />
                                </Box>
                                <Box>
                                    <TextInput disabled label="Reason for Absence " source="Reason" />
                                </Box>
                                <Box>
                                    <TextInput disabled source="FromDt" id="FromDt" label="From"  /> <MyTab />
                                    <TextInput disabled source="FromDt" id="FromDt" label="To"  />
                                </Box>
                                <Box>
                                    <TextInput disabled source="Time" label="Time"  />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Edit >
    );
};



export const StudentAbsencesCreate = (props) => {
    const [visibleDates, setvisibleDates] = useState(true);
    const [visibleDate, setvisibleDate] = useState(false);
    const [visibleTime, setvisibleTime] = useState(false);

    const handleType = (event) => {
        // console.log("TYPE Change ", event.target.value)
        const type = event.target.value
        if (type == "early-pickup" || type == "late-dropoff") {
          setvisibleTime(()=> true);
          setvisibleDate(() => true);
          setvisibleDates(() => false);
        } else {
          setvisibleTime(()=> false);
          setvisibleDate(() => false);
          setvisibleDates(() => true);
        }
      };

    return (
        <Create {...props}>
            <SimpleForm>
                <Box p="1em">
                    <Box display="flex">
                        <Box >
                            <Typography variant="h6" gutterBottom>Student Absence</Typography>
                            <Box>
                                <ReferenceInput allowEmpty={false} alwaysOn label="Student Name" source="sid" reference="Students" filter={{ FEStatus: "Active" }} sort={{ field: 'Sname', order: 'ASC' }}>
                                    <SelectInput optionText="Sname" validate={required()} />
                                </ReferenceInput>
                                <Box display="flex">
                                    <SelectInput source="Type" validate={required()} choices={[
                                        { id: 'absence', name: 'Absence' },
                                        { id: 'early-pickup', name: ' Early Pickup' },
                                        { id: 'late-dropoff', name: 'Late DropOff' },
                                    ]} onChange={event => handleType(event)} />
                                </Box>
                                <Box>
                                    <TextInput label="Reason for Absence " source="Reason" />
                                </Box>
                                {visibleDate && (<Box>
                                    <DateInput source="fromDt" id="fromDt" label="Date"  validate={validateDate}/>
                                </Box>)}
                                {visibleDates && (<Box>
                                    <DateInput source="fromDt" id="fromDt" label="From" validate={validateDate} /> <MyTab />
                                    <DateInput source="toDt" id="toDt" label="To" validate={validateDate} />
                                </Box>)}
                                {visibleTime && (<Box>
                                    <TimeInput source="Time" label="Time" isRequired={true} options={{ clearable: true }} />
                                </Box>)}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Create >
    );
};






const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const UserListActions = (props) => {
    // console.log("In student buttons ", props);
    // let st = props.filterValues.EStatus;
    // let cl = props.cl;
    // console.log("Classroom filter ", cl, st);
    return (
        <TopToolbar>
            <BulkDeleteButton {...props} undoable="true" />
        </TopToolbar>
    );
}
