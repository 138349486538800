import React from 'react';
import { EditButton } from 'react-admin';


const ExcusedAbsenceEdit = ({ record }) => {
    if (record != undefined) {
        if (record.Type === "late-dropoff") {
            return (
                <EditButton record={record} />
            );
        } else {
            return ("");
        }
        
    } else {
        return ("");
    }
}

export default ExcusedAbsenceEdit;
