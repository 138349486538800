// in src/students.js
import React from 'react';
import { useEffect, useState } from 'react';
import { useRecordContext,Labeled, DateField, Tab, TabbedShowLayout, SimpleList, FormDataConsumer, SaveButton, Toolbar, useListContext, DateInput, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput, isRequired } from 'react-admin';
import { Box, Typography, CardActions } from '@material-ui/core';
import FlatButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { Show, RadioButtonGroupInput, Filter, useRefresh, useRedirect, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';
import MyTab from './mytab.js';
import { CheckboxGroupInput, FormTab, TabbedForm } from 'react-admin';
import SendApplicationEmailButton from './SendApplicationEmailButton.js';
import Signature from './sign.js';
import DisplaySignature from './dispsignature.js';
import LangSelection from './LangSelection.js';
import Behavior from './Behavior.js';
import './index.css';
import ActionsRefresh from './NoAction.js';
import TourURL from './tourUrl.js';
import {validateEnrollmentDate} from './utils.js';


const validateName = [required(), minLength(2), maxLength(45)];
const validateEmail = [required(), email()];
const validateDate = [required(), regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDateOpt = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateSex = [required()];
const validateState = [required(), regex(/^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/, "Must be valid US State")];
const validateStateOpt = [regex(/^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/, "Must be valid US State")];
const validateZip = [required(), regex(/^[0-9]{5}(?:-[0-9]{4})?$/, "Must be valid zipcode")];
const validateZipOpt = [regex(/^[0-9]{5}(?:-[0-9]{4})?$/, "Must be valid zipcode")];
const validateDob = [required(),checkDob]
const validateParentData = (values) => {
    return (values) => {
        if (values != undefined && values.length == 2) {
            return undefined;
        }
        return "Please submit both Parents Information";
    };
}
function checkDob(value) {
    // console.log("Dt ",value);
    var today = new Date();
    var dt = new Date(value);
    const yearDiff = today.getFullYear() - dt.getFullYear();

    // Get the number of months in the first year.
    const firstYearMonths = dt.getMonth() + 1;

    // Get the number of months in the second year.
    const secondYearMonths = today.getMonth();

    // Calculate the total number of months between the two dates.
    const diffInMonths = yearDiff * 12 - firstYearMonths + secondYearMonths;
    // console.log("Duiff mnths",diffInMonths,yearDiff,firstYearMonths,secondYearMonths);

    if (diffInMonths < 9) {
        return "Age of child should be 9 months for applying and 18+ months for admission";
    }
}




const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-]+/g, '');
    let x;
    if (newNum.length <= 3) {
        x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6)
    } else {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6) + "-" + newNum.slice(6, 10)
    }
    return x;
};
const validatePhone = [required(), regex(/[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/), "Valid Phone number is required"];
const validatePhoneOpt = [regex(/[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/), "Valid Phone number is required"];

const Empty = () => {
    const { basePath, resource } = useListContext();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h6" paragraph>
                Welcome to LAMS Application submission
            </Typography>
            <Typography variant="body1">
                Please click <CreateButton basePath={basePath} /> to Submit New Application
            </Typography>
        </Box>
    );
};

const ApplicationFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Status" source="Status" allowEmpty={false} choices={[
            // { id: 'All', name: 'All' }, //too many records same as table scan
            { id: 'Submitted', name: 'Submitted' },
            { id: 'WaitList', name: 'WaitList' },
            { id: 'Approved', name: 'Approved' },
            { id: 'Enrolled', name: 'Enrolled' },
            { id: 'Rejected', name: 'Rejected' },
            { id: 'Withdrawn', name: 'Withdrawn' },
            { id: 'NeedMoreInfo', name: 'NeedMoreInfo' },
            { id: 'Alumni', name: 'Alumni' },
        ]} />
        <SelectInput alwaysOn label="Program Name" source="PrgName" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Toddler', name: 'Toddler' },
            { id: 'Primary', name: 'Primary' },
        ]} />
    </Filter>
);


var primaryPrg = false;
const handlePrgSel = event => {
    // console.log("IN HERE for Potty trg", event);
    if (event === "Primary") {
        primaryPrg = true;
    } else {
        primaryPrg = false;
    }
}
var stsApproved = false;
const handleStsSel = event => {
        // console.log("Appln Sts changed ", event.target.value);
        var val = event.target.value
    if (val === "Approved") {
        stsApproved = true;
    } else {
        stsApproved = false;
    }
}


export const LamsApplicationList = ({ permissions, ...props }) => {
    // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isSmall = false;
    if (permissions !== undefined && permissions.role === 'admin') {
        return (
            <List empty={< Empty />}  {...props} filters={<ApplicationFilter />} filterDefaultValues={{ Status: "Submitted", PrgName: "All" }} bulkActionButtons={<LamsApplicationListActions {...props} />} sort={{ field: "none", order: 'ASC' }}>
                <Datagrid className="txtCopy" >
                    <TextField label="Child Name" source="Child.Name" />
                    <TextField label="Child’s Nick Name (if any)" source="Child.Nick" />
                    <ChildAge label="Age" />
                    <TextField label="Desired Enrollment Date" source="Child.DesiredEnrollmentDate" />
                    <TextField label="Tour Precondition" source="TourPrecondition" />
                    <TextField label="Phone" source="Child.Phone" />
                    <TextField label="Program Name" source="Prg.Name" />
                    <TextField label="Program Type" source="Prg.Type" />
                    <TextField label="Status" source="Status" />
                    <TextField label="Date Submitted" source="DtCreated" />
                    <TextField label="Date Approved" source="DtApproved" />
                    <TourURL />
                    <ShowButton />
                    <EditButton />
                </Datagrid>
            </List>
        );
    } else if (permissions !== undefined && (permissions.role === 'officemgr')) {
        return (
            <List empty={< Empty />}  {...props} filters={<ApplicationFilter />} filterDefaultValues={{ Status: "Submitted", PrgName: "All" }} bulkActionButtons={<OfficeMgrListActions {...props} />} sort={{ field: "none", order: 'ASC' }}>
                <Datagrid className="txtCopy" >
                    <TextField label="Child Name" source="Child.Name" />
                    <ChildAge label="Age" />
                    <TextField label="Desired Enrollment Date" source="Child.DesiredEnrollmentDate" />
                    <TextField label="Tour Precondition" source="TourPrecondition" />
                    <TextField label="Phone" source="Child.Phone" />
                    <TextField label="Program Name" source="Prg.Name" />
                    <TextField label="Program Type" source="Prg.Type" />
                    <TextField label="Status" source="Status" />
                    <TextField label="Date Submitted" source="DtCreated" />
                    <TextField label="Date Approved" source="DtApproved" />
                    <TourURL />
                    <ShowButton />
                </Datagrid>
            </List>
        ); 
    } else {
        return (
            <span>Not an Admin, Please login as Admin to see the applications</span>
        )
    }
};

const ChildAge = ({ record }) => {
    // var from = record.Child.Dob.split("-");
    // var oneDay = 24 * 60 * 60 * 1000;
    // var bdate = new Date(from[0], from[1] - 1, from[2]);
    // var diff = Date.now() - bdate.getTime();
    // // var age = new Date(diff); 
    // var age = (diff/oneDay)/365
    // // return <span>{(age.getUTCFullYear() - 1970)}</span>
    // return <span>{age.toFixed(1)}</span>
    var birthday = record.Child.Dob.split("-");
    var dobMonth = birthday[1];
    var dobDay = birthday[2];
    var dobYear = birthday[0];
    var now = new Date();
    var nowDay = now.getDate();
    var nowMonth = now.getMonth() + 1;  //jan = 0 so month + 1
    var nowYear = now.getFullYear();

    var ageyear = nowYear - dobYear;
    var agemonth = nowMonth - dobMonth;
    var ageday = nowDay - dobDay;
    // console.log(ageyear, agemonth, ageday)
    if (agemonth < 0) {
        ageyear--;
        agemonth = (12 + agemonth);
    }
    if (nowDay < dobDay) {
        agemonth--;
        ageday = 30 + ageday;
    }
    var val = ageyear + "yrs, " + agemonth + "mnth(s)";
    return <span>{val}</span>
};


const LamsApplicationTitle = ({ record }) => {
    return <span>LamsApplication {record ? `"${record.id}"` : ''}</span>;
};
export const LamsApplicationEdit = (props) => {
    useEffect(() => {
        var radios = document.getElementsByName('Prg.Name');
        // console.log("In useeffect", radios);
        for (var i = 0, length = radios.length; i < length; i++) {
            if (radios[i].checked) {
                // do whatever you want with the checked radio
                // console.log("Radio Value", radios[i].value);
                if (radios[i].value === "Primary") {
                    primaryPrg = true;
                } else {
                    primaryPrg = false;
                }
                // only one radio can be logically checked, don't check the rest
                break;
            }
        }

    }, []);
    return (
    <Edit title={<LamsApplicationTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Child's Information" >
                <Box style={{ width: "100%" }} >
                    <Box display="flex">
                        <Box>
                            <TextInput label="Child's Name" source="Child.Name" validate={required()} />
                        </Box>
                        <Box ml="2em">
                            <TextInput label="Child’s Nick Name (if any)" source="Child.Nick" />
                        </Box>
                            <Box ml="2em">
                                <SelectInput label="Status" source="Status" choices={[
                                    { id: 'Submitted', name: 'Submitted' },
                                    { id: 'Approved', name: 'Approved' },
                                    { id: 'Rejected', name: 'Rejected' },
                                    { id: 'Withdrawn', name: 'Withdrawn' },
                                    { id: 'Enrolled', name: 'Enrolled' },
                                    { id: 'WaitList', name: 'WaitList' },
                                    { id: 'NeedMoreInfo', name: 'NeedMoreInfo' },
                                    { id: 'Alumni', name: 'Alumni' },
                                ]} onChange={handleStsSel} />
                            </Box>
                            <Box ml="2em">
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => stsApproved && primaryPrg &&
                                        <SelectInput label="Assigned Classroom" source="Child.AssignedClassroom" choices={[
                                            { id: 'Classroom 01', name: 'Classroom 01' },
                                            { id: 'Classroom 02', name: 'Classroom 02' },
                                        ]} {...rest} validate={required()} />
                                    }
                                </FormDataConsumer>
                            </Box>
                            <Box ml="2em">
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => stsApproved && !primaryPrg &&
                                        <SelectInput label="Assigned Classroom" source="Child.AssignedClassroom" choices={[
                                            { id: 'Classroom 03', name: 'Classroom 03' },
                                            { id: 'Classroom 04', name: 'Classroom 04' },
                                        ]} {...rest} validate={required()} />
                                    }
                                </FormDataConsumer>
                            </Box>
                            <Box ml="2em">
                                <DateInput label="Approved enrollment Date" source="Child.ApprovedEnrollmentDate" />
                                <span>Leave this blank if requested date is OK</span>
                            </Box>
                    </Box>
                    <Box display="flex">
                        <Box>
                            <DateInput label="Child’s Date of Birth" source="Child.Dob" validate={validateDob} />
                        </Box>
                        <Box ml="2em">
                            <TextInput label="Child’s Place of Birth" source="Child.Pob" validate={required()} />
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box >
                            <SelectInput label="Child’s Gender" source="Child.Sex" validate={validateSex} choices={[
                                { id: 'M', name: 'M' },
                                { id: 'F', name: 'F' },
                                { id: 'O', name: 'Other' }
                            ]} />
                        </Box>
                        <Box ml="2em" >
                            <TextInput
                                parse={phoneNumberFormat}
                                label="Child's Contact Phone"
                                source="Child.Phone"
                                validate={validatePhone}
                            />
                        </Box>
                    </Box>
                    <Box >
                        <Box>
                            <Box>
                                <b>Child’s Home Address </b>
                            </Box>
                            <Box display="flex">
                                <TextInput style={{ width: 300 }} label="Street" source="Child.HomeAddr.Street" validate={required()} /><MyTab />
                                <TextInput label="Apt #" source="Child.HomeAddr.AptorSuite" /><MyTab />
                                <TextInput label="City" source="Child.HomeAddr.City" validate={required()} /><MyTab />
                                <TextInput label="State" source="Child.HomeAddr.State" validate={validateState} /><MyTab />
                                <TextInput label="Zipcode" source="Child.HomeAddr.Zip" validate={validateZip} />
                            </Box>
                        </Box>
                        <Box >
                            <Box>
                                <b>Child’s Mailing Address, if different from above </b>
                            </Box>
                            <Box display="flex">
                                <TextInput style={{ width: 300 }} label="Street" source="Child.MailingAddr.Street" /><MyTab />
                                <TextInput label="Apt #" source="Child.MailingAddr.AptorSuite" /><MyTab />
                                <TextInput label="City" source="Child.MailingAddr.City" /><MyTab />
                                <TextInput label="State" source="Child.MailingAddr.State" validate={validateStateOpt} /><MyTab />
                                <TextInput label="Zipcode" source="Child.MailingAddr.Zip" validate={validateZipOpt} />
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <b>Child's Siblings? </b>
                            </Box>
                            <Box display="flex">
                                <Box ml="5em" mr="12em">
                                    <b>First and Last Name </b>
                                </Box>
                                <Box mr="6em">
                                    <b>Age </b>
                                </Box>
                                <Box mr="4em">
                                    <b>Gender </b>
                                </Box>
                                <Box mr="4em">
                                    <b>Is Currently Enrolled at LAMS? </b>
                                </Box>
                            </Box>
                            <ArrayInput label="" source="Siblings">
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                            { id: 'M', name: 'M' },
                                                            { id: 'F', name: 'F' },
                                                        ]} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <RadioButtonGroupInput style={{ width: 200 }} label="Enrolled At LAMS" source={getSource('Enrolled')} choices={[
                                                            { id: 'Y', name: 'Y' },
                                                            { id: 'N', name: 'N' },
                                                        ]} validate={required()} />
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                        <Box>
                            <Box>
                                <b>Whom does the child live with and the household members (parents, siblings, relatives, etc.)? </b>
                            </Box>
                            <Box display="flex">
                                <Box ml="5em" mr="12em">
                                    <b>First and Last Name </b>
                                </Box>
                                <Box mr="7em">
                                    <b>Realtionship to Child</b>
                                </Box>
                                <Box mr="6em">
                                    <b>Age </b>
                                </Box>
                                <Box mr="4em">
                                    <b>Gender </b>
                                </Box>
                            </Box>
                            <ArrayInput label="" source="HHmembers" validate={required()}>
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 200 }} label="Relationship #" source={getSource('Relation')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                            { id: 'M', name: 'M' },
                                                            { id: 'F', name: 'F' },
                                                        ]} validate={required()} />
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                    <Box >
                        <Box >
                            <b>What languages does the child speak at home? </b>
                        </Box>
                        <Box display="flex">
                            <CheckboxGroupInput style={{ width: 300 }} label="" source="Child.Langs.Language" choices={[
                                { id: 'English', name: 'English' },
                                { id: 'Spanish', name: 'Spanish' },
                                { id: 'Other', name: 'Other' }
                            ]} validate={required()} />
                            <TextInput label="Specify Other Language" source="Child.Langs.Otherlang" />
                        </Box>
                    </Box>
                    <Box >
                        <Box >
                            <b><u>Child’s Doctor:</u></b>
                        </Box>
                        <Box>
                            <Box>
                                <TextInput label="Doctor’s Name" source="Child.Dr.Name" validate={required()} />
                            </Box>
                            <Box>
                                <TextInput label="Name of Practice" source="Child.Dr.Practice" validate={required()} />
                            </Box>
                            <Box >
                                <b><u>Address:</u></b>
                            </Box>
                            <Box display="flex">
                                <TextInput style={{ width: 300 }} label="Street" source="Child.Dr.Addr.Street" validate={required()} /><MyTab />
                                <TextInput label="Suite #" source="Child.Dr.Addr.AptorSuite" /><MyTab />
                                <TextInput label="City" source="Child.Dr.Addr.City" validate={required()} /><MyTab />
                                <TextInput label="State" source="Child.Dr.Addr.State" validate={validateState} /><MyTab />
                                <TextInput label="Zipcode" source="Child.Dr.Addr.Zip" validate={validateZip} />
                            </Box>
                            <Box>
                                <TextInput
                                    parse={phoneNumberFormat}
                                    label="Phone"
                                    source="Child.Dr.Phone"
                                    validate={validatePhone}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box >
                        <span><b><u>Describe below any medical conditions the child has (including food or medication allergies):</u></b></span>
                        <Box>
                            <TextInput style={{ width: 500 }} multiline label="Medical Conditions" source="Child.MedConds" validate={required()} />
                        </Box>
                    </Box>
                </Box>
            </FormTab>
            <FormTab label="Parent/Guardian Information">
                <Box style={{ width: "100%" }} >
                    <Box >
                        <span><b>Parent / Guardian:</b></span>
                    </Box>
                    <ArrayInput label="" source="Parents" validate={[required(), validateParentData()]}>
                        <SimpleFormIterator>
                            <FormDataConsumer>
                                {({ getSource, scopedFormData }) => {
                                    return (
                                        <Box style={{ width: "100%" }}>
                                            <Box display="flex">
                                                <Box mr="4em">
                                                    <TextInput style={{ width: 300 }} label="Name" source={getSource('Name')} validate={required()} />
                                                </Box>
                                                <Box mr="4em">
                                                    <TextInput style={{ width: 150 }} label="Relation to Child" source={getSource('Relation')} validate={required()} />
                                                </Box>
                                                <Box mr="4em">
                                                    <TextInput style={{ width: 150 }}
                                                        parse={phoneNumberFormat}
                                                        label="Contact Phone"
                                                        source={getSource('Phone')}
                                                        validate={validatePhone}
                                                    />
                                                </Box>
                                                <Box >
                                                    <TextInput style={{ width: 300 }} label="Email" source={getSource('Email')} validate={validateEmail} />
                                                </Box>
                                            </Box>
                                            <Box >
                                                <Box >
                                                    <span><b>Address if different from Child's home address:</b></span>
                                                </Box>
                                                <Box display="flex" >
                                                    <TextInput style={{ width: 300 }} label="Street" source={getSource('Addr.Street')} /><MyTab />
                                                    <TextInput style={{ width: 150 }} label="Apt #" source={getSource('Addr.AptorSuite')} /><MyTab />
                                                    <TextInput style={{ width: 150 }} label="City" source={getSource('Addr.City')} /><MyTab />
                                                    <TextInput style={{ width: 150 }} label="State" source={getSource('Addr.State')} validate={validateStateOpt} /><MyTab />
                                                    <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Addr.Zip')} validate={validateZipOpt} />
                                                </Box>
                                            </Box>
                                            <Box >
                                                <span><b>Work Info:</b></span>
                                            </Box>
                                            <Box >
                                                <TextInput label="Job Title" source={getSource('Work.JobTitle')} validate={required()} /><MyTab />
                                                <TextInput label="Job Description" source={getSource('Work.JobDescription')} validate={required()} /><MyTab />
                                                <TextInput label="Company Name" source={getSource('Work.Company.Name')} validate={required()} />
                                                <Box >
                                                    <span><b>Company Address:</b></span>
                                                </Box>
                                                <Box display="flex">
                                                    <TextInput style={{ width: 300 }} label="Street" source={getSource('Work.Company.Addr.Street')} validate={required()} /><MyTab />
                                                    <TextInput style={{ width: 150 }} label="Suite #" source={getSource('Work.Company.Addr.AptorSuite')} /><MyTab />
                                                    <TextInput style={{ width: 150 }} label="City" source={getSource('Work.Company.Addr.City')} validate={required()} /><MyTab />
                                                    <TextInput style={{ width: 150 }} label="State" source={getSource('Work.Company.Addr.State')} validate={validateState} /><MyTab />
                                                    <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Work.Company.Addr.Zip')} validate={validateZip} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </Box>
            </FormTab>
            <FormTab label="Personal Information">
                <Box style={{ width: "100%" }} >
                    <Box >
                        <span><b>Child’s interests and favorite activities:</b></span><br></br>
                        <TextInput style={{ width: 500 }} multiline label="Child's Activities" source="Child.Activities" validate={required()} />
                    </Box>
                    <Box >
                        <span><b>Characteristic Behavior (check all that apply):</b></span>
                        <CheckboxGroupInput label="" source="Child.Behavior" choices={[
                            { id: 'shy', name: 'Shy' },
                            { id: 'calm', name: 'Calm' },
                            { id: 'cheerful', name: 'Cheerful' },
                            { id: 'aggressive', name: 'Aggressive' },
                            { id: 'whining', name: 'Whining' },
                            { id: 'friendly', name: 'Friendly' },
                            { id: 'excitable', name: 'Excitable' },
                            { id: 'fearful', name: 'Fearful' },
                            { id: 'cooperative', name: 'Cooperative' }
                        ]} validate={required()} />
                    </Box>
                    <Box >
                        <span><b>Any fears? (History and manifestations):</b></span><br></br>
                        <TextInput style={{ width: 500 }} multiline label="" source="Child.Fears" />
                    </Box>
                    <Box >
                        <span><b>Types of home discipline most frequently used:</b></span><br></br>
                        <TextInput style={{ width: 500 }} multiline label="" source="Child.Discipline" validate={required()} />
                    </Box>
                    <Box>
                        <Box >
                            <span><b>Emergency Contact</b></span>
                        </Box>
                        <Box>
                            <b>In case of Emergency, if neither parent can be reached, who can we contact? </b>
                        </Box>
                        <Box display="flex">
                            <Box mr="14em">
                                <b>First and Last Name </b>
                            </Box>
                            <Box mr="8em">
                                <b>Relationship to Child</b>
                            </Box>
                            <Box mr="6em">
                                <b>Contact Phone# </b>
                            </Box>
                        </Box>
                        <ArrayInput label="" source="EmergContacts" validate={required()}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ getSource, scopedFormData }) => {
                                        return (
                                            <Box display="flex">
                                                <Box mr="4em">
                                                    <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                </Box>
                                                <Box mr="4em">
                                                    <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} validate={required()} />
                                                </Box>
                                                <Box mr="4em">
                                                    <TextInput
                                                        style={{ width: 150 }} parse={phoneNumberFormat}
                                                        label="Phone"
                                                        source={getSource('Phone')}
                                                        validate={validatePhone}
                                                    />
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                    <Box>
                        <Box >
                            <span><b>Authorization to Drop-Off / Pick-Up Child</b></span>
                        </Box>
                        <Box>
                            <b>Who will be the main adult to drop-off/pick-up the child? </b>
                        </Box>
                        <Box display="flex">
                            <Box mr="14em">
                                <b>First and Last Name </b>
                            </Box>
                            <Box mr="8em">
                                <b>Relationship to Child</b>
                            </Box>
                            <Box mr="6em">
                                <b>Contact Phone# </b>
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box mr="4em">
                                <TextInput style={{ width: 300 }} label="First and Last Name" source="MainAuth.Name" validate={required()} />
                            </Box>
                            <Box mr="4em">
                                <TextInput style={{ width: 150 }} label="Relationship" source="MainAuth.Relation" validate={required()} />
                            </Box>
                            <Box mr="4em">
                                <TextInput
                                    style={{ width: 150 }} parse={phoneNumberFormat}
                                    label="Phone"
                                    source="MainAuth.Phone"
                                    validate={validatePhone}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <b>Who are the other adults that have authorization to drop-off/pick-up the child? </b>
                        </Box>
                        <ArrayInput label="" source="Oauths" isRequired={false} >
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ getSource, scopedFormData }) => {
                                        return (
                                            <Box display="flex">
                                                <Box mr="4em">
                                                    <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} />
                                                </Box>
                                                <Box mr="4em">
                                                    <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} />
                                                </Box>
                                                <Box mr="4em">
                                                    <TextInput
                                                        style={{ width: 150 }} parse={phoneNumberFormat}
                                                        label="Phone"
                                                        source={getSource('Phone')}
                                                        validate={validatePhoneOpt}
                                                    />
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </Box>
            </FormTab>
            <FormTab label="Enrollment Program Information">
                <Box style={{ width: "100%" }}>
                    <Box >
                        <span><b>How did you hear about LAMS?</b></span>
                        <CheckboxGroupInput label="" source="Mkting.Website" choices={[
                            { id: 'Adwebsite', name: 'Website' }
                        ]} />
                        <Box display="flex">
                            <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.SE" choices={[
                                { id: 'Adsearcheng', name: 'Online Search Engine (i.e., Google)' }
                            ]} />
                            <TextInput label="Specify Search Engine" source="Mkting.SEName" />
                        </Box>
                        <Box display="flex">
                            <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Referred" choices={[
                                { id: 'Adrefer', name: 'Referred By a Parent/Student' }
                            ]} />
                            <TextInput label="First and Last Name" source="Mkting.ReferingPerson.Name" /><MyTab />
                            <TextInput label="Relation to Child" source="Mkting.ReferingPerson.Relation" />
                        </Box>
                        <CheckboxGroupInput label="" source="Mkting.FriendorRel" choices={[
                            { id: 'Adfndrel', name: 'From a Friend/Relative' }
                        ]} />
                        <Box display="flex">
                            <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Other" choices={[
                                { id: 'Adother', name: 'Other' }
                            ]} />
                            <TextInput label="Specify" source="Mkting.OtherDtls" />
                        </Box>
                    </Box>
                    <Box >
                        <span><b>LAMS strongly encourages a three-year program (including kindergarten) in order to provide the maximum advantage of the Montessori method. A two-year program is the minimum recommended enrollment. How long do you intend to enroll your child at LAMS?</b></span>
                        <Box display="flex">
                            <RadioButtonGroupInput style={{ width: "300px" }} label="" source="Intent.Duration" choices={[
                                { id: '2', name: '2 Years' },
                                { id: '3', name: '3 Years' },
                                { id: 'other', name: 'Other' }
                            ]} validate={required()} />
                            <TextInput label="Specify" source="Intent.Other" />
                        </Box>
                        <Box>
                            <span><b>When is the desired enrollment (start) date?</b></span><br></br>
                            <DateInput label="Start date" source="Child.DesiredEnrollmentDate" validate={required()} />
                        </Box>
                    </Box>
                    <Box >
                        <span><b>Program Selection: </b></span><br></br>
                        <RadioButtonGroupInput label="" source="Prg.Name" choices={[
                                { id: 'Toddler', name: 'Toddler Program' },
                                { id: 'Primary', name: 'Primary Program' }

                            ]} onChange={handlePrgSel} validate={required()} />
                            <FormDataConsumer>
                                {({ formData, ...rest }) => primaryPrg &&
                                    <SelectInput style={{ width: "250px" }} label="Child is Fully Potty Trained" source="Child.PottyTrained" choices={[
                                        { id: 'N', name: 'N' },
                                        { id: 'Y', name: 'Y' },
                                        // { id: 'T', name: 'InTraining' }
                                    ]} {...rest} validate={required()} />
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                    {({ formData, ...rest }) => primaryPrg &&
                                        <span>
                                        <b>Child attending LAMS for primary program is required to be fully potty trained. For details of what fully potty trained means please refer <a target="_blank" href="https://lamsaz.com/formsAndCalendar/index.html#potty_trg_req">here</a>.</b>
                                        </span>
                                    }
                            </FormDataConsumer>
                        <br></br>
                        <RadioButtonGroupInput label="" source="Prg.Type" choices={[
                            { id: 'HalfDay', name: 'Half Day 8:05 AM - 12:30' },
                            { id: 'FullDay', name: 'Full Day 8:05 AM - 3:00 PM' },
                            { id: 'ExtendedDay', name: 'Extended Day 7:30 AM - 5:00 PM' }
                        ]} validate={required()} />
                    </Box>
                    <Box>
                        <span><b>Approval Acceptance: </b></span><br></br>
                        <span>If and when approved, is a tour of the school a precondition for your acceptance of the enrollment?</span><br></br>
                        <SelectInput style={{ width: "250px" }} label="Approval Acceptance" source="TourPrecondition" choices={[
                                            { id: 'Y', name: 'Y' },
                                            { id: 'N', name: 'N' },
                                        ]} validate={required()}/>
                    </Box>
                    <Box >
                        <span><b>Does your child have previous school experience?</b></span>
                        <Box display="flex">
                            <TextInput label="Name of school" source="Child.PrvSchool.Name" /><MyTab />
                            <TextInput label="Address" source="Child.PrvSchool.Addr" /><MyTab />
                            <DateInput label="Attendance From date" source="Child.PrvSchool.FrmDt" /><MyTab />
                            <DateInput label="Attendance To date" source="Child.PrvSchool.ToDt" />
                        </Box>
                    </Box>
                    <Box >
                        <span><b><u>What do you hope a Montessori education will do for your child:</u></b></span><br></br>
                        <TextInput style={{ width: "500px" }} multiline label="" source="HopeFrmMontessoriEdu" validate={required()} />
                    </Box>
                    <Box >
                        <span><b><u>Describe briefly the background of your interest in our Montessori program:</u></b></span><br></br>
                        <TextInput style={{ width: "500px" }} multiline label="" source="BackgrdOfMontessori" validate={required()} />
                    </Box>
                </Box>
            </FormTab>
            <FormTab label="General Release Form">
                <Box style={{ width: "100%" }}>
                    <Box >
                        <ol><li>LAMS may use photographs, reproductions, video recordings, and/or sound recordings of my child(ren). Such use may include advertising and publicity purposes.</li>
                            <RadioButtonGroupInput label="" source="GenRelease.UseofMedia" choices={[
                                { id: 'Y', name: 'Yes' },
                                { id: 'N', name: 'No' }
                            ]} validate={required()} />
                            <li>I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li>
                            <li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li>
                            <li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li>
                            <li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li>
                            <li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li>
                            <li>Children attending LAMS(for primary program) are required to be fully potty trained.</li>
                            <li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li>
                            <li>We understand balances due must be paid before evaluations can be released.</li>
                            <li>Fee once paid is not refundable under any circumstances.</li>
                            <li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li>
                        </ol>
                    </Box>
                    <Box>
                        <span><b>Parent’s/Guardian’s Approval</b></span>
                        <Box>
                            <span><b>Parent/Guardian #1</b></span><br></br>
                            <TextInput label="Name" source="GenRelease.PApp1.Name" validate={required()} /><MyTab />
                            <TextInput label="Relationship" source="GenRelease.PApp1.Relation" validate={required()} /><MyTab />
                            <DateInput label="Date" source="GenRelease.PApp1.Date" validate={required()} />
                            <Box style={{ width: "100%" }}>
                                <span>Signature:</span>
                                <DisplaySignature disabled label="Signature" source="GenRelease.PApp1.Signature" />
                            </Box>
                        </Box>
                        <Box>
                            <span><b>Parent/Guardian #2</b></span><br></br>
                            <TextInput label="Name" source="GenRelease.PApp2.Name" validate={required()} /><MyTab />
                            <TextInput label="Relationship" source="GenRelease.PApp2.Relation" validate={required()} /><MyTab />
                            <DateInput label="Date" source="GenRelease.PApp2.Date" validate={required()} />
                            <Box style={{ width: "100%" }}>
                                <span>Signature:</span>
                                <DisplaySignature disabled label="Signature" source="GenRelease.PApp2.Signature" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </FormTab>
        </TabbedForm >
    </Edit>
);
                        }
export const LamsApplicationCreate = (props) => {
    var today = new Date();
    return (
        <Create {...props}>
            <TabbedForm>
                <FormTab label="Child's Information" >
                    <Box style={{ width: "100%" }} >
                        <Box display="flex">
                            <Box>
                                <TextInput label="Child's Name" source="Child.Name" validate={required()} />
                            </Box>
                            <Box ml="2em">
                                <TextInput label="Child’s Nick Name (if any)" source="Child.Nick" />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box>
                                <DateInput label="Child’s Date of Birth" source="Child.Dob" validate={required()} />
                            </Box>
                            <Box ml="2em">
                                <TextInput label="Child’s Place of Birth" source="Child.Pob" validate={required()} />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box >
                                <SelectInput label="Child’s Gender" source="Child.Sex" validate={validateSex} choices={[
                                    { id: 'M', name: 'M' },
                                    { id: 'F', name: 'F' },
                                    { id: 'O', name: 'Other' }
                                ]} />
                            </Box>
                            <Box ml="2em" >
                                <TextInput
                                    parse={phoneNumberFormat}
                                    label="Child's Contact Phone"
                                    source="Child.Phone"
                                    validate={validatePhone}
                                />
                            </Box>
                        </Box>
                        <Box >
                            <Box>
                                <Box>
                                    <b>Child’s Home Address </b>
                                </Box>
                                <Box display="flex">
                                    <TextInput style={{ width: 300 }} label="Street" source="Child.HomeAddr.Street" validate={required()} /><MyTab />
                                    <TextInput label="Apt #" source="Child.HomeAddr.AptorSuite" /><MyTab />
                                    <TextInput label="City" source="Child.HomeAddr.City" validate={required()} /><MyTab />
                                    <TextInput label="State" source="Child.HomeAddr.State" validate={validateState} /><MyTab />
                                    <TextInput label="Zipcode" source="Child.HomeAddr.Zip" validate={validateZip} />
                                </Box>
                            </Box>
                            <Box >
                                <Box>
                                    <b>Child’s Mailing Address, if different from above </b>
                                </Box>
                                <Box display="flex">
                                    <TextInput style={{ width: 300 }} label="Street" source="Child.MailingAddr.Street" /><MyTab />
                                    <TextInput label="Apt #" source="Child.MailingAddr.AptorSuite" /><MyTab />
                                    <TextInput label="City" source="Child.MailingAddr.City" /><MyTab />
                                    <TextInput label="State" source="Child.MailingAddr.State" validate={validateStateOpt} /><MyTab />
                                    <TextInput label="Zipcode" source="Child.MailingAddr.Zip" validate={validateZipOpt} />
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <b>Child's Siblings? </b>
                                </Box>
                                <Box display="flex">
                                    <Box ml="5em" mr="12em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Age </b>
                                    </Box>
                                    <Box mr="4em">
                                        <b>Gender </b>
                                    </Box>
                                    <Box mr="4em">
                                        <b>Is Currently Enrolled at LAMS? </b>
                                    </Box>
                                </Box>
                                <ArrayInput label="" source="Siblings" >
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                { id: 'M', name: 'M' },
                                                                { id: 'F', name: 'F' },
                                                            ]} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <RadioButtonGroupInput style={{ width: 200 }} label="Enrolled At LAMS" source={getSource('Enrolled')} choices={[
                                                                { id: 'Y', name: 'Y' },
                                                                { id: 'N', name: 'N' },
                                                            ]} validate={required()} />
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                            <Box>
                                <Box>
                                    <b>Whom does the child live with and the household members (parents, relatives, etc.)? </b>
                                </Box>
                                <Box display="flex">
                                    <Box ml="5em" mr="12em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="7em">
                                        <b>Relationship to Child</b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Age </b>
                                    </Box>
                                    <Box mr="4em">
                                        <b>Gender </b>
                                    </Box>
                                </Box>
                                <ArrayInput label="" source="HHmembers" validate={required()}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 200 }} label="Relationship #" source={getSource('Relation')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                { id: 'M', name: 'M' },
                                                                { id: 'F', name: 'F' },
                                                            ]} validate={required()} />
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>
                        <Box >
                            <Box >
                                <b>What languages does the child speak at home? </b>
                            </Box>
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: 300 }} label="" source="Child.Langs.Language" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                    { id: 'Other', name: 'Other' }
                                ]} validate={required()} />
                                <TextInput label="Specify Other Language" source="Child.Langs.Otherlang" />
                            </Box>
                        </Box>
                        <Box >
                            <Box >
                                <b><u>Child’s Doctor:</u></b>
                            </Box>
                            <Box>
                                <Box>
                                    <TextInput label="Doctor’s Name" source="Child.Dr.Name" validate={required()} />
                                </Box>
                                <Box>
                                    <TextInput label="Name of Practice" source="Child.Dr.Practice" validate={required()} />
                                </Box>
                                <Box >
                                    <b><u>Address:</u></b>
                                </Box>
                                <Box display="flex">
                                    <TextInput style={{ width: 300 }} label="Street" source="Child.Dr.Addr.Street" validate={required()} /><MyTab />
                                    <TextInput label="Suite #" source="Child.Dr.Addr.AptorSuite" /><MyTab />
                                    <TextInput label="City" source="Child.Dr.Addr.City" validate={required()} /><MyTab />
                                    <TextInput label="State" source="Child.Dr.Addr.State" validate={validateState} /><MyTab />
                                    <TextInput label="Zipcode" source="Child.Dr.Addr.Zip" validate={validateZip} />
                                </Box>
                                <Box>
                                    <TextInput
                                        parse={phoneNumberFormat}
                                        label="Phone"
                                        source="Child.Dr.Phone"
                                        validate={validatePhone}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box >
                            <span><b><u>Describe below any medical conditions the child has (including food or medication allergies):</u></b></span>
                            <Box>
                                <TextInput style={{ width: 500 }} multiline label="Medical Conditions" source="Child.MedConds" validate={required()} />
                            </Box>
                        </Box>
                    </Box>
                </FormTab>
                <FormTab label="Parent/Guardian Information">
                    <Box style={{ width: "100%" }} >
                        <Box >
                            <span><b>Parent / Guardian:</b></span>
                        </Box>
                        <ArrayInput label="" source="Parents" validate={[required(), validateParentData()]}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ getSource, scopedFormData }) => {
                                        return (
                                            <Box style={{ width: "100%" }}>
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="Name" source={getSource('Name')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }} label="Relation to Child" source={getSource('Relation')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }}
                                                            parse={phoneNumberFormat}
                                                            label="Contact Phone"
                                                            source={getSource('Phone')}
                                                            validate={validatePhone}
                                                        />
                                                    </Box>
                                                    <Box >
                                                        <TextInput style={{ width: 300 }} label="Email" source={getSource('Email')} validate={validateEmail} />
                                                    </Box>
                                                </Box>
                                                <Box >
                                                    <Box >
                                                        <span><b>Address if different from Child's home address:</b></span>
                                                    </Box>
                                                    <Box display="flex" >
                                                        <TextInput style={{ width: 300 }} label="Street" source={getSource('Addr.Street')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Apt #" source={getSource('Addr.AptorSuite')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="City" source={getSource('Addr.City')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="State" source={getSource('Addr.State')} validate={validateStateOpt} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Addr.Zip')} validate={validateZipOpt} />
                                                    </Box>
                                                </Box>
                                                <Box >
                                                    <span><b>Work Info:</b></span>
                                                </Box>
                                                <Box >
                                                    <TextInput label="Job Title" source={getSource('Work.JobTitle')} validate={required()} /><MyTab />
                                                    <TextInput label="Job Description" source={getSource('Work.JobDescription')} validate={required()} /><MyTab />
                                                    <TextInput label="Company Name" source={getSource('Work.Company.Name')} validate={required()} />
                                                    <Box >
                                                        <span><b>Company Address:</b></span>
                                                    </Box>
                                                    <Box display="flex">
                                                        <TextInput style={{ width: 300 }} label="Street" source={getSource('Work.Company.Addr.Street')} validate={required()} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Suite #" source={getSource('Work.Company.Addr.AptorSuite')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="City" source={getSource('Work.Company.Addr.City')} validate={required()} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="State" source={getSource('Work.Company.Addr.State')} validate={validateState} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Work.Company.Addr.Zip')} validate={validateZip} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </FormTab>
                <FormTab label="Personal Information">
                    <Box style={{ width: "100%" }} >
                        <Box >
                            <span><b>Child’s interests and favorite activities:</b></span><br></br>
                            <TextInput style={{ width: 500 }} multiline label="Child's Activities" source="Child.Activities" validate={required()} />
                        </Box>
                        <Box >
                            <span><b>Characteristic Behavior (check all that apply):</b></span>
                            <CheckboxGroupInput label="" source="Child.Behavior" choices={[
                                { id: 'shy', name: 'Shy' },
                                { id: 'calm', name: 'Calm' },
                                { id: 'cheerful', name: 'Cheerful' },
                                { id: 'aggressive', name: 'Aggressive' },
                                { id: 'whining', name: 'Whining' },
                                { id: 'friendly', name: 'Friendly' },
                                { id: 'excitable', name: 'Excitable' },
                                { id: 'fearful', name: 'Fearful' },
                                { id: 'cooperative', name: 'Cooperative' }
                            ]} validate={required()} />
                        </Box>
                        <Box >
                            <span><b>Any fears? (History and manifestations):</b></span><br></br>
                            <TextInput style={{ width: 500 }} multiline label="" source="Child.Fears" />
                        </Box>
                        <Box >
                            <span><b>Types of home discipline most frequently used:</b></span><br></br>
                            <TextInput style={{ width: 500 }} multiline label="" source="Child.Discipline" validate={required()} />
                        </Box>
                        <Box>
                            <Box >
                                <span><b>Emergency Contact</b></span>
                            </Box>
                            <Box>
                                <b>In case of Emergency, if neither parent can be reached, who can we contact? </b>
                            </Box>
                            <Box display="flex">
                                <Box mr="14em">
                                    <b>First and Last Name </b>
                                </Box>
                                <Box mr="8em">
                                    <b>Relationship to Child</b>
                                </Box>
                                <Box mr="6em">
                                    <b>Contact Phone# </b>
                                </Box>
                            </Box>
                            <ArrayInput label="" source="EmergContacts" validate={required()}>
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput
                                                            style={{ width: 150 }} parse={phoneNumberFormat}
                                                            label="Phone"
                                                            source={getSource('Phone')}
                                                            validate={validatePhone}
                                                        />
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                        <Box>
                            <Box >
                                <span><b>Authorization to Drop-Off / Pick-Up Child</b></span>
                            </Box>
                            <Box>
                                <b>Who will be the main adult to drop-off/pick-up the child? </b>
                            </Box>
                            <Box display="flex">
                                <Box mr="14em">
                                    <b>First and Last Name </b>
                                </Box>
                                <Box mr="8em">
                                    <b>Relationship to Child</b>
                                </Box>
                                <Box mr="6em">
                                    <b>Contact Phone# </b>
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box mr="4em">
                                    <TextInput style={{ width: 300 }} label="First and Last Name" source="MainAuth.Name" validate={required()} />
                                </Box>
                                <Box mr="4em">
                                    <TextInput style={{ width: 150 }} label="Relationship" source="MainAuth.Relation" validate={required()} />
                                </Box>
                                <Box mr="4em">
                                    <TextInput
                                        style={{ width: 150 }} parse={phoneNumberFormat}
                                        label="Phone"
                                        source="MainAuth.Phone"
                                        validate={validatePhone}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <b>Who are the other adults that have authorization to drop-off/pick-up the child? </b>
                            </Box>
                            <ArrayInput label="" source="Oauths" isRequired={false} >
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput
                                                            style={{ width: 150 }} parse={phoneNumberFormat}
                                                            label="Phone"
                                                            source={getSource('Phone')}
                                                            validate={validatePhoneOpt}
                                                        />
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                </FormTab>
                <FormTab label="Enrollment Program Information">
                    <Box style={{ width: "100%" }}>
                        <Box >
                            <span><b>How did you hear about LAMS?</b></span>
                            <CheckboxGroupInput label="" source="Mkting.Website" choices={[
                                { id: 'Adwebsite', name: 'Website' }
                            ]} />
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.SE" choices={[
                                    { id: 'Adsearcheng', name: 'Online Search Engine (i.e., Google)' }
                                ]} />
                                <TextInput label="Specify Search Engine" source="Mkting.SEName" />
                            </Box>
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Referred" choices={[
                                    { id: 'Adrefer', name: 'Referred By a Parent/Student' }
                                ]} />
                                <TextInput label="First and Last Name" source="Mkting.ReferingPerson.Name" /><MyTab />
                                <TextInput label="Relation to Child" source="Mkting.ReferingPerson.Relation" />
                            </Box>
                            <CheckboxGroupInput label="" source="Mkting.FriendorRel" choices={[
                                { id: 'Adfndrel', name: 'From a Friend/Relative' }
                            ]} />
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Other" choices={[
                                    { id: 'Adother', name: 'Other' }
                                ]} />
                                <TextInput label="Specify" source="Mkting.OtherDtls" />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>LAMS strongly encourages a three-year program (including kindergarten) in order to provide the maximum advantage of the Montessori method. A two-year program is the minimum recommended enrollment. How long do you intend to enroll your child at LAMS?</b></span>
                            <Box display="flex">
                                <RadioButtonGroupInput style={{ width: "300px" }} label="" source="Intent.Duration" choices={[
                                    { id: '2', name: '2 Years' },
                                    { id: '3', name: '3 Years' },
                                    { id: 'other', name: 'Other' }
                                ]} validate={required()} />
                                <TextInput label="Specify" source="Intent.Other" />
                            </Box>
                            <Box>
                                    <span><b>When is the desired enrollment (start) date? [School is closed for month of July for summer vacation, no fee is charged]</b></span><br></br>
                                    <DateInput label="Start date" source="Child.DesiredEnrollmentDate" validate={validateEnrollmentDate} />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>Program Selection: </b></span><br></br>
                            <RadioButtonGroupInput label="" source="Prg.Name" choices={[
                                { id: 'Toddler', name: 'Toddler Program' },
                                { id: 'Primary', name: 'Primary Program' }
                            ]} onChange={handlePrgSel} validate={required()} />
                            <FormDataConsumer>
                                {({ formData, ...rest }) => primaryPrg &&
                                    <SelectInput style={{ width: "250px" }} label="Child is Fully Potty Trained" source="Child.PottyTrained" choices={[
                                        { id: 'N', name: 'N' },
                                        { id: 'Y', name: 'Y' },
                                        // { id: 'T', name: 'InTraining' }
                                    ]} {...rest} validate={required()} />
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                    {({ formData, ...rest }) => primaryPrg &&
                                        <span>
                                        <b>Child attending LAMS for primary program is required to be fully potty trained. For details of what fully potty trained means please refer <a target="_blank" href="https://lamsaz.com/formsAndCalendar/index.html#potty_trg_req">here</a>.</b>
                                        </span>
                                    }
                            </FormDataConsumer>
                            <br></br>
                            <RadioButtonGroupInput label="" source="Prg.Type" choices={[
                                { id: 'HalfDay', name: 'Half Day 8:05 AM - 12:30' },
                                { id: 'FullDay', name: 'Full Day 8:05 AM - 3:00 PM' },
                                { id: 'ExtendedDay', name: 'Extended Day 7:30 AM - 5:00 PM' }
                            ]} validate={required()} />
                        </Box>
                        <Box>
                            <span><b>Approval Acceptance: </b></span><br></br>
                            <span>If and when approved, is a tour of the school a precondition for your acceptance of the enrollment?</span>
                            <SelectInput style={{ width: "250px" }} label="Approval Acceptance" source="TourPrecondition" choices={[
                                            { id: 'Y', name: 'Y' },
                                            { id: 'N', name: 'N' },
                                        ]} validate={required()} />
                        </Box>
                        <Box >
                            <span><b>Does your child have previous school experience?</b></span>
                            <Box display="flex">
                                <TextInput label="Name of school" source="Child.PrvSchool.Name" /><MyTab />
                                <TextInput label="Address" source="Child.PrvSchool.Addr" /><MyTab />
                                <DateInput label="Attendance From date" source="Child.PrvSchool.FrmDt" /><MyTab />
                                <DateInput label="Attendance To date" source="Child.PrvSchool.ToDt" />
                            </Box>
                        </Box>
                        <Box >
                            <span><b><u>What do you hope a Montessori education will do for your child:</u></b></span><br></br>
                            <TextInput style={{ width: "500px" }} multiline label="" source="HopeFrmMontessoriEdu" validate={required()} />
                        </Box>
                        <Box >
                            <span><b><u>Describe briefly the background of your interest in our Montessori program:</u></b></span><br></br>
                            <TextInput style={{ width: "500px" }} multiline label="" source="BackgrdOfMontessori" validate={required()} />
                        </Box>
                    </Box>
                </FormTab>
                <FormTab label="General Release Form">
                    <Box style={{ width: "100%" }}>
                        <Box >
                            <ol><li>LAMS may use photographs, reproductions, video recordings, and/or sound recordings of my child(ren). Such use may include advertising and publicity purposes.</li>
                                <RadioButtonGroupInput label="" source="GenRelease.UseofMedia" choices={[
                                    { id: 'Y', name: 'Yes' },
                                    { id: 'N', name: 'No' }
                                ]} validate={required()} />
                                <li>I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li>
                                <li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li>
                                <li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li>
                                <li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li>
                                <li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li>
                                <li>Children attending LAMS(for primary program) are required to be fully potty trained.</li>
                                <li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li>
                                <li>We understand balances due must be paid before evaluations can be released.</li>
                                <li>Fee once paid is not refundable under any circumstances.</li>
                                <li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li>
                            </ol>
                        </Box>
                        <Box>
                            <span><b>Parent’s/Guardian’s Approval</b></span>
                            <Box>
                                <span><b>Parent/Guardian #1</b></span><br></br>
                                <TextInput label="Name" source="GenRelease.PApp1.Name" validate={required()} /><MyTab />
                                <TextInput label="Relationship" source="GenRelease.PApp1.Relation" validate={required()} /><MyTab />
                                <DateInput label="Date" source="GenRelease.PApp1.Date" validate={required()} />
                                <Box style={{ width: "100%" }}>
                                    <span style={{ color: "red", "font-weight": "bold" }}>Signature:(REQUIRED)</span>
                                    <Signature source="GenRelease.PApp1.Signature" validate={required()} />
                                </Box>
                            </Box>
                            <Box>
                                <span><b>Parent/Guardian #2</b></span><br></br>
                                <TextInput label="Name" source="GenRelease.PApp2.Name" validate={required()} /><MyTab />
                                <TextInput label="Relationship" source="GenRelease.PApp2.Relation" validate={required()} /><MyTab />
                                <DateInput label="Date" source="GenRelease.PApp2.Date" validate={required()} />
                                <Box style={{ width: "100%" }}>
                                    <span style={{ color: "red", "font-weight": "bold" }}>Signature:(REQUIRED)</span>
                                    <Signature source="GenRelease.PApp2.Signature" validate={required()} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </FormTab>
            </TabbedForm >
        </Create >
    );
}

export const LamsApplicationShow = (props) => (
    <Show className="txtCopy" title={<LamsApplicationTitle />} {...props} actions={<ActionsRefresh {...props} />}>
        <TabbedShowLayout>
            <Tab label="Child's Information" >

                <Box style={{ width: "100%" }} >
                    <Box display="flex">
                        <Box>
                            <Labeled label="Child's Name">
                                <TextField source="Child.Name" />
                            </Labeled>
                        </Box>
                        <Box ml="2em">
                            <Labeled label="Child’s Nick Name (if any)">
                                <TextField source="Child.Nick" />
                            </Labeled>
                        </Box>
                        <Box ml="2em">
                            <Labeled label="Status">
                                <TextField source="Status" />
                            </Labeled>
                        </Box>
                        <Box ml="2em">
                            <Labeled label="Approved enrollment Date">
                                <DateField source="Child.ApprovedEnrollmentDate"  locales="en-US" options={{ timeZone: 'UTC' }} />
                            </Labeled>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box>
                            <Labeled label="Child’s Date of Birth">
                                <DateField source="Child.Dob" locales="en-US" options={{ timeZone: 'UTC' }} />
                            </Labeled>
                        </Box>
                        <Box ml="2em">
                            <Labeled label="Child’s Place of Birth">
                                <TextField source="Child.Pob" />
                            </Labeled>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box >
                            <Labeled label="Child’s Gender">
                                <TextField source="Child.Sex" />
                            </Labeled>
                        </Box>
                        <Box ml="2em" >
                            <Labeled label="Child's Contact Phone">
                                <TextField source="Child.Phone" />
                            </Labeled>
                        </Box>
                    </Box>
                    <Box >
                        <Box>
                            <Box>
                                <b>Child’s Home Address </b>
                            </Box>
                            <Box display="flex">
                                <Labeled style={{ width: 300 }} label="Street">
                                    <TextField source="Child.HomeAddr.Street" />
                                </Labeled>
                                <MyTab />
                                <Labeled label="Apt #">
                                    <TextField source="Child.HomeAddr.AptorSuite" />
                                </Labeled>
                                <MyTab />
                                <Labeled label="City">
                                    <TextField source="Child.HomeAddr.City" />
                                </Labeled>
                                <MyTab />
                                <Labeled label="State">
                                    <TextField source="Child.HomeAddr.State" />
                                </Labeled>
                                <MyTab />
                                <Labeled label="Zipcode">
                                    <TextField source="Child.HomeAddr.Zip" />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box >
                            <Box>
                                <b>Child’s Mailing Address, if different from above </b>
                            </Box>
                            <Box display="flex">
                                <Labeled style={{ width: 300 }} label="Street">
                                    <TextField source="Child.MailingAddr.Street" />
                                </Labeled>
                                <MyTab />
                                <Labeled label="Apt #">
                                    <TextField source="Child.MailingAddr.AptorSuite" />
                                </Labeled>
                                <MyTab />
                                <Labeled label="City">
                                    <TextField source="Child.MailingAddr.City" />
                                </Labeled>
                                <MyTab />
                                <Labeled label="State">
                                    <TextField source="Child.MailingAddr.State" />
                                </Labeled>
                                <MyTab />
                                <Labeled label="Zipcode">
                                    <TextField source="Child.MailingAddr.Zip" />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <b>Child's Siblings? </b>
                            </Box>


                            <ArrayField source="Siblings">
                                <Datagrid>

                                    <Box display="flex">
                                        <Box mr="4em">
                                            <Labeled style={{ width: 300 }} label="First and Last Name" >
                                                <TextField source="Name" />
                                            </Labeled>
                                        </Box>
                                        <Box mr="4em">
                                            <Labeled style={{ width: 50 }} label="Age" >
                                                <TextField source="Age" />
                                            </Labeled>
                                        </Box>
                                        <Box mr="4em">
                                            <Labeled style={{ width: 200 }} label="Gender" >
                                                <TextField source="Sex" />
                                            </Labeled>
                                        </Box>
                                        <Box mr="4em">
                                            <Labeled style={{ width: 200 }} label="Enrolled At LAMS" >
                                                <TextField source="Enrolled" />
                                            </Labeled>
                                        </Box>
                                    </Box>

                                </Datagrid>
                            </ArrayField >

                        </Box>
                        <Box>
                            <Box>
                                <b>Whom does the child live with and the household members (parents, siblings, relatives, etc.)? </b>
                            </Box>

                            <ArrayField label="HHM" source="HHmembers">
                                <Datagrid>

                                    <Box display="flex">
                                        <Box mr="4em">
                                            <Labeled style={{ width: 300 }} label="First and Last Name" >
                                                <TextField source="Name" validate={required()} />
                                            </Labeled>
                                        </Box>
                                        <Box mr="4em">
                                            <Labeled style={{ width: 200 }} label="Relationship" >
                                                <TextField source="Relation" validate={required()} />
                                            </Labeled>
                                        </Box>
                                        <Box mr="4em">
                                            <Labeled style={{ width: 50 }} label="Age" >
                                                <TextField source="Age" validate={required()} />
                                            </Labeled>
                                        </Box>
                                        <Box mr="4em">
                                            <Labeled style={{ width: 200 }} label="Gender" >
                                                <TextField source="Sex" />
                                            </Labeled>
                                        </Box>
                                    </Box>

                                </Datagrid>
                            </ArrayField>
                        </Box>
                    </Box>
                    <Box >
                        <Box >
                            <b>What languages does the child speak at home? </b>
                        </Box>
                        <Box display="flex">
                            <Labeled style={{ width: 300 }} label="Languages" >
                                <LangSelection />
                            </Labeled>
                            <Labeled label="Specify Other Language">
                                <TextField source="Child.Langs.Otherlang" />
                            </Labeled>
                        </Box>
                    </Box>
                    <Box >
                        <Box >
                            <b><u>Child’s Doctor:</u></b>
                        </Box>
                        <Box>
                            <Box>
                                <Labeled label="Doctor’s Name">
                                    <TextField source="Child.Dr.Name" validate={required()} />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled label="Name of Practice">
                                    <TextField source="Child.Dr.Practice" validate={required()} />
                                </Labeled>
                            </Box>
                            <Box >
                                <b><u>Address:</u></b>
                            </Box>
                            <Box display="flex">
                                <Labeled style={{ width: 300 }} label="Street">
                                    <TextField source="Child.Dr.Addr.Street" validate={required()} />
                                </Labeled><MyTab />
                                <Labeled label="Suite #">
                                    <TextField source="Child.Dr.Addr.AptorSuite" />
                                </Labeled><MyTab />
                                <Labeled label="City">
                                    <TextField source="Child.Dr.Addr.City" validate={required()} />
                                </Labeled><MyTab />
                                <Labeled label="State">
                                    <TextField source="Child.Dr.Addr.State" validate={validateState} />
                                </Labeled><MyTab />
                                <Labeled label="Zipcode">
                                    <TextField source="Child.Dr.Addr.Zip" validate={validateZip} />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled label="Phone">
                                    <TextField source="Child.Dr.Phone"
                                        validate={validatePhone}
                                    />
                                </Labeled>
                            </Box>
                        </Box>
                    </Box>
                    <Box >
                        <span><b><u>Describe below any medical conditions the child has (including food or medication allergies):</u></b></span>
                        <Box>
                            <Labeled style={{ width: 500 }} multiline label="Medical Conditions">
                                <TextField source="Child.MedConds" validate={required()} />
                            </Labeled>
                        </Box>
                    </Box>
                </Box>
            </Tab>
            <Tab label="Parent/Guardian Information">
                <Box style={{ width: "100%" }} >
                    <Box >
                        <span><b>Parent / Guardian:</b></span>
                    </Box>
                    <ArrayField label="P/G" source="Parents" validate={required()}>
                        <Datagrid>

                            <Box style={{ width: "100%" }}>
                                <Box display="flex">
                                    <Box mr="4em">
                                        <Labeled style={{ width: 300 }} label="Name" >
                                            <TextField source="Name" validate={required()} />
                                        </Labeled>
                                    </Box>
                                    <Box mr="4em">
                                        <Labeled style={{ width: 150 }} label="Relation to Child" >
                                            <TextField source="Relation" validate={required()} />
                                        </Labeled>
                                    </Box>
                                    <Box mr="4em">
                                        <Labeled style={{ width: 150 }} label="Contact Phone">
                                            <TextField source="Phone"
                                                validate={validatePhone}
                                            />
                                        </Labeled>
                                    </Box>
                                    <Box >
                                        <Labeled style={{ width: 300 }} label="Email" >
                                            <TextField source="Email" validate={validateEmail} />
                                        </Labeled>
                                    </Box>
                                </Box>
                                <Box >
                                    <Box >
                                        <span><b>Address if different from Child's home address:</b></span>
                                    </Box>
                                    <Box display="flex" >
                                        <Labeled style={{ width: 300 }} label="Street" >
                                            <TextField source="Addr.Street" />
                                        </Labeled><MyTab />
                                        <Labeled style={{ width: 150 }} label="Apt #" >
                                            <TextField source="Addr.AptorSuite" />
                                        </Labeled><MyTab />
                                        <Labeled style={{ width: 150 }} label="City" >
                                            <TextField source="Addr.City" />
                                        </Labeled><MyTab />
                                        <Labeled style={{ width: 150 }} label="State" >
                                            <TextField source="Addr.State" validate={validateStateOpt} />
                                        </Labeled><MyTab />
                                        <Labeled style={{ width: 150 }} label="Zipcode" >
                                            <TextField source="Addr.Zip" validate={validateZipOpt} />
                                        </Labeled>
                                    </Box>
                                </Box>
                                <Box >
                                    <span><b>Work Info: </b></span>
                                </Box>
                                <Box >
                                    <Labeled label="Job Title">
                                        <TextField source="Work.JobTitle" validate={required()} />
                                    </Labeled>
                                    <MyTab />
                                    <Labeled label="Job Description">
                                        <TextField source="Work.JobDescription" validate={required()} />
                                    </Labeled>
                                    <MyTab />
                                    <Labeled label="Company Name">
                                        <TextField source="Work.Company.Name" validate={required()} />
                                    </Labeled>
                                    <Box >
                                        <span><b>Company Address: </b></span>
                                    </Box>
                                    <Box display="flex">
                                        <Labeled style={{ width: 300 }} label="Street" >
                                            <TextField source="Work.Company.Addr.Street" validate={required()} />
                                        </Labeled><MyTab />
                                        <Labeled style={{ width: 150 }} label="Suite #" >
                                            <TextField source="Work.Company.Addr.AptorSuite" />
                                        </Labeled><MyTab />
                                        <Labeled style={{ width: 150 }} label="City" >
                                            <TextField source="Work.Company.Addr.City" validate={required()} />
                                        </Labeled><MyTab />
                                        <Labeled style={{ width: 150 }} label="State" >
                                            <TextField source="Work.Company.Addr.State" validate={validateState} />
                                        </Labeled><MyTab />
                                        <Labeled style={{ width: 150 }} label="Zipcode" >
                                            <TextField source="Work.Company.Addr.Zip" validate={validateZip} />
                                        </Labeled>
                                    </Box>
                                </Box>
                            </Box>

                        </Datagrid>
                    </ArrayField>
                </Box >
            </Tab >
            <Tab label="Personal Information">
                <Box style={{ width: "100%" }} >
                    <Box >
                        <span><b>Child’s interests and favorite activities:</b></span><br></br>
                        <TextField source="Child.Activities" validate={required()} />
                    </Box>
                    <Box >
                        <span><b>Characteristic Behavior (check all that apply):</b></span>
                        <Behavior />
                    </Box>
                    <Box >
                        <span><b>Any fears? (History and manifestations):</b></span><br></br>
                        <TextField source="Child.Fears" />
                    </Box>
                    <Box >
                        <span><b>Types of home discipline most frequently used:</b></span><br></br>
                        <TextField source="Child.Discipline" validate={required()} />
                    </Box>
                    <Box>
                        <Box >
                            <span><b>Emergency Contact</b></span>
                        </Box>
                        <Box>
                            <b>In case of Emergency, if neither parent can be reached, who can we contact? </b>
                        </Box>

                        <ArrayField label="Emr" source="EmergContacts" validate={required()}>
                            <Datagrid>

                                <Box display="flex">
                                    <Box mr="4em">
                                        <Labeled style={{ width: 300 }} label="First and Last Name" >
                                            <TextField source="Name" validate={required()} />
                                        </Labeled>
                                    </Box>
                                    <Box mr="4em">
                                        <Labeled style={{ width: 150 }} label="Relationship" >
                                            <TextField source="Relation" validate={required()} />
                                        </Labeled>
                                    </Box>
                                    <Box mr="4em">
                                        <Labeled
                                            style={{ width: 150 }}
                                            label="Phone"
                                        >
                                            <TextField source="Phone"
                                                validate={validatePhone}
                                            />
                                        </Labeled>
                                    </Box>
                                </Box>

                            </Datagrid>
                        </ArrayField>
                    </Box>
                    <Box>
                        <Box >
                            <span><b>Authorization to Drop-Off / Pick-Up Child</b></span>
                        </Box>
                        <Box>
                            <b>Who will be the main adult to drop-off/pick-up the child? </b>
                        </Box>

                        <Box display="flex">
                            <Box mr="4em">
                                <Labeled style={{ width: 300 }} label="First and Last Name" >
                                    <TextField source="MainAuth.Name" validate={required()} />
                                </Labeled>
                            </Box>
                            <Box mr="4em">
                                <Labeled style={{ width: 150 }} label="Relationship" >
                                    <TextField source="MainAuth.Relation" validate={required()} />
                                </Labeled>
                            </Box>
                            <Box mr="4em">
                                <Labeled
                                    style={{ width: 150 }}
                                    label="Phone"
                                >
                                    <TextField source="MainAuth.Phone"
                                        validate={validatePhone}
                                    />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box>
                            <b>Who are the other adults that have authorization to drop-off/pick-up the child? </b>
                        </Box>
                        <ArrayField label="Oauth" source="Oauths" isRequired={false} >
                            <Datagrid>

                                <Box display="flex">
                                    <Box mr="4em">
                                        <Labeled style={{ width: 300 }} label="First and Last Name" >
                                            <TextField source="Name" />
                                        </Labeled>
                                    </Box>
                                    <Box mr="4em">
                                        <Labeled style={{ width: 150 }} label="Relationship" >
                                            <TextField source="Relation" />
                                        </Labeled>
                                    </Box>
                                    <Box mr="4em">
                                        <Labeled
                                            style={{ width: 150 }}
                                            label="Phone"
                                        >
                                            <TextField source="Phone"
                                                validate={validatePhoneOpt}
                                            />
                                        </Labeled>
                                    </Box>
                                </Box>

                            </Datagrid>
                        </ArrayField>
                    </Box>
                </Box>
            </Tab>
            <Tab label="Enrollment Program Information">
                <Box style={{ width: "100%" }}>
                    <Box >
                        <span><b></b></span>
                        <Labeled label="How did you hear about LAMS?" >

                            <SelectField source="Mkting.Website[0]" choices={[
                                { id: 'Adwebsite', name: 'Website' }
                            ]} />
                        </Labeled>
                        <Box display="flex">
                            <Labeled style={{ width: "200px" }} label="Online Search Engine (i.e., Google)" >

                                <SelectField source="Mkting.SE[0]" choices={[
                                    { id: 'Adsearcheng', name: '\u2714' }
                                ]} />
                            </Labeled>
                            <Labeled label="Specify Search Engine">
                                <TextField source="Mkting.SEName" />
                            </Labeled>
                        </Box>
                        <Box display="flex">
                            <Labeled style={{ width: "200px" }} label="Referred By a Parent/Student" >

                                <SelectField source="Mkting.Referred[0]" choices={[
                                    { id: 'Adrefer', name: '\u2714' }
                                ]} />
                            </Labeled>
                            <Labeled label="First and Last Name">
                                <TextField source="Mkting.ReferingPerson.Name" />
                            </Labeled><MyTab />
                            <Labeled label="Relation to Child">
                                <TextField source="Mkting.ReferingPerson.Relation" />
                            </Labeled>
                        </Box>
                        <Labeled label="From a Friend/Relative" >

                            <SelectField source="Mkting.FriendorRel[0]" choices={[
                                { id: 'Adfndrel', name: '\u2714' }
                            ]} />
                        </Labeled>
                        <Box display="flex">
                            <Labeled style={{ width: "200px" }} label="Other" >

                                <SelectField source="Mkting.Other[0]" choices={[
                                    { id: 'Adother', name: '\u2714' }
                                ]} />
                            </Labeled>
                            <Labeled label="Specify">
                                <TextField source="Mkting.OtherDtls" />
                            </Labeled>
                        </Box>
                    </Box>
                    <Box >
                        <span><b>LAMS strongly encourages a three-year program (including kindergarten) in order to provide the maximum advantage of the Montessori method. A two-year program is the minimum recommended enrollment. How long do you intend to enroll your child at LAMS?</b></span>
                        <Box display="flex">

                            <SelectField source="Intent.Duration" choices={[
                                { id: '2', name: '2 Years' },
                                { id: '3', name: '3 Years' },
                                { id: 'other', name: 'Other' }
                            ]} /><MyTab />

                            <TextField source="Intent.Other" />
                        </Box>
                        <Box>
                            <span><b>When is the desired enrollment (start) date?</b></span><br></br>
                            <TextField source="Child.DesiredEnrollmentDate" validate={required()} />
                        </Box>
                    </Box>
                    <Box >
                        <span><b>Program Selection: </b></span><br></br>
                        <Labeled label="Program Name" >
                            <SelectField source="Prg.Name" choices={[
                                { id: 'Toddler', name: 'Toddler Program' },
                                { id: 'Primary', name: 'Primary Program' }
                            ]} />
                        </Labeled><br></br>
                        <Labeled label="Child is Fully Potty Trained" >
                            <SelectField style={{ width: "250px" }} source="Child.PottyTrained" choices={[
                                { id: 'N', name: 'N' },
                                { id: 'Y', name: 'Y' },
                                { id: 'T', name: 'InTraining' }
                            ]} />
                        </Labeled>
                        <br></br>
                        <Labeled label="Program Type" >
                            <SelectField source="Prg.Type" choices={[
                                { id: 'HalfDay', name: 'Half Day 8:05 AM - 12:30' },
                                { id: 'FullDay', name: 'Full Day 8:05 AM - 3:00 PM' },
                                { id: 'ExtendedDay', name: 'Extended Day 7:30 AM - 5:00 PM' }
                            ]} />
                        </Labeled>
                    </Box>
                    <Box>
                                <span><b>Approval Acceptance: </b></span><br></br>
                                <span>If and when approved, is a tour of the school a precondition for your acceptance of the enrollment?</span><br></br>
                                <SelectField style={{ width: "250px" }} label="Approval Acceptance" source="TourPrecondition" choices={[
                                            { id: 'Y', name: 'Y' },
                                            { id: 'N', name: 'N' },
                                        ]} />
                    </Box>
                    <Box >
                        <span><b>Does your child have previous school experience?</b></span>
                        <Box display="flex">
                            <Labeled label="Name of school">
                                <TextField source="Child.PrvSchool.Name" />
                            </Labeled><MyTab />
                            <Labeled label="Address">
                                <TextField source="Child.PrvSchool.Addr" />
                            </Labeled><MyTab />
                            <Labeled label="Attendance From date" >
                                <TextField source="Child.PrvSchool.FrmDt" />
                            </Labeled><MyTab />
                            <Labeled label="Attendance To date" >
                                <TextField source="Child.PrvSchool.ToDt" />
                            </Labeled>
                        </Box>
                    </Box>
                    <Box >
                        <span><b><u>What do you hope a Montessori education will do for your child:</u></b></span><br></br>
                        <TextField source="HopeFrmMontessoriEdu" validate={required()} />
                    </Box>
                    <Box >
                        <span><b><u>Describe briefly the background of your interest in our Montessori program:</u></b></span><br></br>
                        <TextField source="BackgrdOfMontessori" validate={required()} />
                    </Box>
                </Box>
            </Tab>
            <Tab label="General Release Form">
                <Box style={{ width: "100%" }}>
                    <Box >
                        <ol><li>LAMS may use photographs, reproductions, video recordings, and/or sound recordings of my child(ren). Such use may include advertising and publicity purposes.</li>
                            <SelectField source="GenRelease.UseofMedia" choices={[
                                { id: 'Y', name: 'Yes' },
                                { id: 'N', name: 'No' }
                            ]} validate={required()} />
                            <li>I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li>
                            <li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li>
                            <li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li>
                            <li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li>
                            <li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li>
                            <li>Children attending LAMS are required to be fully potty trained.</li>
                            <li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li>
                            <li>We understand balances due must be paid before evaluations can be released.</li>
                            <li>Fee once paid is not refundable under any circumstances.</li>
                            <li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li>
                        </ol>
                    </Box>
                    <Box>
                        <span><b>Parent’s/Guardian’s Approval</b></span>
                        <Box>
                            <span><b>Parent/Guardian #1</b></span><br></br>
                            <Labeled label="Name">
                                <TextField source="GenRelease.PApp1.Name" validate={required()} />
                            </Labeled><MyTab />
                            <Labeled label="Realtionship">
                                <TextField source="GenRelease.PApp1.Relation" validate={required()} />
                            </Labeled><MyTab />
                            <Labeled label="Date" >
                                <TextField source="GenRelease.PApp1.Date" validate={required()} />
                            </Labeled>
                            <Box style={{ width: "100%" }}>
                                <span>Signature:</span>
                                <DisplaySignature disabled label="Signature" source="GenRelease.PApp1.Signature" />
                            </Box>
                        </Box>
                        <Box>
                            <span><b>Parent/Guardian #2</b></span><br></br>
                            <Labeled label="Name">
                                <TextField source="GenRelease.PApp2.Name" validate={required()} />
                            </Labeled><MyTab />
                            <Labeled label="Realtionship">
                                <TextField source="GenRelease.PApp2.Relation" validate={required()} />
                            </Labeled><MyTab />
                            <Labeled label="Date" >
                                <TextField source="GenRelease.PApp2.Date" validate={required()} />
                            </Labeled>
                            <Box style={{ width: "100%" }}>
                                <span>Signature:</span>
                                <DisplaySignature disabled label="Signature" source="GenRelease.PApp2.Signature" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Tab>
        </TabbedShowLayout >
    </Show >
);
                        
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
const LamsApplicationListActions = props => {

    return (
        <TopToolbar>
            <BulkDeleteButton {...props} undoable="true" /><MyTab /><MyTab /><MyTab /><MyTab />
            <SendApplicationEmailButton {...props} />
        </TopToolbar>
    );
}
const LamsApplicationCreateActions = ({ basePath, data, refresh }) => (
    <CardActions style={cardActionStyle}>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
        <DeleteButton basePath={basePath} record={data} />
        <FlatButton primary label="Refresh" onClick={refresh} icon={<NavigationRefresh />} />
    </CardActions>
);
const OfficeMgrListActions = (props) => {
    // console.log("In student buttons ", props);
    // let st = props.filterValues.EStatus;
    // let cl = props.cl;
    // console.log("Classroom filter ", cl, st);
    return (
        <TopToolbar>
        </TopToolbar>
    );
}