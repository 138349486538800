import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import Tooltip from '@material-ui/core/Tooltip';

class CreateFeeReminderEmailButton extends Component {
  handleClick = () => {
    // console.log("in gen receipt" + process.env.REACT_APP_FEEREMINDEREMAIL_URL);
    const fetchURL = process.env.REACT_APP_FEEREMINDEREMAIL_URL
    // console.log(fetchURL)
    const { push, selectedIds, showNotification } = this.props;
    // console.log(this.props);
    // console.log("sending " + selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      // "Content-Length": JSON.stringify(body).length
    })
    // const bdy="{\"studs\":[{\"id\":\"8bb9e4a0-5387-11ea-8d8c-f8cab832656a\",\"sname\":\"Test04\",\"Sdob\":\"01/01/2018\",\"sdoj\":\"02/10/2019\",\"semail\":\"\",\"eStatus\":\"Active\",\"graduationDate\":\"\",\"schoolID\":3,\"program\":\"fullday\",\"tuition\":\"$875\",\"sex\":\"F\",\"pIN\":\"8976\",\"immunizationStatus\":\"complete\",\"classroom\":\"montessori01\",\"parents\":[{\"pName\":\"parent01\",\"address\":\"abc, AZ 85001\",\"tel\":\"6230019999\",\"email\":\"abc@xyz.com\"},{\"pName\":\"parent02\",\"address\":\"abc, AZ 85002\",\"tel\":\"6230019990\",\"email\":\"abc2@xyz.com\"}]},{\"id\":\"98e64254-537e-11ea-aab6-f8cab832656a\",\"Sname\":\"newtest01\",\"Sdob\":\"01/01/1999\",\"Sdoj\":\"\",\"Semail\":\"\",\"EStatus\":\"Graduated\",\"GraduationDate\":\"\",\"SchoolID\":3,\"Program\":\"Toddler - FullDay\",\"Tuition\":\"\",\"Sex\":\"F\",\"PIN\":\"9876\",\"ImmunizationStatus\":\"Not Provided\",\"Classroom\":\"Classroom 03\"},{\"id\":\"2dba6ce6-537f-11ea-aab6-f8cab832656a\",\"Sname\":\"newtest01\",\"Sdob\":\"1/1/1999\",\"Sdoj\":\"\",\"Semail\":\"\",\"EStatus\":\"\",\"GraduationDate\":\"\",\"SchoolID\":3,\"Program\":\"Toddler - FullDay\",\"Tuition\":\"\",\"Sex\":\"F\",\"PIN\":\"\",\"ImmunizationStatus\":\"Not Provided\",\"Classroom\":\"Classroom 03\"}]}";
    const bdy = JSON.stringify(selectedIds);
    // const updatedRecord = { ...record, is_approved: true };
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            // console.log(response, json);
            if (json != "") {
              showNotification("Receipts generated");
              push('/Receipts');

            } else {
              showNotification("Receipts generated");
              push('/Receipts');
            }
          });
        } else if (response.status === '401' ) {
          showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            // console.log(response, json);
            if (json != "") {
              showNotification("Unable to generate for " + json.message);

            } else {
              showNotification("Error generating receipt(s)");
            }
          });
        }
      })
      .catch((e) => {
        console.error(e);
        showNotification('Error: generating fee reminder email', 'warning')
      });
  }

  render() {
    return <div><Tooltip title="Fee Reminder Email"><IconButton color="primary" component="span" onClick={this.handleClick}>
      <EmailIcon />
    </IconButton></Tooltip></div>
  }
}

CreateFeeReminderEmailButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(CreateFeeReminderEmailButton);
