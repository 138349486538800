// in src/students.js
import React from 'react';
import { TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import { Box, Typography, CardActions } from '@material-ui/core';
import FlatButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { Filter, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';
import { getYears } from './utils';


const SummaryFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Month" validate={required()} source="Month" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: '0', name: 'Jan' },
            { id: '1', name: 'Feb' },
            { id: '2', name: 'Mar' },
            { id: '3', name: 'Apr' },
            { id: '4', name: 'May' },
            { id: '5', name: 'Jun' },
            { id: '6', name: 'Jul' },
            { id: '7', name: 'Aug' },
            { id: '8', name: 'Sep' },
            { id: '9', name: 'Oct' },
            { id: '10', name: 'Nov' },
            { id: '11', name: 'Dec' },
        ]} />
        <SelectInput alwaysOn label="Year" validate={required()} source="Year" allowEmpty={false} choices={getYears()} />

    </Filter>
);

var d = new Date();
var mnth = d.getMonth();
var yr = d.getFullYear();
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
//console.log(mnth)

const DetailsPanel = ({ id, record, resource }) => {
    const tuitionAmt = record.TotalTuitionForMonthYear - record.TotalExtrHrsForMonthYear
    const diffTuitionAndIncoming = (record.TotalTuition - tuitionAmt).toFixed(2)
    const txt = "<div>Total Registration Paid (Filter Year): " + record.TotalRegPaidForYear + "</div>" +
        "<div>Total Registration Pending (Filter Year): " + record.TotalRegPendingForYear + "</div>" +
        "<div>Total tuition w/o extra hrs (Filter Month/Year): " + tuitionAmt + "</div>" +
        "<div>Diff in Tuition from today compared to Filter Month/Year: " + diffTuitionAndIncoming + "</div>"
    return (
        <div dangerouslySetInnerHTML={{ __html: txt }} />
    );
}

export const SummariesList = ({ permissions, ...props }) => {
    if (permissions !== undefined && permissions.role === 'admin') {
        return (
            <List filters={<SummaryFilter />} filterDefaultValues={{ Month: mnth, Year: yr }} bulkActionButtons={false} {...props} >
                <Datagrid expand={<DetailsPanel />}>
                    <TextField label="Total Students(Active+Pending)" source="TotalStudents" />
                    <TextField label="Total Active Students" source="TotalActiveStudents" />
                    <TextField label="Total Pending Students(Paid Fee)" source="TotalPendingStudents" />
                    <TextField label="Total Disenrolling Students" source="TotalDisenrollStudents" />
                    <ArrayField label="Total per Class" source="TotalStudentsPerClass">
                        <Datagrid>
                            <TextField label="Class" source="Name" />
                            <TextField label="No of Active Students" source="NumStuds" />
                            <TextField label="No of Pending Students(Paid)" source="NumPendingStuds" />
                            <TextField label="No of OnHold Students" source="NumOnHoldStuds" />
                            <TextField label="No of Diserolling Students" source="NumDisenrollStuds" />
                            <TextField label="Total Students" source="TotalStuds" />
                            <TextField label="No of Transitioning Students(Included in toddler totals)" source="NumTransitioning" />
                            <TextField label="Total Amount(Active+Pending-Disenroll)" source="Amt" />
                        </Datagrid>
                    </ArrayField>
                    <TextField label={"Total tuition for students(Active+Pending-Disenrolling)"} source="TotalTuition" />
                    <TextField label={"Total tuition for Active students"} source="TotalActiveTuition" />
                    <TextField label={"Total tuition for Pending students(Paid)"} source="TotalPendingTuition" />
                    <TextField label={"Total tuition for Disnerolling students"} source="TotalDisenrollTuition" />
                    <TextField label="Total extra Hrs for (Filter Month/Year)" source="TotalExtrHrsForMonthYear" />
                    <TextField label="Total Tuition Paid (Filter Month/Year)" source="TotalTuitionPaidForMonthYear" />
                    <TextField label="Total Tuition Pending (Filter Month/Year)" source="TotalTuitionPendingForMonthYear" />
                    {/* <TextField label="Total Registration Paid (Filter Year)" source="TotalRegPaidForYear" /> */}
                    {/* <TextField label="Total Registration Pending (Filter Year)" source="TotalRegPendingForYear" /> */}
                </Datagrid>
            </List>
        );
    } else if (permissions !== undefined && (permissions.role === 'officemgr')) {
        return (
            <List bulkActionButtons={false} {...props} >
                <Datagrid>
                    <TextField label="Total Students(Active+Pending)" source="TotalStudents" />
                    <TextField label="Total Active Students" source="TotalActiveStudents" />
                    <TextField label="Total Pending Students(Paid Fee)" source="TotalPendingStudents" />
                    <TextField label="Total Disenrolling Students" source="TotalDisenrollStudents" />
                    <ArrayField label="Total per Class" source="TotalStudentsPerClass">
                        <Datagrid>
                            <TextField label="Class" source="Name" />
                            <TextField label="No of Active Students" source="NumStuds" />
                            <TextField label="No of Pending Students(Paid)" source="NumPendingStuds" />
                            <TextField label="No of OnHold Students" source="NumOnHoldStuds" />
                            <TextField label="No of Diserolling Students" source="NumDisenrollStuds" />
                            <TextField label="Total Students" source="TotalStuds" />
                            <TextField label="No of Transitioning Students(Included in toddler totals)" source="NumTransitioning" />
                        </Datagrid>
                    </ArrayField>
                </Datagrid>
            </List>
        );
    }
};