import {
    AUTH_GET_PERMISSIONS,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_ERROR,
    AUTH_CHECK,
} from 'react-admin';
const jwt = require('jsonwebtoken');

var adminRole = {
    "role": "admin",
    "classroom": "All"
}
var class01Role = {
    "role": "teacher",
    "classroom": "Classroom 01"
}
var class03Role = {
    "role": "toddlerteacher",
    "classroom": "Classroom 03"
}
var class03AssistantRole = {
    "role": "toddlerassistant",
    "classroom": "Classroom 03"
}
var class04Role = {
    "role": "teacher",
    "classroom": "Classroom 04"
}
var officeMgrRole = {
    "role": "officemgr",
    "classroom": "All"
}
var defaultRole = {
    "role": "none",
    "classroom": ""
}

localStorage.setItem('not_authenticated', true)

export default (type, params) => {
    // console.log("In auth provider", type, params);
    // console.log(localStorage.getItem('not_authenticated'))
    if (type === AUTH_LOGIN) {
        const { user, token } = params;
        if (user != undefined) {
            var perm = "none"
            var classroom = "";
            var decode = jwt.decode(token);
            //   console.log("AFTER VERIFY permission",decode.permissions);
              if (decode.permissions.length == 2) {
              var key = decode.permissions[0].split(":");
              if (key[0]==="r") {
                perm=key[1];
              } else {
                classroom = key[1];
              }
              key = decode.permissions[1].split(":");
              if (key[0]==="c") {
                classroom = key[1];
              } else {
                perm=key[1];
              }
            }
            const role = JSON.stringify({
                'role': perm,
                'classroom': classroom
            });
            // console.log("rl",role);
            // if (user.email === 'director@lamsaz.com') {
            //     localStorage.removeItem('not_authenticated');
            //     localStorage.setItem("token", token);
            //     if (perm != "none") { //for backward compatability
            //     localStorage.setItem('role', role);
            //     } else {
            //         localStorage.setItem('role', JSON.stringify(adminRole));
            //     }
            //     return Promise.resolve();
            // } else if (user.email === "swati@lamsaz.com") {
            //     localStorage.removeItem('not_authenticated');
            //     localStorage.setItem("token", token);
            //     if (perm != "none") {
            //         localStorage.setItem('role', role);
            //         } else {
            //             localStorage.setItem('role', JSON.stringify(class01Role));
            //         }
            //     return Promise.resolve();
            // } else if (user.email === 'dulce@lamsaz.com') {
            //     localStorage.removeItem('not_authenticated');
            //     localStorage.setItem("token", token);
            //     if (perm != "none") {
            //         localStorage.setItem('role', role);
            //         } else {
            //             localStorage.setItem('role', JSON.stringify(class03Role));
            //         }
            //     return Promise.resolve();
            // } else if (user.email === "toddlerreport@lamsaz.com") {
            //     localStorage.removeItem('not_authenticated');
            //     localStorage.setItem("token", token);
            //     if (perm != "none") {
            //         localStorage.setItem('role', role);
            //         } else {
            //             localStorage.setItem('role', JSON.stringify(class03AssistantRole));
            //         }
            //     return Promise.resolve();
            // } else if (user.email === 'admin@lamsaz.com'){// || user.email === "mytestiamtestpass@gmail.com" || user.email === "director@lamsaz.com") {
            //     localStorage.removeItem('not_authenticated');
            //     localStorage.setItem("token", token);
            //     if (perm != "none") {
            //         localStorage.setItem('role', role);
            //         } else {
            //             localStorage.setItem('role', JSON.stringify(officeMgrRole));
            //         }
            //     return Promise.resolve();
            if (perm != "none") {
                localStorage.removeItem('not_authenticated');
                localStorage.setItem("token", token);
                localStorage.setItem('role', role);
                return Promise.resolve();
            } else {
                localStorage.removeItem('not_authenticated');
                localStorage.setItem('role', JSON.stringify(defaultRole));
                return Promise.resolve();
            }
        } else {
            localStorage.setItem('not_authenticated', true);
            return Promise.reject({ message: false });
        }

    }
    if (type === AUTH_LOGOUT) {
        localStorage.setItem('not_authenticated', true);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem('role');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401 || status === 403) {
            localStorage.setItem('not_authenticated', true);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            return Promise.reject({ message: false });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        // const role = JSON.parse(localStorage.getItem('role'));
        // console.log("In Auth check", role);
        // if (role == undefined || role == "")
        //     Promise.reject();
        // else
        //     Promise.resolve();
        return localStorage.getItem('not_authenticated')
            ? Promise.reject()
            : Promise.resolve({ message: false });
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const role = JSON.parse(localStorage.getItem('role'));
        // console.log("permission role", role)
        return Promise.resolve(role);
    }

    return Promise.reject('Unknown method');
}



