import React from 'react';

const TourURL = ({ record }) => {
    // console.log("applnurl ", record)
    if (record.TID != "") {
        var url = "/tours/TourDetails/"+record.TID+"#/tours/TourDetails/"+record.TID
        return (
            <a href={encodeURI(url)}>Tour Link</a>
        );
    } else {
                return (<div></div>);
            }
}

export default TourURL;