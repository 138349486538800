import { Box } from '@material-ui/core';
import { Labeled, RichTextField, Show, TextField } from 'react-admin';
import MyTab from './mytab.js';
import DisplaySignature from './dispsignature.js';


export const EmployeeGenReleaseShow = (props) => (
    <Show {...props}>
        <Box >
            <h3>Duties & Responsibilities</h3>
            <Box>
                <Box style={{ width: "100%" }}>
                        <RichTextField source="Terms" label="Duties & Responsibilities" multiline />
                        <Labeled label="Name">
                            <TextField source="Name" />
                        </Labeled><MyTab />
                        <Labeled label="Date" >
                            <TextField source="Date" />
                        </Labeled>
                        <Box style={{ width: "100%" }}>
                            <span>Signature:</span>
                            <DisplaySignature disabled label="Signature" source="Signature" />
                        </Box>
                </Box>
            </Box>
        </Box>
    </Show>
);