import React from 'react';
import { SelectField } from 'react-admin';
import { useRecordContext } from 'react-admin';
import MyTab from './mytab';

const LangSelection = (props) => {
    const record = useRecordContext(props);
    if (record != undefined) {
        // record.Child.Langs.Language.forEach(element => console.log(element));
        // var out = "<div>" + record.Child.Langs.Language.reduce((c, item, idx) => c += `<SelectField source="Child.Langs.Language[${idx}]" choices={[{ id: 'English', name: 'English' }, { id: 'Spanish', name: 'Spanish' }, { id: 'Other', name: 'Other' }]} />`, '') + "</div>";
        return (
            <div>
                <SelectField source="Child.Langs.Language[0]" choices={[{ id: 'English', name: 'English' }, { id: 'Spanish', name: 'Spanish' }, { id: 'Other', name: 'Other' }]} /><MyTab />
                <SelectField source="Child.Langs.Language[1]" choices={[{ id: 'English', name: 'English' }, { id: 'Spanish', name: 'Spanish' }, { id: 'Other', name: 'Other' }]} /><MyTab />
                <SelectField source="Child.Langs.Language[2]" choices={[{ id: 'English', name: 'English' }, { id: 'Spanish', name: 'Spanish' }, { id: 'Other', name: 'Other' }]} /><MyTab />
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export default LangSelection;