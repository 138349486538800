import { LabelList, ResponsiveContainer, ReferenceLine, LineChart, XAxis, YAxis, Legend, Tooltip, CartesianGrid, Line, BarChart, Bar } from 'recharts';
import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: 'row',
            [theme.breakpoints.down('lg')]: {
                width: "100%",
                display: "flex",
                flexDirection: 'column',
            }
        },
    };
});


export const Dashboard = ({ permissions, ...props }) => {
    const classes = useStyles();
    const [chartWidth, setChartWidth] = useState(["100%"]);
    useEffect(() => { let w = window.innerWidth; if (w >= 1000) setChartWidth(700); else setChartWidth("100%") }, [])
    const BAR_GAP = 4;
    const BAR_SIZE = 20;
    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchStats = async () => {
            try {
                const fetchURL = process.env.REACT_APP_STATS_URL
                const token = localStorage.getItem('token');
                const hdrs = new Headers({
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                })
                fetch(fetchURL, { method: 'GET', headers: hdrs })
                    .then(response => {
                        if (response.ok) {
                            response.json().then(json => {
                                if (json != "") {
                                    // console.log(json);
                                    setData(json)
                                } else {

                                }
                            });
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        this.props.showNotification('Error: fetching dashboard Stats', 'warning')
                    });

            } catch (error) {
                console.error('Error fetching dashboard Stats:', error);
            }
        };

        fetchStats();
    }, [dataProvider]);

    const valueAccessor = (entry) => {
        return entry ? (entry.TuitionFee + entry.RegistrationFee + entry.MaterialFee) : 0;
    };
    if (permissions !== undefined && permissions.role === 'admin') {
        return (
            <Box p="1em">
                <span style={{ textAlign: 'center' }}><h1>Welcome to LAMS Admin Dashboard</h1></span>
                <br /><br />
                <Box className={classes.container}>
                    <Box >
                        <h3>Number of Active Students per Month</h3>
                        <ResponsiveContainer width={chartWidth} height={300} >
                            <LineChart data={data.MStats}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Mnth" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line name="# active" type="monotone" dataKey="NumActive" stroke="#82ca9d" strokeWidth={5} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box p="2em"></Box>
                    <Box>
                        <h3>Number of Pending & Disenrolling Students</h3>
                        <ResponsiveContainer width={chartWidth} height={300}>
                            <BarChart data={data.MStats}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Mnth" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="NumDisenrolling" fill="orange" stackId="a" barSize={50} />
                                <Bar dataKey="NumPending" fill="lightgreen" stackId="a" barSize={50} />
                                <Bar dataKey="NumTransitioning" fill="lightblue" stackId="a" barSize={50} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box p="2em"></Box>
                    <Box>
                        <h3>Open Slots per Classroom</h3>
                        <ResponsiveContainer width={chartWidth} height={300}>
                            <BarChart data={data.CStats} barGap={BAR_GAP}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <XAxis dataKey="Mnth" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Legend />
                                <ReferenceLine y={0} stroke='#000' />
                                <Bar name="Class 01" type="monotone" dataKey="OSlots.Classroom 01" fill="magenta" barSize={BAR_SIZE} transform={`translate(${4}, 0)`} />
                                <Bar name="Class 02" type="monotone" dataKey="OSlots.Classroom 02" fill="purple" barSize={BAR_SIZE} transform={`translate(${2}, 0)`} />
                                <Bar name="Class 03" type="monotone" dataKey="OSlots.Classroom 03" fill="red" barSize={BAR_SIZE} transform={`translate(${1}, 0)`} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
                <Box className={classes.container}>
                    <Box>
                        <h3>Classroom Total (Active+Pending+Transition-Disenroll)</h3>
                        <ResponsiveContainer width={chartWidth} height={300}>
                            <BarChart data={data.CStats} barGap={BAR_GAP}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <XAxis dataKey="Mnth" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Legend />
                                <ReferenceLine y={0} stroke='#000' />
                                <Bar name="Class 01" type="monotone" dataKey="Active.Classroom 01" fill="green" barSize={BAR_SIZE} transform={`translate(${4}, 0)`} />
                                <Bar name="Class 02" type="monotone" dataKey="Active.Classroom 02" fill="lightgreen" barSize={BAR_SIZE} transform={`translate(${2}, 0)`} />
                                <Bar name="Class 03" type="monotone" dataKey="Active.Classroom 03" fill="teal" barSize={BAR_SIZE} transform={`translate(${1}, 0)`} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box p="2em"></Box>
                    <Box>
                        <h3>Classroom Pending</h3>
                        <ResponsiveContainer width={chartWidth} height={300}>
                            <BarChart data={data.CStats} barGap={BAR_GAP}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <XAxis dataKey="Mnth" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Legend />
                                <ReferenceLine y={0} stroke='#000' />
                                <Bar name="Class 01" type="monotone" dataKey="Pending.Classroom 01" fill="blue" barSize={BAR_SIZE} transform={`translate(${4}, 0)`} />
                                <Bar name="Class 02" type="monotone" dataKey="Pending.Classroom 02" fill="lightblue" barSize={BAR_SIZE} transform={`translate(${2}, 0)`} />
                                <Bar name="Class 03" type="monotone" dataKey="Pending.Classroom 03" fill="indigo" barSize={BAR_SIZE} transform={`translate(${1}, 0)`} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box p="2em"></Box>
                    <Box>
                        <h3>Classroom Disenrolling</h3>
                        <ResponsiveContainer width={chartWidth} height={300}>
                            <BarChart data={data.CStats} barGap={BAR_GAP}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <XAxis dataKey="Mnth" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Legend />
                                <ReferenceLine y={0} stroke='#000' />
                                <Bar name="Class 01" type="monotone" dataKey="Disenroll.Classroom 01" fill="red" barSize={BAR_SIZE} transform={`translate(${4}, 0)`} />
                                <Bar name="Class 02" type="monotone" dataKey="Disenroll.Classroom 02" fill="orange" barSize={BAR_SIZE} transform={`translate(${2}, 0)`} />
                                <Bar name="Class 03" type="monotone" dataKey="Disenroll.Classroom 03" fill="coral" barSize={BAR_SIZE} transform={`translate(${1}, 0)`} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
                <Box className={classes.container}>
                    <Box>
                        <h3>Estimated income/expense - Fees, Salary & Work Hours <br />(Does not include Extra Hours, Late/Other Fee, Employee Taxes, Benefits)</h3>
                        <ResponsiveContainer width={chartWidth} height={300}>
                            <BarChart data={data.MStats}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Mnth" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="TuitionFee" fill="green" stackId="a" barSize={50} />
                                <Bar dataKey="RegistrationFee" fill="blue" stackId="a" barSize={50} />
                                <Bar dataKey="MaterialFee" fill="orange" stackId="a" barSize={50} />
                                <Bar name="TotalFee" stackId="a">
                                    <LabelList position="top" valueAccessor={valueAccessor} />
                                </Bar>
                                <Bar name="Total Salary" type="monotone" dataKey="TotalEmpSalary" fill="red" barSize={50} transform={`translate(${4}, 0)`} />
                                <Bar name="Work Hours" type="monotone" dataKey="TotalEmpHours" fill="purple" barSize={50} transform={`translate(${2}, 0)`} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
            </Box>
        );
    } else if (permissions !== undefined && permissions.role === 'officemgr') {
        return (
            <Box p="1em">
                <span style={{ textAlign: 'center' }}><h1>Welcome to LAMS Admin Dashboard</h1></span>
                <br /><br />
                <Box className={classes.container}>
                    <Box>
                        <h3>Number of Pending & Disenrolling Students</h3>
                        <ResponsiveContainer width={chartWidth} height={300}>
                            <BarChart data={data.MStats}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Mnth" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="NumDisenrolling" fill="orange" stackId="a" barSize={50} />
                                <Bar dataKey="NumPending" fill="lightgreen" stackId="a" barSize={50} />
                                <Bar dataKey="NumTransitioning" fill="lightblue" stackId="a" barSize={50} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box>
                        <h3>Open Slots per Classroom</h3>
                        <ResponsiveContainer width={chartWidth} height={300}>
                            <BarChart data={data.CStats} barGap={BAR_GAP}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                <XAxis dataKey="Mnth" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Legend />
                                <ReferenceLine y={0} stroke='#000' />
                                <Bar name="Class 01" type="monotone" dataKey="OSlots.Classroom 01" fill="magenta" barSize={BAR_SIZE} transform={`translate(${4}, 0)`} />
                                <Bar name="Class 02" type="monotone" dataKey="OSlots.Classroom 02" fill="purple" barSize={BAR_SIZE} transform={`translate(${2}, 0)`} />
                                <Bar name="Class 03" type="monotone" dataKey="OSlots.Classroom 03" fill="red" barSize={BAR_SIZE} transform={`translate(${1}, 0)`} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
            </Box>
        );
    } else {
        if (permissions !== undefined && permissions.role === 'teacher') {
            return (
                <Box p="1em">
                    <span style={{ textAlign: 'center' }}><h1>Welcome to LAMS Admin Dashboard</h1></span>
                    <br /><br />
                    <Box className={classes.container}>
                        <Box>
                            <h3>Open Slots per Classroom</h3>
                            <ResponsiveContainer width={chartWidth} height={300}>
                                <BarChart data={data.CStats} barGap={BAR_GAP}
                                    margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                    <XAxis dataKey="Mnth" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Legend />
                                    <ReferenceLine y={0} stroke='#000' />
                                    <Bar name={permissions.classroom} type="monotone" dataKey={"OSlots." + permissions.classroom} fill="magenta" barSize={BAR_SIZE} transform={`translate(${4}, 0)`} />
                                </BarChart>
                            </ResponsiveContainer>
                        </Box>
                    </Box>
                </Box>
            );
        } else {
            return (
                <div>
                    <span style={{ textAlign: 'center' }}><h1>Welcome to LAMS Admin Dashboard</h1></span>
                </div>
            );
        }
    }
};
export default Dashboard;