// in src/students.js
import React from 'react';
import TuitionReceiptButton from './TuitionReceiptButton';
import AnnualReportButton from './AnnualReportButton';
import RegReceiptButton from './RegReceiptButton';
import MaterialReceiptButton from './MaterialReceiptButton';
import LateFeeInvoiceButton from './LateFeeInvoiceButton';
import GenericInvoiceButton from './GenericInvoiceButton';
import CreateDraftEmailButton from './CreateDraftEmailButton';
import SendWelcomeEmailButton from './SendWelcomeEmailButton';
import {ExportButton, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import { Box, Typography, CardActions } from '@material-ui/core';
import FlatButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { SelectArrayInput, RadioButtonGroupInput, Filter, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';
import ExtraHrsButton from './ExtraHrsButton';
import CashSummaryButton from './CashSummaryButton';
import GenerateRCsButton from './GenerateRCsButton';
import { useMediaQuery } from '@material-ui/core';
import StudentName from './studentName.js';
import SignInButton from './SignInButton.js';
import SignOutButton from './SignOutButton.js';
import UpdateTRCommonData from './UpdateTRCommonData';
import ApprovalEmailButton from './ApprovalEmailButton';
import MyTab from './mytab';
import './index.css';
import ApplnURL from './applnurl';


const validateName = [required(), minLength(2), maxLength(45)];
const validateEmail = [required(), email()];
const validatePIN = [required(), regex(/[0-9][0-9][0-9][0-9]/, "Must be 4 digit number")];
const validatePrg = [required()];
// const validateDate = [required(), regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDate = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDateOpt = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateClass = [required()];
const validateSex = [required()];
const validateEStatus = [required()];

// console.log("Env:" + process.env.NODE_ENV)
const Aside = ({ data, ids }) => {
    // console.log(data, ids);
    var numstuds = ids.map(id => data[id]).reduce((numstuds, rec) => {
        return numstuds + 1
    }, 0);
    var tottut = ids.map(id => data[id]).reduce((tottut, rec) => {
        if (rec.Tuition != undefined) {
            var tut = parseFloat(rec.Tuition)
            if (tut != undefined || !isNaN(tut))
                return tottut + tut
        } else {
            return tottut
        }
    }, 0);
    return (
        <div style={{ width: 200, margin: '1em' }} >
            <Typography >Details:</Typography>
            <Typography variant="body1">
                {/* {ids.map(id => data[id]).reduce((sum, rec) => {
                    if (rec.EHrs.ExtraAmount) {
                        return sum + parseFloat(rec.EHrs.ExtraAmount);
                    } else {
                        return sum;
                    }
                }, 0.0)} */}
                <br />
                <Typography ># of students</Typography>
                {numstuds}
                <br />
                <Typography >Total Tuition</Typography>
                ${tottut}
            </Typography>
        </div >
    );
}

const ActionsRefresh = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate = false, // you can hide CreateButton if hasCreate = false
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => (
    <CardActions>
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
    </CardActions>
);
const feStatuses = [
    { id: 'All', name: 'All' },
    { id: 'Active', name: 'Active' },
    { id: 'Graduated', name: 'Graduated' },
    { id: 'On Hold', name: 'On Hold' },
    { id: 'Pending', name: 'Pending' },
    { id: 'Disenrolled', name: 'Disenrolled' },
    { id: 'No Response', name: 'No Response' },
    { id: 'Declined', name: 'Declined' },
];

const OFMfeStatuses = [
    { id: 'Active', name: 'Active' },
    { id: 'On Hold', name: 'On Hold' },
    { id: 'Pending', name: 'Pending' },
];

const TfeStatuses = [
    { id: 'Active', name: 'Active' },
];

const StudentFilter = (props) => (
    <Filter {...props}>
        <SelectArrayInput style={{ width: 200 }} alwaysOn label="Enrollment Status" source="FEStatus" validate={validateEStatus} allowEmpty={false} choices={feStatuses} />
        <SelectInput alwaysOn label="Classroom" source="Classroom" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Classroom 01', name: 'Classroom 01' },
            { id: 'Classroom 02', name: 'Classroom 02' },
            { id: 'Classroom 03', name: 'Classroom 03' },
            { id: 'Classroom 04', name: 'Classroom 04' },
        ]} />
        <SelectInput alwaysOn label="Program" source="Program" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Primary', name: 'Primary' },
            { id: 'Toddler', name: 'Toddler' },
        ]} />
    </Filter>
);
const TeacherFilter = (props) => (
    <Filter {...props}>
        <SelectArrayInput style={{ width: 200 }} alwaysOn label="Enrollment Status" source="FEStatus" validate={validateEStatus} allowEmpty={false} choices={TfeStatuses} />
    </Filter>
);
const OfficeMgrFilter = (props) => (
    <Filter {...props}>
        <SelectArrayInput style={{ width: 200 }} alwaysOn label="Enrollment Status" source="FEStatus" validate={validateEStatus} allowEmpty={false} choices={OFMfeStatuses} />
        <SelectInput alwaysOn label="Classroom" source="Classroom" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Classroom 01', name: 'Classroom 01' },
            { id: 'Classroom 02', name: 'Classroom 02' },
            { id: 'Classroom 03', name: 'Classroom 03' },
            { id: 'Classroom 04', name: 'Classroom 04' },
        ]} />
    </Filter>
);

const DetailsPanel = ({ id, record, resource }) => {
    const txt = "<div>Sex: " + record.Sex + "</div>" +
        "<div>Date of Birth: " + record.Sdob + "</div>" +
        "<div>Date of Joining: " + record.Sdoj + "</div>" +
        "<div>Discount: " + record.Discount + "</div>" +
        "<div>Material Fee Paid: " + record.MaterialFeePaid + "</div>" +
        "<div>Welcome Email Sent: " + record.WelcomeEmailSent + "</div>"
    return (
        <div dangerouslySetInnerHTML={{ __html: txt }} />
    );
}

const ChildAge = ({ record }) => {
    // var from = record.Sdob.split("/");
    // var oneDay = 24 * 60 * 60 * 1000;
    // var bdate = new Date(from[2], from[0] - 1, from[1]);
    // var diff = Date.now() - bdate.getTime();
    // var age = (diff / oneDay) / 365
    // return <span>{age.toFixed(1)}</span>
    var birthday=record.Sdob.split("/");
    var dobMonth= birthday[0]; 
var dobDay= birthday[1];
var dobYear= birthday[2];
var now = new Date();
var nowDay= now.getDate();
var nowMonth = now.getMonth() + 1;  //jan = 0 so month + 1
var nowYear= now.getFullYear();

var ageyear = nowYear - dobYear;
var agemonth = nowMonth - dobMonth;
var ageday = nowDay- dobDay;
// console.log(ageyear,agemonth,ageday)
if (agemonth < 0) {
       ageyear--;
       agemonth = (12 + agemonth);
        }
if (nowDay < dobDay) {
      agemonth--;
      ageday = 30 + ageday;
      }
var val = ageyear + "yrs, " + agemonth + "mnth(s)";
return <span>{val}</span>
};

const PDtls = ({ record }) => {
    var pnm = record.PName;
    var tel = record.Tel;
    return <span>{pnm}&nbsp;&nbsp;{tel}</span>
};

export const StudentsList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions !== undefined && permissions.role === 'admin') {
        return (
            <List  {...props} aside={<Aside />} filters={<StudentFilter />} filterDefaultValues={{ FEStatus:["Active"], Classroom: "All" }} bulkActionButtons={<StudentListActions {...props} />} sort={{ field: 'Sname', order: 'ASC' }} >
                {isSmall ? (
                    <Datagrid>
                        {/* <TextField disabled label="id" source="id" /> */}
                        <StudentName label="Student Name" status="Status" />
                        <TextField label="Balance" source="Balance" />
                        <TextField label="Date of Joining" source="Sdoj" />
                        <TextField label="Program" source="Program" />
                        <ArrayField label="Phone" source="Parents" sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="Tel" />
                            </SingleFieldList>
                        </ArrayField>
                        {/* <TextField label="Enrollment Status" source="EStatus" /> */}
                        <TextField label="Use Of Media Approval" source="UseofMediaApproval" />
                        <TextField label="Tuition" source="Tuition" />
                        <TextField label="Discount" source="Discount" />
                        <TextField label="PIN" source="PIN" />
                        <TextField label="Material Fee Paid" source="MaterialFeePaid" sortable={false} />
                        <ArrayField label="Reg Fee Paid" source="RegistrationFee" sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="RegYear" />
                            </SingleFieldList>
                        </ArrayField>
                        <EditButton />
                    </Datagrid>
                ) : (
                    <Datagrid className="txtCopy" expand={<DetailsPanel />}>
                        <StudentName label="Student Name" status="Status" sortable={true} sortBy="Sname"/>
                        <TextField label="Balance" source="Balance" />
                        <TextField  label="UserID" source="UserID" />
                        <ChildAge label="Age" />
                        <TextField label="Class" source="Classroom" />
                        <TextField label="Date of Joining" source="Sdoj" />
                        <TextField label="Enrollment Status" source="EStatus" />
                        <TextField label="Transitioning" source="Transitioning" />
                        <TextField label="Grad/DisEnroll Dt" source="GraduationDate" />
                        <TextField label="Use Of Media Approval" source="UseofMediaApproval" />
                        <TextField label="Program" source="Program" />
                        <TextField label="Fee" source="Tuition" sortable={false} />
                        <TextField label="Discount" source="Discount" />
                        <TextField label="PIN" source="PIN" />
                        <ArrayField label="Siblings" source="EnrolledSiblings" sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="Name" />
                            </SingleFieldList>
                        </ArrayField>
                        <ArrayField source="Parents" sortable={false}>
                            <Datagrid>
                                <PDtls />
                            </Datagrid>
                        </ArrayField>
                        <ArrayField label="Email" source="Parents" sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="Email" />
                            </SingleFieldList>
                        </ArrayField>
                        <ArrayField label="Reg Fee Paid" source="RegistrationFee" sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="RegYear" />
                            </SingleFieldList>
                        </ArrayField>
                        <TextField label="Notes" source="Notes" />
                        <ApplnURL />
                        {permissions !== undefined && permissions.role === 'admin' &&
                            <EditButton />}

                    </Datagrid>)}
            </List>
        );
    } else if (permissions !== undefined && (permissions.role === 'teacher' || permissions.role === 'toddlerteacher' || permissions.role === 'toddlerassistant')) {
        return (
            <List  {...props} filter={{ Classroom: permissions !== undefined && permissions.classroom }} filters={<TeacherFilter />} filterDefaultValues={{ FEStatus:["Active"] }} bulkActionButtons={< TeacherListActions {...props} cl={permissions !== undefined && permissions.classroom} />} actions={<ActionsRefresh {...props} />} >
                {
                    isSmall ? (
                        <Datagrid>
                            {/* <TextField disabled label="id" source="id" /> */}
                            {/* < TextField label="Student Name" source="Sname" /> */}
                            <StudentName label="Student Name" status="Status" />
                            <TextField label="Use Of Media Approval" source="UseofMediaApproval" />
                            <TextField label="Date of Birth" source="Sdob" />
                            <ArrayField label="Email" source="Parents" sortable={false}>
                                <SingleFieldList linkType={false}>
                                    <ChipField source="Email" />
                                </SingleFieldList>
                            </ArrayField>
                        </Datagrid >
                    ) : (
                        <Datagrid >
                            <TextField label="Student Name" source="Sname" />
                            <TextField label="Use Of Media Approval" source="UseofMediaApproval" />
                            <TextField label="Date of Birth" source="Sdob" />
                            <TextField label="Transitioning" source="Transitioning" />
                            {/* <ArrayField label="Phone" source="Parents" sortable={false}>
                                <SingleFieldList linkType={false}>
                                    <ChipField source="Tel" />
                                </SingleFieldList>
                            </ArrayField>
                            <TextField label="Class" source="Classroom" />
                            <TextField label="Sex" source="Sex" />
                            <TextField label="Date of Birth" source="Sdob" />
                            <TextField label="Date of Joining" source="Sdoj" />
                            <TextField label="Enrollment Status" source="EStatus" />
                            <TextField label="Program" source="Program" />
                            <TextField label="ImmunizationStatus" source="ImmunizationStatus" /> */}
                            {/* <ArrayField source="Parents" sortable={false}>
                                <SingleFieldList linkType={false}>
                                    <ChipField source="PName" />
                                </SingleFieldList>
                            </ArrayField> */}
                            <ArrayField label="Email" source="Parents" sortable={false}>
                                <SingleFieldList linkType={false}>
                                    <ChipField source="Email" />
                                </SingleFieldList>
                            </ArrayField>
                            <ApplnURL />
                        </Datagrid>)}
            </List >

        );
    } else if (permissions !== undefined && (permissions.role === 'officemgr')) {
        return (
            <List  {...props}  filters={<OfficeMgrFilter />} filterDefaultValues={{ FEStatus:["Active"], Classroom: "All" }} bulkActionButtons={< OfficeMgrListActions {...props} /*cl={permissions !== undefined && permissions.classroom} *//>} actions={<ActionsRefresh {...props} />} >
                {
                    (
                        <Datagrid >
                            {/* <TextField label="Student Name" source="Sname" /> */}
                            <StudentName label="Student Name" status="Status" />
                            <TextField label="Balance" source="Balance" />
                            <TextField label="Class" source="Classroom" />
                            <TextField label="Transitioning" source="Transitioning" />
                            <TextField label="Grad/DisEnroll Dt" source="GraduationDate" />
                            <TextField label="Use Of Media Approval" source="UseofMediaApproval" />                            
                            <TextField label="Date of Birth" source="Sdob" />
                            <ChildAge label="Age"/>
                            <TextField label="Date of Joining" source="Sdoj" />
                            <TextField label="PIN" source="PIN" />
                            <ArrayField label="Siblings" source="EnrolledSiblings" sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="Name" />
                            </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="Parents" label="Phone" sortable={false}>
                                <Datagrid>
                                    <PDtls />
                                </Datagrid>
                            </ArrayField>
                            <ArrayField label="Email" source="Parents" sortable={false}>
                                <SingleFieldList linkType={false}>
                                    <ChipField source="Email" />
                                </SingleFieldList>
                            </ArrayField>
                            <TextField label="Material Fee Paid" source="MaterialFeePaid" />
                            <ArrayField label="Reg Fee Paid" source="RegistrationFee" sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="RegYear" />
                            </SingleFieldList>
                        </ArrayField>
                        <ApplnURL />
                        </Datagrid>)}
            </List >

        );
    } else {
        return (<span>User has No Access...</span>)
    }
};

const StudentTitle = ({ record }) => {
    return <span>Student {record ? `"${record.id}"` : ''}</span>;
};

const TransitionInput = ({ record }) => {
    return (
        /*record.Program.includes("Toddler") &&*/ <SelectInput label="Transitioning" source="Transitioning" choices={[
            { id: 'Classroom 01', name: 'Classroom 01' },
            { id: 'Classroom 02', name: 'Classroom 02' },
            { id: '', name: 'Blank' },
        ]} />
    );
};

export const StudentEdit = (props) => {
return (
    <Edit title={<StudentTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput  label="UserID" source="UserID" />
            <TextInput label="Notes" source="Notes" />
            <TextInput label="Student Name" source="Sname" validate={validateName} />
            <TextInput label="Balance" source="Balance"/>
            <SelectInput source="Sex" validate={validateSex} choices={[
                { id: 'M', name: 'M' },
                { id: 'F', name: 'F' },
            ]} />

            <TextInput label="Date of Birth" source="Sdob" validate={validateDate} />
            <TextInput label="Date of Joining" source="Sdoj" validate={validateDate} />
            <TextInput label="Welcome Email Sent:" source="WelcomeEmailSent" />

            <SelectInput label="Enrollment Status" source="EStatus" validate={validateEStatus} choices={[
                { id: 'Active', name: 'Active' },
                { id: 'Graduated', name: 'Graduated' },
                { id: 'On Hold', name: 'On Hold' },
                { id: 'Pending', name: 'Pending' },
                { id: 'Disenrolled', name: 'Disenrolled' },
                { id: 'No Response', name: 'No Response' },
                { id: 'Declined', name: 'Declined' },
            ]} />
            <TransitionInput {...props}/>
            <SelectInput source="Classroom" validate={validateClass} choices={[
                { id: 'Classroom 01', name: 'Classroom 01' },
                { id: 'Classroom 02', name: 'Classroom 02' },
                { id: 'Classroom 03', name: 'Classroom 03' },
                { id: 'Classroom 04', name: 'Classroom 04' },
            ]} />
            <TextInput label="Graduation Date" source="GraduationDate" validate={validateDateOpt} />
            <SelectInput source="Program" choices={[
                { id: 'Primary - HalfDay', name: 'Primary - HalfDay' },
                { id: 'Primary - FullDay', name: 'Primary - FullDay' },
                { id: 'Primary - ExtendedDay', name: 'Primary - ExtendedDay' },
                { id: 'Toddler - HalfDay', name: 'Toddler - HalfDay' },
                { id: 'Toddler - FullDay', name: 'Toddler - FullDay' },
                { id: 'Toddler - ExtendedDay', name: 'Toddler - ExtendedDay' },
            ]} />
            <TextInput label="Fee" source="Tuition" />
            <RadioButtonGroupInput source="Discount" choices={[
                { id: 'SD', name: 'Sibling Discount' },
                { id: 'ED', name: 'Employee Discount' },
                { id: 'ND', name: 'None' },
            ]} />
            <TextInput label="Material Fee" source="MaterialFeePaid" choices={[
                { id: 'Y', name: 'Y' },
                { id: 'N', name: 'N' },
            ]} />
            <TextInput label="PIN" source="PIN" validate={validatePIN} />
            <TextInput label="Address" source="SAddr" />
            <SelectInput source="ImmunizationStatus" choices={[
                { id: 'Complete', name: 'Complete' },
                { id: 'Not Provided', name: 'Not Provided' },
                { id: 'Exemption', name: 'Exemption' },
            ]} />
            <ArrayInput label="Reg Fee Paid" source="RegistrationFee" >
                <SimpleFormIterator>
                    <TextInput label="Registration Year" source="RegYear" />
                </SimpleFormIterator>
            </ArrayInput>
            <RadioButtonGroupInput label="Use of Media Approval" source="UseofMediaApproval" choices={[
                { id: 'Y', name: 'Yes' },
                { id: 'N', name: 'No' }

            ]} validate={required()} />
            <ArrayInput source="Parents" validate={required()}>
                <SimpleFormIterator>
                    <Typography variant="h6" gutterBottom>Parent</Typography>
                    <TextInput label="Parent Name" source="PName" validate={validateName} />
                    <TextInput label="Address" source="Address" />
                    <TextInput label="Phone" source="Tel" />
                    <TextInput label="Email" source="Email" validate={validateEmail} />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="EnrolledSiblings">
                <SimpleFormIterator>
                    <Typography variant="h6" gutterBottom>Siblings</Typography>
                    <TextInput label="Sibling ID" source="RefSID" />
                    <TextInput label="Sibling Name" source="Name" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);
        }

export const StudentCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box p="1em">
                <Box display="flex">
                    <Box >
                        <Typography variant="h6" gutterBottom>Student Info</Typography>
                        <Box display="flex">
                        <Box mr="2em">
                                <TextInput label="Notes" source="Notes" />
                            </Box>
                            <Box mr="2em">
                                <TextInput label="Student Name" source="Sname" validate={validateName} />
                            </Box>
                            <Box  >
                                <SelectInput source="Sex" validate={validateSex} choices={[
                                    { id: 'M', name: 'M' },
                                    { id: 'F', name: 'F' },
                                ]} />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box mr="2em" >
                                <TextInput label="Date of Birth" source="Sdob" validate={validateDate} />
                            </Box>
                            <Box >
                                <TextInput label="Date of Joining" source="Sdoj" validate={validateDate} />
                            </Box>

                        </Box>
                        <Box display="flex">
                            <Box mr="6em" >
                                <SelectInput source="Classroom" validate={validateClass} choices={[
                                    { id: 'Classroom 01', name: 'Classroom 01' },
                                    { id: 'Classroom 02', name: 'Classroom 02' },
                                    { id: 'Classroom 03', name: 'Classroom 03' },
                                    { id: 'Classroom 04', name: 'Classroom 04' },
                                ]} />
                            </Box>
                            <Box>
                                <SelectInput source="Program" validate={validatePrg} choices={[
                                    { id: 'Primary - HalfDay', name: 'Primary - HalfDay' },
                                    { id: 'Primary - FullDay', name: 'Primary - FullDay' },
                                    { id: 'Primary - ExtendedDay', name: 'Primary - ExtendedDay' },
                                    { id: 'Toddler - HalfDay', name: 'Toddler - HalfDay' },
                                    { id: 'Toddler - FullDay', name: 'Toddler - FullDay' },
                                    { id: 'Toddler - ExtendedDay', name: 'Toddler - ExtendedDay' },
                                ]} /></Box>
                            <Box>
                                <RadioButtonGroupInput source="Discount" choices={[
                                    { id: 'SD', name: 'Sibling Discount' },
                                    { id: 'ED', name: 'Employee Discount' },
                                    { id: 'ND', name: 'None' },
                                ]} />
                            </Box>
                        </Box>
                        <Box display="flex">
                            {/* <Box mr="2em" >
                                <TextInput label="PIN" source="PIN" validate={validatePIN} />
                            </Box> */}
                            <Box>
                                <TextInput label="Address" source="SAddr" />
                            </Box>
                            <Box>
                                <SelectInput source="ImmunizationStatus" choices={[
                                    { id: 'Complete', name: 'Complete' },
                                    { id: 'Not Provided', name: 'Not Provided' },
                                    { id: 'Exemption', name: 'Exemption' },
                                ]} />
                            </Box>
                            <Box>
                                <RadioButtonGroupInput label="Use of Media Approval" source="UseofMediaApproval" choices={[
                                    { id: 'Y', name: 'Yes' },
                                    { id: 'N', name: 'No' }

                                ]} validate={required()} />
                            </Box>
                        </Box>
                    </Box>
                    <Box ml="1em">
                        <ArrayInput source="Parents" validate={required()}>
                            <SimpleFormIterator>
                                <Typography variant="h6" gutterBottom>Parent</Typography>
                                {/* <Box display="flex">
                                    <Box mr="2em" > */}
                                <TextInput label="Parent Name" source="PName" validate={validateName} />
                                {/* </Box>
                                    <Box > */}
                                <TextInput label="Address" source="Address" />
                                {/* </Box>

                                </Box>
                                <Box display="flex">
                                    <Box mr="2em"> */}
                                <TextInput label="Phone" source="Tel" />
                                {/* </Box>
                                    <Box > */}
                                <TextInput label="Email" source="Email" validate={validateEmail} />
                                {/* </Box>

                                </Box> */}
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </Box>
            </Box>
        </SimpleForm>
    </Create>
);



const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const StudentListActions = props => {
    // console.log("In student buttons ", props.filterValues.Classroom);
    let cl = props.filterValues.Classroom;
    let st = props.filterValues.FEStatus;
    // console.log("FESTS",st);
    return (
        <TopToolbar>
            <BulkDeleteButton {...props} undoable="true" /><MyTab /><MyTab /><MyTab /><MyTab />
            {(st != undefined && (st == "Active" || st == "Pending")) &&
                <SendWelcomeEmailButton {...props} />}
            {(st != undefined && st == "Pending") &&
                <ApprovalEmailButton {...props} />}
            <CreateDraftEmailButton {...props} />
            {(st != undefined && (st == "Active" || st == "Pending")) &&
                <TuitionReceiptButton {...props} />}
            {(st != undefined && (st == "Active" || st == "Pending")) &&
                <RegReceiptButton {...props} />}
            {(st != undefined && (st == "Active" || st == "Pending")) &&
                <MaterialReceiptButton {...props} />}
            {(st != undefined && (st == "Active" )) &&
                <LateFeeInvoiceButton {...props} />}
            {(st != undefined && (st == "Active" || st == "Pending" )) &&
                <GenericInvoiceButton {...props} />}
            {(st != undefined && (st != "Pending")) &&
                <AnnualReportButton {...props} />}
            <CashSummaryButton {...props} />
            {(st != undefined && st == "Active") &&
                <GenerateRCsButton {...props} />}
            {(st != undefined && st == "Active") &&
                <SignInButton {...props} />}
            {(st != undefined && st == "Active") &&
                <SignOutButton {...props} />}
            {(cl != undefined && (cl == "Classroom 03" || cl == "Classroom 04")) && (st != undefined && st == "Active") &&
                <UpdateTRCommonData {...props} />}
        </TopToolbar>
    );
}

const TeacherListActions = (props) => {
    // console.log("In student buttons ", props);
    let st = props.filterValues.FEStatus;
    let cl = props.cl;
    console.log("Classroom filter ", cl, st);
    return (
        <TopToolbar>
            {st != undefined && st == "Active" && <GenerateRCsButton {...props} />}
            {(cl != undefined && (cl == "Classroom 03" || cl == "Classroom 04")) && (st != undefined && st == "Active") && <UpdateTRCommonData {...props} />}
        </TopToolbar>
    );
}

const OfficeMgrListActions = (props) => {
    // console.log("In student buttons ", props);
    // let st = props.filterValues.EStatus;
    // let cl = props.cl;
    // console.log("Classroom filter ", cl, st);
    return (
        <TopToolbar>
            <CreateDraftEmailButton {...props} />
        </TopToolbar>
    );
}
