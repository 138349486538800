import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import Tooltip from '@material-ui/core/Tooltip';


class SendWelcomeEmailButton extends Component {
  handleClick = () => {
    //console.log("in gen receipt" + process.env.REACT_APP_WELCOMEEMAIL_URL);
    const fetchURL = process.env.REACT_APP_WELCOMEEMAIL_URL
    //console.log(fetchURL)
    const { push, selectedIds, showNotification } = this.props;
    //console.log(this.props);
    //console.log("sending " + selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      // "Content-Length": JSON.stringify(body).length
    })
    const bdy = JSON.stringify(selectedIds);
    // const updatedRecord = { ...record, is_approved: true };
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              showNotification("Email generated");
              push('/Students');

            } else {
              showNotification("Email generated");
              push('/Students');
            }
          });
        } else if (response.status === '401' ) {
          showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              showNotification("Unable to generate for " + json.message);

            } else {
              showNotification("Error generating Email(s)");
            }
          });
        }
      })
      .catch((e) => {
        //console.error(e);
        showNotification('Error: sending welcome email', 'warning')
      });
  }

  render() {
    return <div><Tooltip title="Welcome Email"><IconButton color="primary" component="span" onClick={this.handleClick}>
      <EmailIcon />
    </IconButton></Tooltip></div>
    // <Button variant="contained" color="primary" component="span" onClick={this.handleClick}>Send Welcome Email</Button>;
  }
}

SendWelcomeEmailButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(SendWelcomeEmailButton);
