import { showNotification as showNotificationAction } from 'react-admin';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import Tooltip from '@material-ui/core/Tooltip';

class ExtraHrsButton extends Component {


  handleClick = () => {
    // console.log("in reg receipt");
    const fetchURL = process.env.REACT_APP_EXTRAHRS_URL
    const { push, selectedIds, showNotification } = this.props;
    // console.log(this.props);
    // console.log("sending " + selectedIds);
    const bdy = JSON.stringify(selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "Content-Length": JSON.stringify(bdy).length
    })

    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            // console.log(response, json);
            if (json != "") {
              showNotification("Receipts generated");
              push('/Receipts');

            } else {
              showNotification("Receipts generated");
              push('/Receipts');
            }
          });
        } else if (response.status === '401' ) {
          showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            // console.log(response, json);
            if (json != "") {
              showNotification("Unable to generate for " + json.message);

            } else {
              showNotification("Error generating receipt(s)");
            }
          });
        }
      })
      .catch((e) => {
        console.error(e);
        showNotification('Error: generating extra hr reminders', 'warning')
      });
  }

  render() {
    return <div><Tooltip title="Tuition Invoice + Extra Hrs Email"><IconButton color="primary" component="span" onClick={this.handleClick}>
      <EmailIcon style={{ color: "orange" }} />
    </IconButton></Tooltip></div>
    // <Button onClick={this.handleClick}>Gen ExtraHrs Reminders</Button>;
  }
}

ExtraHrsButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(ExtraHrsButton);