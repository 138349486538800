import React from 'react';
import { BooleanField } from 'react-admin';


const ExcusedAbsence = ({ record }) => {
    if (record != undefined) {

        if (record.Type === "late-dropoff") {
            return (
                <BooleanField label="Excused Absence" source="Excused" />
            );
        } else {
            return ("");
        }
        
    } else {
        return ("");
    }
}

export default ExcusedAbsence;
