import React from 'react';
import { TextField } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    greendot: {
        height: '5px',
        width: '5px',
        backgroundColor: 'green',
        borderRadius: '50%',
        display: 'inline-block'
    },
    reddot: {
        height: '5px',
        width: '5px',
        backgroundColor: 'red',
        borderRadius: '50%',
        display: 'inline-block'
    },
});

const StudentName = ({ record }) => {
    // console.log("student name ", record)
    const classes = useStyles();
    if (record != undefined) {

        if (record.Status === undefined || record.Status === "") {
            return (
                <TextField label="Student Name" source="Sname" />
            );
        }
        if (record.Status === "IN") {
            return (
                <div>
                    <TextField label="Student Name" source="Sname" />
                    <span className={classes.greendot}></span>
                </div>
            );
        }
        if (record.Status === "OUT") {
            return (
                <div>
                    <TextField label="Student Name" source="Sname" />
                    <span className={classes.reddot}></span>
                </div>
            );
        }
    } else {
        return (
            <TextField label="Student Name" source="Sname" />
        );
    }
}

export default StudentName;